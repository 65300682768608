var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "base", staticClass: "gp-rules" },
    [
      _c("gp-stored", {
        attrs: {
          family: _vm.getStoredFamily,
          username: _vm.username,
          shareGroups: _vm.shareGroups
        },
        on: { saved: _vm.configChanged, change: _vm.configChanged },
        model: {
          value: _vm.config,
          callback: function($$v) {
            _vm.config = $$v
          },
          expression: "config"
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c(
            "label",
            { attrs: { for: "gp-rules_rules-scope" } },
            [_c("l10n", { attrs: { value: "Rules scope" } })],
            1
          ),
          _vm._v(" "),
          _c("gp-filter", {
            attrs: {
              stream: _vm.stream,
              source: _vm.source,
              groups: _vm.groups,
              filter0: _vm.extraFilter0,
              filter1: _vm.extraFilter1,
              filter2: _vm.extraFilter2,
              threshold: _vm.threshold,
              attributes: _vm.attributes,
              formulas: _vm.formulas,
              vars: _vm.vars
            },
            model: {
              value: _vm.editingFilter,
              callback: function($$v) {
                _vm.editingFilter = $$v
              },
              expression: "editingFilter"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.usePromoTypeSelector
        ? _c("div", { staticClass: "form-group" }, [
            _c(
              "label",
              { attrs: { for: "configPromoType" } },
              [_c("l10n", { attrs: { value: "Promo type" } })],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex flex-nowrap" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.config.promoType,
                      expression: "config.promoType"
                    }
                  ],
                  staticClass: "form-control form-control-sm",
                  attrs: { id: "configPromoType" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.config,
                        "promoType",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c(
                    "option",
                    { attrs: { value: "banner" } },
                    [_c("l10n", { attrs: { value: "Banner" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "catalog" } },
                    [_c("l10n", { attrs: { value: "Catalog" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "yellow_tag" } },
                    [_c("l10n", { attrs: { value: "Yellow tag" } })],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-info apply-button",
                  attrs: { type: "button", disabled: _vm.isPromoRulesLoading },
                  on: { click: _vm.getRulesByPromoType }
                },
                [_c("l10n", { attrs: { value: "Apply" } })],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gp-rules-dates" },
        [
          _c("l10n", { attrs: { value: "Start date" } }),
          _vm._v("\n    :\n    "),
          _c("inline-input", {
            attrs: { type: "date" },
            model: {
              value: _vm.config.startDate,
              callback: function($$v) {
                _vm.$set(_vm.config, "startDate", $$v)
              },
              expression: "config.startDate"
            }
          }),
          _vm._v(" "),
          _c("l10n", { attrs: { value: "End date" } }),
          _vm._v("\n    :\n    "),
          _c("inline-input", {
            attrs: { type: "date" },
            model: {
              value: _vm.config.endDate,
              callback: function($$v) {
                _vm.$set(_vm.config, "endDate", $$v)
              },
              expression: "config.endDate"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gp-rules-hint" },
        [
          _c("l10n", {
            attrs: { value: "Use drag & drop to reorder sections and rules" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gp-rules-weight" },
        [
          _c("l10n", { attrs: { value: "Rule weight" } }),
          _vm._v(" "),
          _c("inline-help", {
            attrs: {
              text:
                "Optimization minimizes cummulative rules violation measured in price delta beyond boundaries defined by rules. Rule weight allows to control importance of every rule or an entire section of rules."
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { ref: "sections", staticClass: "gp-rules-sections" },
        [
          _vm._l(_vm.editingSections, function(section) {
            return _c(
              "div",
              {
                key: section.id,
                staticClass: "gp-rules-section",
                class: { expanded: _vm.expandedSections[section.id] },
                attrs: { "data-section": section.id }
              },
              [
                _c(
                  "label",
                  { attrs: { for: "gp-rules-section" } },
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            _vm.selectedSection = section
                          }
                        }
                      },
                      [
                        _c("feather-icon", { attrs: { name: "more-vertical" } })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "gp-rules-weight",
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            return _vm.promptSectionWeight(section)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.formatPercent(
                                section.weight !== undefined
                                  ? section.weight * 100
                                  : 100
                              )
                            ) +
                            "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.types.length > 1
                      ? _c(
                          "div",
                          { staticClass: "gp-section-opt-types-icons" },
                          _vm._l(_vm.types, function(type) {
                            return _c(
                              "span",
                              {
                                class: {
                                  active: section.types.includes(type.code)
                                }
                              },
                              [_c("l10n", { attrs: { value: type.icon } })],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            return _vm.$set(
                              _vm.expandedSections,
                              section.id,
                              !_vm.expandedSections[section.id]
                            )
                          }
                        }
                      },
                      [
                        _c("feather-icon", {
                          attrs: {
                            name: _vm.expandedSections[section.id]
                              ? "chevrons-down"
                              : "chevrons-right"
                          }
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.sectionName(section)) +
                            "\n        "
                        )
                      ],
                      1
                    ),
                    _vm._v("\n        –\n        "),
                    _c("l10n", {
                      attrs: {
                        value: "{rules} price rules",
                        rules: new Number(section.rules.length).toLocaleString()
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                section.rules.length
                  ? _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            _vm.isAllRulesExpanded(section)
                              ? _vm.collapseAllRules(section)
                              : _vm.expandAllRules(section)
                          }
                        }
                      },
                      [
                        _c("l10n", {
                          attrs: {
                            value: _vm.isAllRulesExpanded(section)
                              ? "collapse all"
                              : "expand all"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.types.length > 1 && _vm.expandedSections[section.id]
                  ? _c(
                      "div",
                      { staticClass: "gp-section-opt-types-checks" },
                      _vm._l(_vm.types, function(type) {
                        return _c(
                          "gp-check",
                          {
                            key: type.code,
                            attrs: {
                              checked: section.types.includes(type.code)
                            },
                            on: {
                              click: function($event) {
                                return _vm.toogleSectionType(section, type.code)
                              }
                            }
                          },
                          [_c("l10n", { attrs: { value: type.name } })],
                          1
                        )
                      }),
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.expandedSections[section.id]
                  ? _c(
                      "ul",
                      {
                        ref: "rules",
                        refInFor: true,
                        staticClass: "gp-rules-list"
                      },
                      _vm._l(section.rules, function(rule, n) {
                        return _vm.isRuleVisible(rule)
                          ? _c(
                              "li",
                              {
                                key: rule.id,
                                class: { expanded: _vm.expandedRules[rule.id] },
                                attrs: {
                                  "data-section": section.id,
                                  "data-rule": rule.id
                                }
                              },
                              [
                                _c("label", [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function($event) {
                                          _vm.selectedRule = rule
                                        }
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        attrs: { name: "more-vertical" }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  rule.type !== "rounding" &&
                                  rule.type !== "allowable_percent" &&
                                  rule.type !== "price_change_cnt_limits" &&
                                  rule.type !== "weak_optimization" &&
                                  rule.type !== "optimization_effect_limits"
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "gp-rules-weight",
                                          attrs: { href: "javascript:void(0)" },
                                          on: {
                                            click: function($event) {
                                              return _vm.promptRuleWeight(rule)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.formatPercent(
                                                  rule.weight !== undefined
                                                    ? rule.weight * 100
                                                    : 100
                                                )
                                              ) +
                                              "\n            "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function($event) {
                                          return _vm.$set(
                                            _vm.expandedRules,
                                            rule.id,
                                            !_vm.expandedRules[rule.id]
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        attrs: {
                                          name: _vm.expandedRules[rule.id]
                                            ? "chevron-down"
                                            : "chevron-right"
                                        }
                                      }),
                                      _vm._v(
                                        "\n              #" +
                                          _vm._s(_vm.ruleNumbers[rule.id]) +
                                          "\n              "
                                      ),
                                      _c("l10n", {
                                        attrs: { value: rule.name }
                                      })
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _vm.expandedRules[rule.id] &&
                                _vm.strictRules.includes(rule.type)
                                  ? _c(
                                      "gp-check",
                                      {
                                        staticClass: "float-right",
                                        model: {
                                          value: rule.strict,
                                          callback: function($$v) {
                                            _vm.$set(rule, "strict", $$v)
                                          },
                                          expression: "rule.strict"
                                        }
                                      },
                                      [
                                        _c("l10n", {
                                          attrs: { value: "strict rule" }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.expandedRules[rule.id] &&
                                rule.type !== "price_change_cnt_limits" &&
                                rule.type !== "optimization_effect_limits"
                                  ? _c(
                                      "p",
                                      { staticClass: "gp-rule-filter" },
                                      [
                                        _c("gp-filter", {
                                          attrs: {
                                            stream: _vm.stream,
                                            source: _vm.source,
                                            groups: _vm.groups,
                                            filter0: _vm.extraFilter0,
                                            filter1: _vm.extraFilter1,
                                            filter2: _vm.extraFilter2,
                                            threshold: _vm.threshold,
                                            attributes: _vm.attributes,
                                            formulas: _vm.formulas,
                                            vars: _vm.vars
                                          },
                                          model: {
                                            value: rule.filter,
                                            callback: function($$v) {
                                              _vm.$set(rule, "filter", $$v)
                                            },
                                            expression: "rule.filter"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("gp-filter", {
                                          attrs: {
                                            stream: _vm.stream,
                                            source: _vm.source,
                                            groups: _vm.groups,
                                            filter0: _vm.extraFilter0,
                                            filter1: _vm.extraFilter1,
                                            filter2: _vm.extraFilter2,
                                            threshold: _vm.threshold,
                                            attributes: _vm.attributes,
                                            formulas: _vm.formulas,
                                            vars: _vm.vars
                                          },
                                          model: {
                                            value: rule.filter_not,
                                            callback: function($$v) {
                                              _vm.$set(rule, "filter_not", $$v)
                                            },
                                            expression: "rule.filter_not"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.expandedRules[rule.id]
                                  ? _c(
                                      "p",
                                      [
                                        _c(
                                          "l10n",
                                          { attrs: { value: rule.text } },
                                          _vm._l(_vm.parseRule(rule), function(
                                            part
                                          ) {
                                            return _c(
                                              "span",
                                              [
                                                part.tag == "span"
                                                  ? [_vm._v(_vm._s(part.text))]
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                part.tag == "input"
                                                  ? [
                                                      _vm.activeControl ==
                                                      rule.id + part.prop
                                                        ? [
                                                            _c(
                                                              "my-popup",
                                                              {
                                                                attrs: {
                                                                  portal:
                                                                    "popup"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "anchor",
                                                                      fn: function() {
                                                                        return [
                                                                          _c(
                                                                            "a",
                                                                            {
                                                                              attrs: {
                                                                                href:
                                                                                  "javascript:void(0)"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  part.format(
                                                                                    part.value
                                                                                  )
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      },
                                                                      proxy: true
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "gp-rule-popup"
                                                                  },
                                                                  [
                                                                    part.prop.endsWith(
                                                                      "wholeEndings"
                                                                    )
                                                                      ? _c(
                                                                          "gp-endings",
                                                                          {
                                                                            ref:
                                                                              "prop",
                                                                            refInFor: true,
                                                                            attrs: {
                                                                              value:
                                                                                part.value,
                                                                              suffix:
                                                                                "."
                                                                            },
                                                                            on: {
                                                                              change: function(
                                                                                $event
                                                                              ) {
                                                                                _vm.newPropValue = $event
                                                                              }
                                                                            }
                                                                          }
                                                                        )
                                                                      : part.prop.endsWith(
                                                                          "fractionalEndings"
                                                                        )
                                                                      ? _c(
                                                                          "gp-endings",
                                                                          {
                                                                            ref:
                                                                              "prop",
                                                                            refInFor: true,
                                                                            attrs: {
                                                                              value:
                                                                                part.value,
                                                                              prefix:
                                                                                "."
                                                                            },
                                                                            on: {
                                                                              change: function(
                                                                                $event
                                                                              ) {
                                                                                _vm.newPropValue = $event
                                                                              }
                                                                            }
                                                                          }
                                                                        )
                                                                      : part.prop ===
                                                                        "reference_price"
                                                                      ? _c(
                                                                          "gp-autocomplete",
                                                                          {
                                                                            ref:
                                                                              "prop",
                                                                            refInFor: true,
                                                                            attrs: {
                                                                              options:
                                                                                rule
                                                                                  .options[
                                                                                  part
                                                                                    .prop
                                                                                ],
                                                                              value:
                                                                                part.value
                                                                            },
                                                                            on: {
                                                                              input: function(
                                                                                $event
                                                                              ) {
                                                                                _vm.newPropValue = $event
                                                                                _vm.changeProp(
                                                                                  part
                                                                                )
                                                                              }
                                                                            }
                                                                          }
                                                                        )
                                                                      : rule.options &&
                                                                        rule
                                                                          .options[
                                                                          part
                                                                            .prop
                                                                        ]
                                                                      ? _c(
                                                                          "select",
                                                                          {
                                                                            ref:
                                                                              "prop",
                                                                            refInFor: true,
                                                                            staticClass:
                                                                              "form-control form-control-sm",
                                                                            attrs: {
                                                                              autofocus:
                                                                                "",
                                                                              multiple:
                                                                                rule.multiple
                                                                            },
                                                                            domProps: {
                                                                              value:
                                                                                part.value
                                                                            },
                                                                            on: {
                                                                              change: function(
                                                                                $event
                                                                              ) {
                                                                                _vm.newPropValue = rule.multiple
                                                                                  ? Array.from(
                                                                                      $event
                                                                                        .target
                                                                                        .options
                                                                                    )
                                                                                      .filter(
                                                                                        function(
                                                                                          x
                                                                                        ) {
                                                                                          return x.selected
                                                                                        }
                                                                                      )
                                                                                      .map(
                                                                                        function(
                                                                                          x
                                                                                        ) {
                                                                                          return x.value
                                                                                        }
                                                                                      )
                                                                                  : $event
                                                                                      .target
                                                                                      .value
                                                                              },
                                                                              keydown: function(
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  $event.key ==
                                                                                  "Escape"
                                                                                ) {
                                                                                  _vm.activeControl = null
                                                                                }
                                                                                if (
                                                                                  $event.key ==
                                                                                  "Enter"
                                                                                ) {
                                                                                  _vm.newPropValue = rule.multiple
                                                                                    ? Array.from(
                                                                                        $event
                                                                                          .target
                                                                                          .options
                                                                                      )
                                                                                        .filter(
                                                                                          function(
                                                                                            x
                                                                                          ) {
                                                                                            return x.selected
                                                                                          }
                                                                                        )
                                                                                        .map(
                                                                                          function(
                                                                                            x
                                                                                          ) {
                                                                                            return x.value
                                                                                          }
                                                                                        )
                                                                                    : $event
                                                                                        .target
                                                                                        .value
                                                                                  _vm.changeProp(
                                                                                    part
                                                                                  )
                                                                                  _vm.activeControl = null
                                                                                }
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            rule
                                                                              .options[
                                                                              part
                                                                                .prop
                                                                            ]
                                                                              .length
                                                                              ? _vm._l(
                                                                                  rule
                                                                                    .options[
                                                                                    part
                                                                                      .prop
                                                                                  ],
                                                                                  function(
                                                                                    key
                                                                                  ) {
                                                                                    return _c(
                                                                                      "option",
                                                                                      {
                                                                                        domProps: {
                                                                                          value: key
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            key
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  }
                                                                                )
                                                                              : _vm._l(
                                                                                  rule
                                                                                    .options[
                                                                                    part
                                                                                      .prop
                                                                                  ],
                                                                                  function(
                                                                                    value,
                                                                                    key
                                                                                  ) {
                                                                                    return _c(
                                                                                      "option",
                                                                                      {
                                                                                        domProps: {
                                                                                          value: key
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            key
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  }
                                                                                )
                                                                          ],
                                                                          2
                                                                        )
                                                                      : part.type ===
                                                                        "dates"
                                                                      ? _c(
                                                                          "div",
                                                                          [
                                                                            _c(
                                                                              "my-date-picker",
                                                                              {
                                                                                ref:
                                                                                  "prop",
                                                                                refInFor: true,
                                                                                attrs: {
                                                                                  value:
                                                                                    rule.md_dates
                                                                                },
                                                                                on: {
                                                                                  input: function(
                                                                                    $event
                                                                                  ) {
                                                                                    _vm.newPropValue = $event
                                                                                    _vm.changeProp(
                                                                                      part
                                                                                    )
                                                                                  }
                                                                                }
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticStyle: {
                                                                                  "margin-top":
                                                                                    "10px"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "button",
                                                                                  {
                                                                                    staticClass:
                                                                                      "btn btn-danger btn-xs",
                                                                                    staticStyle: {
                                                                                      width:
                                                                                        "100%"
                                                                                    },
                                                                                    on: {
                                                                                      click: function(
                                                                                        $event
                                                                                      ) {
                                                                                        rule.md_dates = []
                                                                                        rule.md_force_percents = {}
                                                                                        _vm.newPropValue = []
                                                                                        _vm.changeProp(
                                                                                          part
                                                                                        )
                                                                                        _vm.activeControl = null
                                                                                        $event.stopPropagation()
                                                                                      }
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "l10n",
                                                                                      {
                                                                                        attrs: {
                                                                                          value:
                                                                                            "Reset dates"
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      : part.type ===
                                                                        "value_by_date"
                                                                      ? _c(
                                                                          "div",
                                                                          [
                                                                            _c(
                                                                              "v-date-picker",
                                                                              {
                                                                                attrs: {
                                                                                  "available-dates":
                                                                                    rule.md_dates,
                                                                                  value:
                                                                                    _vm.currentMdDate,
                                                                                  attributes: [
                                                                                    {
                                                                                      highlight: {
                                                                                        fillMode:
                                                                                          "outline",
                                                                                        color:
                                                                                          "blue"
                                                                                      },
                                                                                      dates: Object.keys(
                                                                                        rule.md_force_percents
                                                                                      ).filter(
                                                                                        function(
                                                                                          x
                                                                                        ) {
                                                                                          return (
                                                                                            rule
                                                                                              .md_force_percents[
                                                                                              x
                                                                                            ]
                                                                                              .type ===
                                                                                            "eq"
                                                                                          )
                                                                                        }
                                                                                      )
                                                                                    },
                                                                                    {
                                                                                      highlight: {
                                                                                        fillMode:
                                                                                          "outline",
                                                                                        color:
                                                                                          "red"
                                                                                      },
                                                                                      dates: Object.keys(
                                                                                        rule.md_force_percents
                                                                                      ).filter(
                                                                                        function(
                                                                                          x
                                                                                        ) {
                                                                                          return (
                                                                                            rule
                                                                                              .md_force_percents[
                                                                                              x
                                                                                            ]
                                                                                              .type ===
                                                                                            "le"
                                                                                          )
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  ]
                                                                                },
                                                                                on: {
                                                                                  dayclick: function(
                                                                                    $event
                                                                                  ) {
                                                                                    if (
                                                                                      rule.md_dates
                                                                                        .map(
                                                                                          function(
                                                                                            x
                                                                                          ) {
                                                                                            return _vm.formatDate(
                                                                                              x
                                                                                            )
                                                                                          }
                                                                                        )
                                                                                        .includes(
                                                                                          $event.id
                                                                                        )
                                                                                    ) {
                                                                                      _vm.currentMdDate =
                                                                                        $event.id
                                                                                      rule.md_force_percents[
                                                                                        _vm.currentMdDate
                                                                                      ] = rule
                                                                                        .md_force_percents[
                                                                                        _vm
                                                                                          .currentMdDate
                                                                                      ] || {
                                                                                        type:
                                                                                          "eq",
                                                                                        value: 0
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _vm.currentMdDate
                                                                              ? _c(
                                                                                  "my-popup",
                                                                                  {
                                                                                    attrs: {
                                                                                      portal:
                                                                                        ""
                                                                                    },
                                                                                    on: {
                                                                                      clickoutside: function(
                                                                                        $event
                                                                                      ) {
                                                                                        _vm.currentMdDate = undefined
                                                                                      }
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "gp-rule-popup gp-table-dates"
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          [
                                                                                            _c(
                                                                                              "l10n",
                                                                                              {
                                                                                                attrs: {
                                                                                                  value:
                                                                                                    "Discount"
                                                                                                }
                                                                                              }
                                                                                            ),
                                                                                            _vm._v(
                                                                                              " "
                                                                                            ),
                                                                                            _c(
                                                                                              "select",
                                                                                              {
                                                                                                style:
                                                                                                  "min-width: auto; color: " +
                                                                                                  (rule
                                                                                                    .md_force_percents[
                                                                                                    _vm
                                                                                                      .currentMdDate
                                                                                                  ]
                                                                                                    .type ==
                                                                                                  "eq"
                                                                                                    ? "var(--cyan)"
                                                                                                    : "var(--red)"),
                                                                                                domProps: {
                                                                                                  value:
                                                                                                    rule
                                                                                                      .md_force_percents[
                                                                                                      _vm
                                                                                                        .currentMdDate
                                                                                                    ]
                                                                                                      .type
                                                                                                },
                                                                                                on: {
                                                                                                  change: function(
                                                                                                    $event
                                                                                                  ) {
                                                                                                    rule.md_force_percents[
                                                                                                      _vm.currentMdDate
                                                                                                    ].type =
                                                                                                      $event.target.value
                                                                                                    _vm.newPropValue = Object.assign(
                                                                                                      {},
                                                                                                      rule.md_force_percents
                                                                                                    )
                                                                                                    _vm.changeProp(
                                                                                                      part
                                                                                                    )
                                                                                                  }
                                                                                                }
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "option",
                                                                                                  {
                                                                                                    staticStyle: {
                                                                                                      color:
                                                                                                        "var(--cyan)"
                                                                                                    },
                                                                                                    attrs: {
                                                                                                      value:
                                                                                                        "eq"
                                                                                                    }
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "equals"
                                                                                                    )
                                                                                                  ]
                                                                                                ),
                                                                                                _vm._v(
                                                                                                  " "
                                                                                                ),
                                                                                                _c(
                                                                                                  "option",
                                                                                                  {
                                                                                                    staticStyle: {
                                                                                                      color:
                                                                                                        "var(--red)"
                                                                                                    },
                                                                                                    attrs: {
                                                                                                      value:
                                                                                                        "le"
                                                                                                    }
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "deep or equal"
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ]
                                                                                            ),
                                                                                            _vm._v(
                                                                                              " "
                                                                                            ),
                                                                                            _c(
                                                                                              "span",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "gp-date"
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "a",
                                                                                                  [
                                                                                                    _c(
                                                                                                      "span",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "btn-link"
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          _vm._s(
                                                                                                            rule
                                                                                                              .md_force_percents[
                                                                                                              _vm
                                                                                                                .currentMdDate
                                                                                                            ]
                                                                                                              .value
                                                                                                          )
                                                                                                        )
                                                                                                      ]
                                                                                                    ),
                                                                                                    _vm._v(
                                                                                                      "%\n                                    "
                                                                                                    ),
                                                                                                    _c(
                                                                                                      "input",
                                                                                                      {
                                                                                                        staticStyle: {
                                                                                                          "min-width":
                                                                                                            "auto",
                                                                                                          width:
                                                                                                            "40px"
                                                                                                        },
                                                                                                        attrs: {
                                                                                                          type:
                                                                                                            "number"
                                                                                                        },
                                                                                                        on: {
                                                                                                          click: function(
                                                                                                            $event
                                                                                                          ) {
                                                                                                            return $event.target.parentElement.parentElement.classList.add(
                                                                                                              "focused"
                                                                                                            )
                                                                                                          },
                                                                                                          keydown: function(
                                                                                                            $event
                                                                                                          ) {
                                                                                                            if (
                                                                                                              $event.key ==
                                                                                                              "Escape"
                                                                                                            ) {
                                                                                                              $event.target.parentElement.parentElement.classList.remove(
                                                                                                                "focused"
                                                                                                              )
                                                                                                            }
                                                                                                            if (
                                                                                                              $event.key ==
                                                                                                              "Enter"
                                                                                                            ) {
                                                                                                              rule.md_force_percents[
                                                                                                                _vm.currentMdDate
                                                                                                              ].value = parseFloat(
                                                                                                                $event
                                                                                                                  .target
                                                                                                                  .value
                                                                                                              )
                                                                                                              _vm.newPropValue = Object.assign(
                                                                                                                {},
                                                                                                                rule.md_force_percents
                                                                                                              )
                                                                                                              _vm.changeProp(
                                                                                                                part
                                                                                                              )
                                                                                                              $event.target.parentElement.parentElement.classList.remove(
                                                                                                                "focused"
                                                                                                              )
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ]
                                                                                            ),
                                                                                            _vm._v(
                                                                                              " "
                                                                                            ),
                                                                                            _c(
                                                                                              "a",
                                                                                              {
                                                                                                staticStyle: {
                                                                                                  "margin-left":
                                                                                                    "15px"
                                                                                                },
                                                                                                attrs: {
                                                                                                  href:
                                                                                                    "javascript:void(0)"
                                                                                                },
                                                                                                on: {
                                                                                                  click: function(
                                                                                                    $event
                                                                                                  ) {
                                                                                                    delete rule
                                                                                                      .md_force_percents[
                                                                                                      _vm
                                                                                                        .currentMdDate
                                                                                                    ]
                                                                                                    _vm.currentMdDate = undefined
                                                                                                  }
                                                                                                }
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "feather-icon",
                                                                                                  {
                                                                                                    attrs: {
                                                                                                      name:
                                                                                                        "trash-2",
                                                                                                      color:
                                                                                                        "var(--red)"
                                                                                                    }
                                                                                                  }
                                                                                                )
                                                                                              ],
                                                                                              1
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticStyle: {
                                                                                  "margin-top":
                                                                                    "10px"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "button",
                                                                                  {
                                                                                    staticClass:
                                                                                      "btn btn-danger btn-xs",
                                                                                    staticStyle: {
                                                                                      width:
                                                                                        "100%"
                                                                                    },
                                                                                    on: {
                                                                                      click: function(
                                                                                        $event
                                                                                      ) {
                                                                                        rule.md_force_percents = {}
                                                                                        _vm.newPropValue = {}
                                                                                        _vm.changeProp(
                                                                                          part
                                                                                        )
                                                                                        $event.stopPropagation()
                                                                                      }
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "l10n",
                                                                                      {
                                                                                        attrs: {
                                                                                          value:
                                                                                            "Reset force percents"
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _c(
                                                                          "input",
                                                                          {
                                                                            ref:
                                                                              "prop",
                                                                            refInFor: true,
                                                                            staticClass:
                                                                              "form-control form-control-sm",
                                                                            attrs: {
                                                                              type:
                                                                                part.type,
                                                                              autofocus:
                                                                                ""
                                                                            },
                                                                            domProps: {
                                                                              value:
                                                                                part.value !==
                                                                                  -10000000 &&
                                                                                part.value !==
                                                                                  10000000
                                                                                  ? part.value
                                                                                  : ""
                                                                            },
                                                                            on: {
                                                                              change: function(
                                                                                $event
                                                                              ) {
                                                                                _vm.newPropValue =
                                                                                  $event.target.value
                                                                              },
                                                                              keydown: function(
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  $event.key ==
                                                                                  "Escape"
                                                                                ) {
                                                                                  _vm.activeControl = null
                                                                                }
                                                                                if (
                                                                                  $event.key ==
                                                                                  "Enter"
                                                                                ) {
                                                                                  _vm.newPropValue =
                                                                                    $event.target.value
                                                                                  _vm.changeProp(
                                                                                    part
                                                                                  )
                                                                                  _vm.activeControl = null
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "gp-rule-popup-actions"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "button",
                                                                          {
                                                                            staticClass:
                                                                              "btn btn-primary btn-xs",
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                _vm.changeProp(
                                                                                  part
                                                                                )
                                                                                _vm.activeControl = null
                                                                                $event.stopPropagation()
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "l10n",
                                                                              {
                                                                                attrs: {
                                                                                  value:
                                                                                    "OK"
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "button",
                                                                          {
                                                                            staticClass:
                                                                              "btn btn-secondary btn-xs",
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                _vm.activeControl = null
                                                                                $event.stopPropagation()
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "l10n",
                                                                              {
                                                                                attrs: {
                                                                                  value:
                                                                                    "Cancel"
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        : _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                href:
                                                                  "javascript:void(0)"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.activeControl =
                                                                    rule.id +
                                                                    part.prop
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                    " +
                                                                  _vm._s(
                                                                    part.format(
                                                                      part.value
                                                                    )
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            )
                                          }),
                                          0
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e()
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.expandedSections[section.id]
                  ? _c(
                      "div",
                      { staticClass: "gp-rules-add-rule" },
                      [
                        _vm.showRulesLibrary === section.id
                          ? _c(
                              "my-popup",
                              {
                                attrs: {
                                  portal: "popup",
                                  placement: "bottom-start"
                                },
                                on: {
                                  escape: function($event) {
                                    _vm.showRulesLibrary = null
                                  },
                                  clickoutside: function($event) {
                                    _vm.showRulesLibrary = null
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "anchor",
                                      fn: function() {
                                        return [
                                          _c(
                                            "a",
                                            {
                                              staticStyle: { display: "block" },
                                              attrs: {
                                                href: "javascript:void(0)"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.showRulesLibrary = null
                                                }
                                              }
                                            },
                                            [
                                              _c("feather-icon", {
                                                attrs: { name: "plus" }
                                              }),
                                              _vm._v(" "),
                                              _c("l10n", {
                                                attrs: {
                                                  value: "Add price rule"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._v(" "),
                                _c(
                                  "ul",
                                  { staticClass: "gp-rules-library" },
                                  _vm._l(_vm.rulesLibrary, function(rule) {
                                    return rule.show !== false
                                      ? _c("li", [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "javascript:void(0)"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addRule(
                                                    section,
                                                    rule
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(rule.name) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ])
                                      : _vm._e()
                                  }),
                                  0
                                )
                              ]
                            )
                          : _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    _vm.showRulesLibrary = section.id
                                  }
                                }
                              },
                              [
                                _c("feather-icon", { attrs: { name: "plus" } }),
                                _vm._v(" "),
                                _c("l10n", {
                                  attrs: { value: "Add price rule" }
                                })
                              ],
                              1
                            )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            )
          }),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: { href: "javascript:void(0)" },
              on: { click: _vm.addSection }
            },
            [
              _c("feather-icon", { attrs: { name: "plus" } }),
              _vm._v(" "),
              _c("l10n", { attrs: { value: "Add price rules section" } })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: { href: "javascript:void(0)" },
              on: { click: _vm.exportToExcel }
            },
            [
              _c("feather-icon", { attrs: { name: "download" } }),
              _vm._v(" "),
              _c("l10n", { attrs: { value: "Export rules to Excel" } })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: { href: "javascript:void(0)" },
              on: { click: _vm.importFromExcel }
            },
            [
              _c("feather-icon", { attrs: { name: "upload" } }),
              _vm._v(" "),
              _c("l10n", { attrs: { value: "Import rules from Excel" } })
            ],
            1
          )
        ],
        2
      ),
      _vm._v(" "),
      _c("p"),
      _c("div", { staticClass: "form-group" }, [
        _c(
          "label",
          [_c("l10n", { attrs: { value: "Optimization run name" } })],
          1
        ),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.runName,
              expression: "runName"
            }
          ],
          staticClass: "form-control",
          attrs: {
            placeholder:
              _vm.config &&
              (!_vm.usePromoTypeSelector
                ? _vm.config.name
                : _vm.config.promoType)
          },
          domProps: { value: _vm.runName },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.runName = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c(
            "gp-check",
            {
              model: {
                value: _vm.temporaryRun,
                callback: function($$v) {
                  _vm.temporaryRun = $$v
                },
                expression: "temporaryRun"
              }
            },
            [
              _c("l10n", {
                attrs: { value: "Don't save optimization results" }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.types.length > 1
        ? _c("div", { staticClass: "form-group optimization-types" }, [
            _c(
              "label",
              [_c("l10n", { attrs: { value: "Optimization type" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              _vm._l(_vm.types, function(type) {
                return _c(
                  "gp-check",
                  {
                    key: type.code,
                    attrs: {
                      type: _vm.multipleTypes ? "checkbox" : "radio",
                      checked: _vm.optimizationTypes.includes(type.code),
                      name: "optimization-type"
                    },
                    on: {
                      click: function($event) {
                        _vm.optimizationTypes = _vm.multipleTypes
                          ? _vm.optimizationTypes.includes(type.code)
                            ? _vm.optimizationTypes.filter(function(code) {
                                return code != type.code
                              })
                            : _vm.optimizationTypes.concat(type.code)
                          : [type.code]
                      }
                    }
                  },
                  [_c("l10n", { attrs: { value: type.name } })],
                  1
                )
              }),
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btm-sm btn-secondary",
          attrs: { disabled: _vm.optimizing },
          on: { click: _vm.optimize }
        },
        [_c("l10n", { attrs: { value: "Optimize" } })],
        1
      ),
      _vm._v(" "),
      _c("p"),
      _vm._v(" "),
      _vm.optimizing
        ? _c(
            "div",
            [
              _c("l10n", { attrs: { value: _vm.optimizationStatus } }),
              _vm._v(" "),
              _c("div", { staticClass: "progress" }, [
                _c("div", {
                  staticClass:
                    "progress-bar progress-bar-striped progress-bar-animated bg-info",
                  style: { width: _vm.optimizationProgress * 100 + "%" }
                })
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.stats
        ? _c("div", { staticClass: "gp-rules-stats" }, [
            _c(
              "div",
              [
                _c("l10n", {
                  attrs: {
                    value: "{count} price changes",
                    count: new Number(_vm.stats.changes).toLocaleString()
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.stats.increased
              ? _c(
                  "div",
                  [
                    _c("l10n", {
                      attrs: {
                        value: "{count} price increased on {avg} in average",
                        count: new Number(_vm.stats.increased).toLocaleString(),
                        avg: _vm.formatPercent(_vm.stats.avg_increase * 100)
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.stats.decreased
              ? _c(
                  "div",
                  [
                    _c("l10n", {
                      attrs: {
                        value: "{count} price decreased on {avg} in average",
                        count: new Number(_vm.stats.decreased).toLocaleString(),
                        avg: _vm.formatPercent(_vm.stats.avg_decrease * -100)
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.stats.dropped_impact > 0
              ? _c(
                  "div",
                  [
                    _c("l10n", {
                      attrs: {
                        value:
                          "{count} price changes are dropped due to metric impact limit",
                        count: new Number(
                          _vm.stats.dropped_impact
                        ).toLocaleString()
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.stats.dropped_limits > 0
              ? _c(
                  "div",
                  [
                    _c("l10n", {
                      attrs: {
                        value:
                          "{count} price changes are dropped due to price change limit",
                        count: new Number(
                          _vm.stats.dropped_limits
                        ).toLocaleString()
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.stats.dropped_markdown_budget > 0
              ? _c(
                  "div",
                  [
                    _c("l10n", {
                      attrs: {
                        value:
                          "{count} price changes are dropped due to markdown budget limit",
                        count: new Number(
                          _vm.stats.dropped_markdown_budget
                        ).toLocaleString()
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.stats.errors > 0
              ? _c(
                  "div",
                  [
                    _c("l10n", {
                      attrs: {
                        value: "{errors} optimization tasks failed",
                        errors: new Number(_vm.stats.errors).toLocaleString()
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.config.schedule
        ? [
            _c("gp-data", {
              attrs: {
                id: "gp-rules-categories",
                stream: _vm.stream,
                source: _vm.source,
                groups: ["gp-rules-categories", "reference-date"],
                filter0: _vm.filter0,
                filter1: _vm.filter1,
                filter2: _vm.filter2,
                filter3: "dim1 != ''",
                dims: ["classif.category"],
                initialSort: [1],
                instant: false
              },
              model: {
                value: _vm.categoriesReport,
                callback: function($$v) {
                  _vm.categoriesReport = $$v
                },
                expression: "categoriesReport"
              }
            }),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "gp-runs-schedule" },
              _vm._l(_vm.config.schedule, function(
                scheduledRun,
                scheduledRunId
              ) {
                return _c(
                  "li",
                  {
                    key: scheduledRunId,
                    class: {
                      expanded: _vm.expandedScheduledRuns[scheduledRunId]
                    }
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            _vm.expandedScheduledRuns[scheduledRunId]
                              ? _vm.$delete(
                                  _vm.expandedScheduledRuns,
                                  scheduledRunId
                                )
                              : _vm.$set(
                                  _vm.expandedScheduledRuns,
                                  scheduledRunId,
                                  true
                                )
                          }
                        }
                      },
                      [
                        _c("feather-icon", {
                          attrs: {
                            name: _vm.expandedScheduledRuns[scheduledRunId]
                              ? "chevron-down"
                              : "chevron-right"
                          }
                        }),
                        _vm._v(" "),
                        _c("l10n", {
                          attrs: {
                            value:
                              "Run optimization on {days} at {time} for {categories}.",
                            days:
                              (scheduledRun.days &&
                                scheduledRun.days.length &&
                                scheduledRun.days
                                  .map(function(day) {
                                    return _vm
                                      .parseDate(day)
                                      .toLocaleDateString()
                                  })
                                  .join(", ")) ||
                              (scheduledRun.dows &&
                                scheduledRun.dows.length &&
                                scheduledRun.dows.map(_vm.l10n).join(", ")) ||
                              _vm.l10n("[no days selected]"),
                            time:
                              scheduledRun.time ||
                              _vm.l10n("[no time selected]"),
                            categories:
                              (scheduledRun.categories || [])
                                .map(function(category) {
                                  return category.name
                                })
                                .join(", ") ||
                              _vm.l10n("[no categories selected]")
                          }
                        }),
                        _vm._v(" "),
                        scheduledRun.combineCategories
                          ? _c("l10n", {
                              attrs: {
                                value: "Optimize all categories at once."
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        scheduledRun.autoAccept
                          ? _c("l10n", {
                              attrs: {
                                value:
                                  "Automatically accept recommendations if price change is within {left} to {right} range.",
                                left: scheduledRun.autoAcceptBoundsLeft
                                  ? scheduledRun.autoAcceptBoundsLeft + "%"
                                  : _vm.l10n("[no min limit selected]"),
                                right: scheduledRun.autoAcceptBoundsRight
                                  ? scheduledRun.autoAcceptBoundsRight + "%"
                                  : _vm.l10n("[no max limit selected]")
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        scheduledRun.autoAcceptExcludeStrictViolations
                          ? _c("l10n", {
                              attrs: {
                                value:
                                  "Do not accept if strict rules are violated"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.expandedScheduledRuns[scheduledRunId]
                      ? [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              [_c("l10n", { attrs: { value: "Run at:" } })],
                              1
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: scheduledRun.time,
                                  expression: "scheduledRun.time"
                                }
                              ],
                              staticClass: "form-control form-control-sm",
                              attrs: { type: "time" },
                              domProps: { value: scheduledRun.time },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    scheduledRun,
                                    "time",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                [_c("l10n", { attrs: { value: "Run on:" } })],
                                1
                              ),
                              _vm._v(" "),
                              scheduledRun.days.length == 0
                                ? _c(
                                    "div",
                                    { staticClass: "btn-group-toggle" },
                                    _vm._l(
                                      [
                                        "Mon",
                                        "Tue",
                                        "Wed",
                                        "Thu",
                                        "Fri",
                                        "Sat",
                                        "Sun"
                                      ],
                                      function(dow) {
                                        return _c(
                                          "label",
                                          {
                                            class: {
                                              "btn btn-sm btn-secondary": true,
                                              active:
                                                scheduledRun.dows.indexOf(
                                                  dow
                                                ) != -1
                                            }
                                          },
                                          [
                                            _c("input", {
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked:
                                                  scheduledRun.dows.indexOf(
                                                    dow
                                                  ) != -1
                                              },
                                              on: {
                                                change: function($event) {
                                                  $event.target.checked
                                                    ? scheduledRun.dows.push(
                                                        dow
                                                      )
                                                    : scheduledRun.dows.splice(
                                                        scheduledRun.dows.indexOf(
                                                          dow
                                                        ),
                                                        1
                                                      )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("l10n", {
                                              attrs: { value: dow }
                                            })
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scheduledRun.dows.length == 0
                                ? _c("v-calendar", {
                                    attrs: {
                                      locale: _vm.locale,
                                      "is-dark": _vm.darkTheme,
                                      "is-expanded": "",
                                      "trim-weeks": "",
                                      "title-position": "left",
                                      attributes: scheduledRun.days.map(
                                        function(day) {
                                          return {
                                            key: day,
                                            highlight: true,
                                            dates: _vm.parseDate(day)
                                          }
                                        }
                                      )
                                    },
                                    on: {
                                      dayclick: function($event) {
                                        scheduledRun.days.indexOf($event.id) >=
                                        0
                                          ? scheduledRun.days.splice(
                                              scheduledRun.days.indexOf(
                                                $event.id
                                              ),
                                              1
                                            )
                                          : scheduledRun.days.push($event.id)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                [_c("l10n", { attrs: { value: "Run for:" } })],
                                1
                              ),
                              _vm._v(" "),
                              _c("gp-pills", {
                                attrs: {
                                  options: _vm.knownCategories,
                                  placeholder: _vm.l10n("Categories"),
                                  recentOptionsKey: "recentCategories"
                                },
                                model: {
                                  value: scheduledRun.categories,
                                  callback: function($$v) {
                                    _vm.$set(scheduledRun, "categories", $$v)
                                  },
                                  expression: "scheduledRun.categories"
                                }
                              }),
                              _vm._v(" "),
                              _c("gp-filter", {
                                attrs: {
                                  stream: _vm.stream,
                                  source: _vm.source,
                                  attributes: _vm.attributes,
                                  formulas: _vm.formulas,
                                  groups: ["gp-rules-filter", "reference-date"],
                                  vars: _vm.vars
                                },
                                model: {
                                  value: scheduledRun.filter,
                                  callback: function($$v) {
                                    _vm.$set(scheduledRun, "filter", $$v)
                                  },
                                  expression: "scheduledRun.filter"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "gp-check",
                                {
                                  model: {
                                    value: scheduledRun.combineCategories,
                                    callback: function($$v) {
                                      _vm.$set(
                                        scheduledRun,
                                        "combineCategories",
                                        $$v
                                      )
                                    },
                                    expression: "scheduledRun.combineCategories"
                                  }
                                },
                                [
                                  _c("l10n", {
                                    attrs: {
                                      value: "Optimize all categories at once"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "gp-check",
                                {
                                  model: {
                                    value: scheduledRun.autoAccept,
                                    callback: function($$v) {
                                      _vm.$set(scheduledRun, "autoAccept", $$v)
                                    },
                                    expression: "scheduledRun.autoAccept"
                                  }
                                },
                                [
                                  _c("l10n", {
                                    attrs: {
                                      value:
                                        "Automatically accept recommendations"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              scheduledRun.autoAccept
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "gp-rules-auto-accept-bounds"
                                    },
                                    [
                                      _c("l10n", {
                                        attrs: {
                                          value: "if price change is within"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              scheduledRun.autoAcceptBoundsLeft,
                                            expression:
                                              "scheduledRun.autoAcceptBoundsLeft"
                                          }
                                        ],
                                        staticClass:
                                          "form-control form-control-sm",
                                        attrs: { type: "number", step: "0.1" },
                                        domProps: {
                                          value:
                                            scheduledRun.autoAcceptBoundsLeft
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              scheduledRun,
                                              "autoAcceptBoundsLeft",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("%")]),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              scheduledRun.autoAcceptBoundsRight,
                                            expression:
                                              "scheduledRun.autoAcceptBoundsRight"
                                          }
                                        ],
                                        staticClass:
                                          "form-control form-control-sm",
                                        attrs: { type: "number", step: "0.1" },
                                        domProps: {
                                          value:
                                            scheduledRun.autoAcceptBoundsRight
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              scheduledRun,
                                              "autoAcceptBoundsRight",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("%")])
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.$delete(
                                      _vm.config.schedule,
                                      scheduledRunId
                                    )
                                  }
                                }
                              },
                              [
                                _c("l10n", {
                                  attrs: {
                                    value:
                                      "Delete this scheduled optimization run"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      : _vm._e()
                  ],
                  2
                )
              }),
              0
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c(
          "a",
          {
            attrs: { href: "javascript:void(0)" },
            on: { click: _vm.addScheduledRun }
          },
          [
            _c("feather-icon", { attrs: { name: "calendar" } }),
            _vm._v(" "),
            _c("l10n", { attrs: { value: "Schedule optimization run" } })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gp-rules-configuration" },
        [
          _c("p"),
          _c("div", { staticClass: "form-check" }, [
            _c("input", {
              staticClass: "form-check-input",
              attrs: { type: "checkbox", id: "show-rules-configYaml" },
              domProps: { checked: _vm.showConfig },
              on: { click: _vm.toogleConfig }
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "form-check-label",
                attrs: { for: "show-rules-configYaml" }
              },
              [_c("l10n", { attrs: { value: "Show configuration" } })],
              1
            )
          ]),
          _vm._v(" "),
          _c("p"),
          _vm._v(" "),
          _vm.showConfig
            ? _c("ace-editor", {
                attrs: { mode: "yaml", "auto-height": true },
                model: {
                  value: _vm.configYaml,
                  callback: function($$v) {
                    _vm.configYaml = $$v
                  },
                  expression: "configYaml"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showConfig
            ? _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-secondary",
                  on: { click: _vm.applyConfig }
                },
                [_c("l10n", { attrs: { value: "Submit changes" } })],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.selectedSection
        ? _c(
            "my-popup",
            {
              attrs: {
                portal: "popup",
                placement: "bottom-end",
                anchor:
                  "*[data-section='" +
                  _vm.selectedSection.id +
                  "'] .feather-icon-more-vertical"
              },
              on: {
                escape: function($event) {
                  _vm.selectedSection = null
                },
                clickoutside: function($event) {
                  _vm.selectedSection = null
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "popover show plain-table-manage-table" },
                [
                  _c("div", { staticClass: "popover-body" }, [
                    _c("ul", [
                      _c("li", [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                _vm.promptSectionName(_vm.selectedSection)
                                _vm.selectedSection = null
                              }
                            }
                          },
                          [
                            _c("feather-icon", { attrs: { name: "edit-3" } }),
                            _vm._v(" "),
                            _c("l10n", { attrs: { value: "Rename section" } })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                _vm.cloneSection(_vm.selectedSection)
                                _vm.selectedSection = null
                              }
                            }
                          },
                          [
                            _c("feather-icon", { attrs: { name: "copy" } }),
                            _vm._v(" "),
                            _c("l10n", {
                              attrs: { value: "Duplicate section" }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                _vm.deleteSection(_vm.selectedSection)
                                _vm.selectedSection = null
                              }
                            }
                          },
                          [
                            _c("feather-icon", { attrs: { name: "trash" } }),
                            _vm._v(" "),
                            _c("l10n", { attrs: { value: "Delete section" } })
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedRule
        ? _c(
            "my-popup",
            {
              attrs: {
                portal: "popup",
                placement: "bottom-end",
                anchor:
                  "*[data-rule='" +
                  _vm.selectedRule.id +
                  "'] .feather-icon-more-vertical"
              },
              on: {
                escape: function($event) {
                  _vm.selectedRule = null
                },
                clickoutside: function($event) {
                  _vm.selectedRule = null
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "popover show plain-table-manage-table" },
                [
                  _c("div", { staticClass: "popover-body" }, [
                    _c("ul", [
                      _c("li", [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                _vm.promptRuleName(_vm.selectedRule)
                                _vm.selectedRule = null
                              }
                            }
                          },
                          [
                            _c("feather-icon", { attrs: { name: "edit-3" } }),
                            _vm._v(" "),
                            _c("l10n", {
                              attrs: { value: "Rename price rule" }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                _vm.cloneRule(_vm.selectedRule)
                                _vm.selectedRule = null
                              }
                            }
                          },
                          [
                            _c("feather-icon", { attrs: { name: "copy" } }),
                            _vm._v(" "),
                            _c("l10n", {
                              attrs: { value: "Duplicate price rule" }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                _vm.deleteRule(_vm.selectedRule)
                                _vm.selectedRule = null
                              }
                            }
                          },
                          [
                            _c("feather-icon", { attrs: { name: "trash" } }),
                            _vm._v(" "),
                            _c("l10n", {
                              attrs: { value: "Delete price rule" }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                ]
              )
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }