<!-- eslint-disable no-alert -->
<template>
  <div class="gp-table">
    <div
      v-if="failedEdits.length != 0"
      class="alert alert-warning gp-table-failed-edits"
    >
      <l10n value="The following changes are failed to save:" />

      <table class="gp-table-failed-edits">
        <thead>
          <tr>
            <th />

            <th>
              <l10n value="Column" />
            </th>

            <th>
              <l10n value="Error" />
            </th>

            <th>
              <l10n value="Old value" />
            </th>

            <th>
              <l10n value="New value" />
            </th>

            <th>
              <l10n value="Row" />
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(edit, i) in failedEdits"
            :key="i"
          >
            <td>
              <feather-icon
                v-if="edit.status == 'retrying'"
                name="clock"
              />

              <feather-icon
                v-else
                name="alert-circle"
              />
            </td>

            <td>
              <l10n :value="edit.args.column.name" />
            </td>

            <td>
              <span>{{ edit.error }}</span>
            </td>

            <td>
              <span>{{ edit.args.column.format(edit.args.oldValue, edit.args.row, edit.args.column) }}</span>
            </td>

            <td>
              <span>{{ edit.args.value }}</span>
            </td>

            <td
              v-for="(column, i) in edit.args.meta.columns"
              v-if="column.section == 'Dimensions'"
              :key="i"
            >
              <span>{{ edit.args.row[i] }}</span>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="gp-table-failed-edits-controls">
        <button
          class="btn-sm btn-primary"
          type="button"
          @click="retryFailedEdits"
        >
          <l10n value="Retry" />
        </button>

        <button
          class="btn-sm btn-secondary"
          type="button"
          @click="discardFailedEdits"
        >
          <l10n value="Discard" />
        </button>
      </div>
    </div>

    <div
      v-if="product == 'price'"
      class="gp-table-dates"
    >
      <div class="gp-table-dates__container">
        <feather-icon name="calendar" />

        <label>
          <l10n value="Reference date:" />
        </label>

        <gp-date
          :value="nextDate(referenceDate)"
          :expect-today="true"
          @input="referenceDate = prevDate($event)"
        />
      </div>

      <div>
        <label>
          <l10n value="Actuals:" />
        </label>

        <div class="gp-table-timeframe">
          <my-popup
            v-if="isPastTimeframeSelectVisible"
            portal="popup"
            @clickoutside="isPastTimeframeSelectVisible = false"
            @escape="isPastTimeframeSelectVisible = false"
          >
            <template #anchor>
              <a
                href="javascript:void(0)"
                @click="isPastTimeframeSelectVisible = false"
              >
                <span>{{ pastTimeframeName }}</span>
              </a>
            </template>

            <gp-timeframe-select
              :timeframes="fixedTimeframes"
              :selected-timeframe="pastTimeframe"
              @select-timeframe="setPastTimeframe"
            />
          </my-popup>

          <span
            v-else
            :title="pastTimeframeTitle"
            @click="isPastTimeframeSelectVisible = true"
          >{{ pastTimeframeName }}</span>
        </div>
      </div>

      <div>
        <label>
          <l10n value="Forecasts:" />
        </label>

        <div class="gp-table-timeframe">
          <my-popup
            v-if="isFutureTimeframeSelectVisible"
            portal="popup"
            @clickoutside="isFutureTimeframeSelectVisible = false"
            @escape="isFutureTimeframeSelectVisible = false"
          >
            <template #anchor>
              <a
                href="javascript:void(0)"
                @click="isFutureTimeframeSelectVisible = false"
              >
                <span>{{ futureTimeframeName }}</span>
              </a>
            </template>

            <gp-timeframe-select
              :timeframes="fixedTimeframes"
              :selected-timeframe="futureTimeframe"
              @select-timeframe="setFutureTimeframe"
            />
          </my-popup>

          <span
            v-else
            :title="futureTimeframeTitle"
            @click="isFutureTimeframeSelectVisible = true"
          >{{ futureTimeframeName }}</span>
        </div>
      </div>
    </div>

    <gp-table-runs
      v-if="renderOptimization && product == 'price'"
      v-model="optimizationId"
      :vars="vars"
      :stream="stream"
      :locale="locale"
      :username="username"
      :metrics="metrics"
      :attributes="attributes"
      :formats="formats"
      :formulas="formulas"
      :timeframes="timeframes"
      :referenceDate="referenceDate"
      :optimizationDims="optimizationDims"
      :optimizationVals="optimizationVals"
      :optimizationSections="optimizationSections"
      :futureTimeframe="futureTimeframe"
    />

    <my-tooltip
      ref="tooltip"
      :meta="null"
      :keep-only="keepOnly"
      :exclude="exclude"
      :actions="actions"
    />

    <form
      ref="uploadForm"
      style="display: none"
    >
      <input
        ref="uploadInput"
        type="file"
        @change="handleFileUpload"
      >
    </form>

    <form
      ref="uploadToUrlForm"
      class="hidden-form"
    >
      <input
        aria-label="Upload excel file"
        ref="uploadToUrlInput"
        type="file"
        @change="handleFileToUrlUpload($event, uploadToUrlButton.value)"
      >
    </form>

    <plain-table
      v-if="showTable"
      :id="type === 'tree' ? 'tree-table' : 'gp-table'"
      ref="table"
      :cores="cores"
      :caption="caption"
      :stream="stream"
      :groups="groups"
      :vars="vars"
      :dims="dims"
      :vals="vals"
      :cols="cols"
      :filter0="filter0"
      :filter1="extendedFilter1"
      :filter2="filter2_"
      :totals="totals"
      :source="source"
      :hover="hover"
      :striped="striped"
      :table-actions="tableActionsEx"
      :column-icons="columnIcons"
      :column-actions="columnActions"
      :section-actions="sectionActions"
      :instant="instant"
      :throttled="throttled"
      :initial-sort="initialSort"
      :responsive="false"
      :override="override"
      :expandable="expandable"
      :freeze-dims="true"
      :pagination="pagination"
      :overscroll="type === 'tree' ? false : !pagination"
      :client-sort="!pagination"
      :changeTypeFromStringToNumber="changeTypeFromStringToNumber"
      :sub-totals="subTotals"
      :expand='expand'
      :mode='type'
      :evaluateButtons='evaluateButtons'
      :downloadFromUrlButton="downloadFromUrlButton"
      :downloadFileFromUrlButtonLoading='downloadFileFromUrlButtonLoading'
      :uploadToUrlButton='uploadToUrlButton'
      :uploadFileToUrlButtonLoading='uploadFileToUrlButtonLoading'
      :headerWithoutSum='headerWithoutSum'
      :isEvaluateBtnsLoading='isEvaluateBtnsLoading'
      :editCellOnChange='editCellOnChange'
      :groupSize='groupSize'
      :tableActionsButtonEnabled="tableActionsButtonEnabled"
      :getOptionsListFromStream="getOptionsListFromStream"
      :restartStreamButton="restartStreamButton"
      :showExpandTableButton="showExpandTableButton"
      :needColumnsIndexes="needColumnsIndexes"
      :uniqueKey="uniqueKey"
      @action="handleCellAction"
      @report-updated="reportUpdated"
      @cell-edited="cellEdited"
      @cell-clicked="cellClicked"
      @link-clicked="linkClicked"
      @row-selected="rowSelected"
      @rows-selected="rowsSelected"
      @column-selected="columnSelected"
      @column-moved="columnMoved"
      @column-updated="columnUpdated"
      @page-size-changed="handlePageSizeChanged($event)"
      @desired-page-changed="handleDesiredPageChanged($event)"
      @desired-sort-changed="handleDesiredSortChanged"
      @filters-changed="handleTableFiltersChanged"
      @evaluate-button-click="handleEvaluateButtonClick"
      @download-url-button-click='downloadFileFromUrl'
      @upload-url-button-click="importFromFileToUrl"
    >
      <my-popup
        v-if="permalink"
        :draggable="true"
        :anchor="getTableActionsElement"
        placement="bottom"
        @clickoutside="permalink = null"
      >
        <div class="popover gp-table-permalink">
          <div class="popover-body">
            <div class="form-group">
              <a
                class="float-right"
                href="javascript:void(0)"
                @click="permalink = null"
              >
                <feather-icon name="x" />
              </a>

              <label>
                <l10n value="Please use the link below to share current report" />
              </label>

              <div class="input-group">
                <input
                  class="form-control"
                  readonly
                  :value="permalink"
                  @click="$event.target.select()"
                >

                <div class="input-group-append">
                  <button
                    class="btn btn-sm btn-outline-secondary"
                    type="button"
                    @click="copyPermalink"
                  >
                    <feather-icon name="clipboard" />

                    <l10n :value="permalinkCopied ? 'Copied' : 'Copy'" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </my-popup>

      <my-popup
        v-if="uploadFileDryRun"
        :draggable="true"
        :anchor="getTableActionsElement"
        placement="bottom"
        @clickoutside="uploadFileDryRun = null"
      >
        <div class="popover gp-table-upload-file">
          <div class="popover-body">
            <h3 class="popover-header">
              <l10n value="Import file: {file}" :file="uploadFileDryRun.file.name" />
            </h3>

            <div
              v-if="uploadFileDryRun.result.columns.length"
              class="form-group gp-table-upload-file-ready"
            >
              <label>
                <l10n value="Please select columns to import" />
              </label>

              <table>
                <thead>
                  <tr>
                    <th />
                    <th>
                      <l10n value="changes" />
                    </th>

                    <th>
                      <l10n value="not changed" />
                    </th>

                    <th>
                      <l10n value="not found" />
                    </th>

                    <th>
                      <l10n value="not allowed" />
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(stats, i) in uploadFileDryRun.result.report"
                    :key="i"
                  >
                    <td>
                      <gp-check
                        :checked="uploadFileDryRun.columns[stats.column]"
                        @change="selectUploadFileColumn(stats.column, $event)"
                      >
                        <l10n :value="stats.column" />
                      </gp-check>
                    </td>

                    <td>
                      <a
                        v-if="stats.changed"
                        href="#"
                        @click.prevent="downloadAsExcel(stats.changedRows, `${stats.column} - ${l10n('changes')}.xlsx`)"
                      >{{ Number(stats.changed).toLocaleString() }}</a>

                      <template v-else>
                        <span>{{ Number(stats.changed).toLocaleString() }}</span>
                      </template>
                    </td>

                    <td>
                      <span>{{ Number(stats.unchanged).toLocaleString() }}</span>
                    </td>

                    <td>
                      <a
                        v-if="stats.notFound"
                        href="#"
                        @click.prevent="downloadAsExcel(stats.notFoundRows, `${stats.column} - ${l10n('not found')}.xlsx`)"
                      >{{ Number(stats.notFound).toLocaleString() }}</a>

                      <template v-else>
                        <span>{{ Number(stats.notFound).toLocaleString() }}</span>
                      </template>
                    </td>

                    <td>
                      <a
                        v-if="stats.notAllowed"
                        href="#"
                        @click.prevent="downloadAsExcel(stats.notAllowedRows, `${stats.column} - ${l10n('not allowed')}.xlsx`)"
                      >{{ Number(stats.notAllowed).toLocaleString() }}</a>

                      <template v-else>
                        <span>{{ Number(stats.notAllowed).toLocaleString() }}</span>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              v-if="Object.keys(uploadFileDryRun.missing).length"
              class="form-group gp-table-upload-file-missing"
            >
              <label>
                <l10n value="The following metrics might be missing either in the file or report" />
              </label>

              <ul>
                <template v-for="metrics in uploadFileDryRun.missing">
                  <li
                    v-for="(metric, i) in metrics"
                    :key="i"
                  >
                    <l10n :value="metric" />
                  </li>
                </template>
              </ul>
            </div>

            <div
              v-if="Object.keys(uploadFileDryRun.result.no_keys).length"
              class="form-group gp-table-upload-file-no-keys"
            >
              <label>
                <l10n value="The following columns require additional grouping to be imported" />
              </label>

              <ul>
                <li
                  v-for="(keys, column) in uploadFileDryRun.result.no_keys"
                  :key="column"
                >
                  <l10n :value="column" />: {{ keys.map(key => attributesByCalc[key] ? attributesByCalc[key].name : key).join(", ") }}
                </li>
              </ul>
            </div>

            <div class="form-group gp-table-upload-file-actions">
              <button
                v-if="uploadFileDryRun.result.columns.length"
                class="btn btn-sm btn-primary"
                type="button"
                :disabled="Object.keys(uploadFileDryRun.columns).length == 0"
                @click="uploadFile(uploadFileDryRun.file, false, Object.keys(uploadFileDryRun.columns)); uploadFileDryRun = null;"
              >
                <l10n value="Import" />
              </button>

              <button
                class="btn btn-sm btn-secondary"
                type="button"
                @click="uploadFileDryRun = null"
              >
                <l10n value="Cancel" />
              </button>
            </div>
          </div>
        </div>
      </my-popup>
    </plain-table>

    <portal to="gp-kpis">
      <gp-kpis3
        v-model="kpis"
        :stream="stream"
        :vars="vars"
        :groups="['kpis', 'search', 'report']"
        :locale="locale"
        :currency="currency"
        :metrics="metrics"
        :formats="formats"
        :formulas="formulas"
        :timeframes="timeframes"
        :attributes="attributes"
        :metric1="kpisMetric1"
        :metric2="kpisMetric2"
        :metric3="kpisMetric3"
      />
    </portal>

    <portal :to="`gp-settings-${this.uniqueKey}`">
      <h2>
        <l10n value="Report settings" />
      </h2>

      <gp-stored
        ref="stored"
        family="reports"
        :compact="compact"
        :config="config"
        :username="username"
        :share-groups="shareGroups"
        :uniqueKey="uniqueKey"
        @saved="configSaved"
        @change="configChanged"
      />

      <template v-if="product == 'price' || product == 'pim'">
        <label>
          <l10n value="Scope" />
        </label>

        <gp-filter
          v-model="editingFilter"
          :stream="stream"
          :attributes="attributes"
          :formulas="formulas"
          :filter0="extraFilter0"
          :filter1="extraFilter1"
          :filter2="extraFilter2"
          :vars="vars"
        />

        <gp-filter
          style="visibility: hidden; position: absolute;"
          :value="currentFilter"
          :stream="stream"
          :attributes="attributes"
          :formulas="formulas"
          :groups="['report', 'search']"
          :autofilter="true"
        />
      </template>

      <label>
        <l10n value="Grouping" />
      </label>

      <gp-pills
        v-model="editingDimensions"
        :options="dimesionsOptions"
        recent-options-key="recentDimensions"
      />

      <div>
        <gp-check
          v-model="subTotals"
        >
          <l10n value="Enable subtotals by first group" />
        </gp-check>
      </div>

      <div>
        <label for="gp-settings-vars">
          <l10n value="Parameters" />
        </label>
        <table id="gp-settings-vars" class="gp-settings-vars">
          <tbody>
            <tr v-if="product == 'price'">
              <td><l10n value="POI history length" /></td>
              <td>
                <select
                  class="form-control form-control-sm"
                  :value="editingVars.target_poi_length || 7"
                  @change="$set(editingVars, 'target_poi_length', parseInt($event.target.value))"
                >
                  <option value="7"><l10n value="7 days" /></option>
                  <option value="14"><l10n value="14 days" /></option>
                  <option value="28"><l10n value="28 days" /></option>
                </select>
              </td>
            </tr>
            <tr v-if="product == 'price'">
              <td><l10n value="Cost change history length" /></td>
              <td>
                <select
                  class="form-control form-control-sm"
                  :value="editingVars.target_cost_change_days || 1"
                  @change="$set(editingVars, 'target_cost_change_days', parseInt($event.target.value))"
                >
                  <option value="1"><l10n value="1 day" /></option>
                  <option value="2"><l10n value="2 days" /></option>
                  <option value="3"><l10n value="3 days" /></option>
                  <option value="4"><l10n value="4 days" /></option>
                  <option value="5"><l10n value="5 days" /></option>
                  <option value="6"><l10n value="6 days" /></option>
                  <option value="7"><l10n value="7 days" /></option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <label>
        <l10n value="Columns" />
      </label>

      <div class="gp-settings-sections">
        <table
          v-for="(section, i) in editingSections"
          :key="i"
          :data-i="i"
          class="gp-table-settings"
        >
          <thead>
            <tr class="gp-table-section">
              <td>
                <div class="form-check form-check--in-settings">
                  <input
                    :id="`show-${i}`"
                    class="form-check-input"
                    type="checkbox"
                    :checked="isSectionVisible(section)"
                    :indeterminate.prop="isSectionIndeterminate(section)"
                    @click="toogleSectionVisible(section)"
                  >

                  <label
                    class="form-check-label"
                    :for="`show-${i}`"
                  />
                </div>
              </td>

              <td
                colspan="4"
                @click="editSectionName(section); $event.stopPropagation()"
              >
                <input
                  v-if="!!checkSectionNameInputVisibility(section)"
                  ref="editingControl"
                  v-model="editingValue"
                  class="form-control form-control-sm"
                  @keydown="handleEditingControlKeyDown"
                  @blur="handleEditingControlBlur"
                >

                <template v-else-if="section.name">
                  <span>{{ section.name }}</span>
                </template>

                <template v-else>
                  <span>&nbsp;</span>
                </template>
              </td>

              <td>
                <a
                  href="javascript:void(0)"
                  @click="removeSection(section)"
                >
                  <feather-icon name="trash" />
                </a>
              </td>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(column, j) in section.columns"
              :key="j"
              :data-j="j"
              class="gp-table-column"
            >
              <td>
                <div class="form-check form-check--in-settings">
                  <input
                    :id="`show-${i}-${j}`"
                    class="form-check-input"
                    type="checkbox"
                    :checked="isColumnVisible(column)"
                    @click="toogleColumnVisible(column)"
                  >

                  <label
                    class="form-check-label"
                    :for="`show-${i}-${j}`"
                  />
                </div>
              </td>

              <td
                :colspan="column.type === 'metric' && isClassSelectRendered ? 1 : 2"
                @click="editColumnName(section, column); $event.stopPropagation()"
              >
                <template v-if="column.alias || column.name">
                  <span>{{ resolveVars(column.alias || column.name) }}</span>
                  <span
                    v-if="column.metric && column.metric.timeframe !== column.timeframe && !column.alias"
                    class="gp-table-timeframe"
                  >({{ getTimeframeName(column.timeframe) }})</span>
                  <span v-if="getSelectedClass(column)">
                    ({{ getColumnName(column) }})
                  </span>
                </template>

                <template v-else>
                  <span>&nbsp;</span>
                </template>

                <template
                  v-if="!!checkSectionNameSelectVisibility(section, column)"
                >
                  <div @click="$event.stopPropagation()">
                    <gp-select
                      v-model="editingValue"
                      :autofocus="true"
                      :options="attributes
                        .concat(metrics)
                        .concat(calc_columns)
                        .filter(a => !a.deleted && a.name)
                        .sort((a, b) => a.name.localeCompare(b.name))"
                      recent-options-key="recentColumns"
                      @change="submitEditing"
                    />
                  </div>
                </template>
              </td>

              <td v-if="column.type === 'metric'">
                <my-popup
                  v-if="editingSection === section && editingColumn === column && editingTimeframe"
                  portal="popup"
                  @clickoutside="stopEditing"
                  @escape="stopEditing"
                >
                  <template #anchor>
                    <a href="javascript:void(0)" @click="stopEditing">
                      <feather-icon name="calendar" />
                    </a>
                  </template>

                  <gp-timeframe-select
                    :timeframes="computedTimeframes"
                    :selected-timeframe="column.timeframe"
                    @select-timeframe="updateColumnTimeframe(column, $event)"
                  />
                </my-popup>

                <a
                  v-else
                  href="javascript:void(0)"
                  :title="column.timeframe ? getTimeframeName(column.timeframe) : null"
                  @click="editColumnTimeframe(section, column)"
                >
                  <feather-icon name="calendar" />
                </a>
              </td>

              <td v-else />

              <td>
                <a
                  href="javascript:void(0)"
                  @click="renameColumn(section, column)"
                >
                  <feather-icon name="edit-3" />
                </a>
              </td>

              <td>
                <a
                  href="javascript:void(0)"
                  @click="removeColumn(section, column)"
                >
                  <feather-icon name="trash" />
                </a>
              </td>

              <td v-if="isClassSelectRendered">
                <my-popup
                  v-if="checkClassSelectVisibility(section, column)"
                  portal="popup"
                  @clickoutside="stopEditing"
                  @escape="stopEditing"
                >
                  <template #anchor>
                    <a
                      aria-label="editClass"
                      href="javascript:void(0)"
                      @click="stopEditing"
                    >
                      <feather-icon name="sliders" />
                    </a>
                  </template>

                  <gp-class-select
                    :classes="computedClasses"
                    :selected-class="getSelectedClass(column)"
                    @select-class="updateColumnClass(column, $event)"
                  />
                </my-popup>
                <a
                  v-else
                  aria-label="editClass"
                  href="javascript:void(0)"
                  title="Выбрать название колонки"
                  @click="editColumnClass(section, column)"
                >
                  <feather-icon name="sliders" />
                </a>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td colspan="5">
                <a
                  href="javascript:void(0)"
                  @click="addColumn(section); $event.stopPropagation()"
                >
                  <feather-icon name="plus" />
                  <l10n value="Add column" />
                </a>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <table class="gp-table-settings">
        <tbody>
          <tr class="gp-table-section">
            <td colspan="5">
              <a
                href="javascript:void(0)"
                @click="addSection"
              >
                <feather-icon name="plus" />
                <l10n value="Add section" />
              </a>
            </td>
          </tr>
        </tbody>
      </table>

      <p v-if="!compact">
        <button
          class="btn btn-sm btn-secondary"
          type="button"
          :disabled="!somethingEdited"
          @click="applyChanges"
        >
          <l10n value="Apply changes without saving" />
        </button>
      </p>

      <div
        v-if="!compact"
        class="gp-table-configuration"
      >
        <div class="form-check">
          <input
            id="show-table-config"
            class="form-check-input"
            type="checkbox"
            :checked="showConfig"
            @click="toogleYamlConfig"
          >

          <label class="form-check-label" for="show-table-config">
            <l10n value="Show configuration" />
          </label>
        </div>

        <ace-editor
          v-if="showConfig"
          v-model="configYaml"
          mode="yaml"
          :auto-height="true"
        />

        <button
          v-if="showConfig"
          type="button"
          class="btn btn-sm btn-secondary"
          @click="applyYamlConfig"
        >
          <l10n value="Submit changes" />
        </button>
      </div>
    </portal>

    <portal :to="`gp-discard-tools-${this.uniqueKey}`">
      <gp-discard-tools
        v-if="discardStream"
        :stream="discardStream"
        :user="username"
      />
    </portal>
  </div>
</template>

<script>
const createHyphenator = require('hyphen');
const moment = require('moment');
const patterns = require('hyphen/patterns/ru');
const GpClassSelect = require('./gp-class-attribute-select.vue').default;
const GpDiscardTools = require('./gp-discard-tools.vue').default;
const utils = require('../my-utils');
const ls = require('../api/localStorage');
const graphQl = require('../api/graphQl');

try {
  const hyphenate = createHyphenator(patterns, { async: false });
  window.hyphenate = hyphenate;
} catch (ex) {
  // eslint-disable-next-line no-console
  console.warn(ex);
}

module.exports = {
  mixins: [
    utils.configHelpers,
    utils.extraFilters,
  ],

  components: {
    'gp-class-select': GpClassSelect,
    'gp-discard-tools': GpDiscardTools,
  },

  props: {
    cores: { type: Number, default: 24 },
    compact: { type: Boolean, default: false },
    product: { type: String, default: 'price' },
    darkTheme: { type: Boolean, default: false },
    username: { type: String, default: null },
    groups: { type: Array, default: () => [] },
    stream: { type: String, default: 'default' },
    totals: { type: Boolean, default: true },
    source: { type: Object, default: null },
    hover: { type: Boolean, default: false },
    striped: { type: Boolean, default: false },
    expandable: { type: Boolean, default: false },
    expand: { type: String, default: null },
    actions: { type: Array },
    hash: { type: String },
    filter1: { type: String },
    filter2: { type: String },
    sections: { type: Array, default: () => [] },
    instant: { type: Boolean, default: false },
    throttled: { type: Boolean, default: true },
    hyphenate: { type: Object },
    searchProp: { type: String },
    dimensions: {
      type: Array,
      default: () => ([{
        name: 'Item',
        calc: 'item',
      }, {
        name: 'Store',
        calc: 'store',
      }]),
    },
    initialReferenceDate: { type: String, default: '2020-01-01' },
    initialEffectiveDate: { type: String, default: '2020-01-01' },
    conditions: { type: Object, default: () => ({}) },
    styles: { type: Object, default: () => ({}) },
    zeroThreshold: { type: Number, default: 1e-6 },
    exportFormat: { type: [String, Function] },
    shareGroups: { type: Array, default: () => [] },
    pagination: { type: Boolean, default: false },
    tableActions: { type: Array, default: () => [] },
    kpisMetric1: { type: String, default: 'Forecast' },
    kpisMetric2: { type: String, default: 'Adj. Forecast' },
    kpisMetric3: { type: String, default: 'Plan' },
    uniqueKey: { type: String, default: 'Initial' },
    readReport: { type: Boolean, default: false },
    evaluateButtons: {
      type: Array,
      required: false,
      default: () => [],
    },
    downloadFromUrlButton: {
      type: Object,
      required: false,
      default: () => {},
    },
    uploadToUrlButton: {
      type: Object,
      required: false,
      default: () => {},
    },
    isClassSelectRendered: {
      type: Boolean,
      required: false,
      default: false,
    },
    editCellOnChange: { type: Boolean, default: false },
    discardStream: { type: String, default: '' },
    uniqueTable: { type: String, required: false },
    type: { type: String, required: false, default: 'table' },
    needColumnsIndexes: {
      type: Boolean,
      default: false,
      required: false,
    },
    renderOptimization: {
      type: Boolean,
      default: false,
      required: false,
    },
    headerWithoutSum: {
      type: Boolean,
      required: false,
      default: false,
    },
    changeTypeFromStringToNumber: {
      type: Array,
      required: false,
      default: () => [],
    },
    groupSize: {
      type: Number,
      required: false,
      default: 0,
    },
    optimizationDims: {
      type: Array,
      required: false,
    },
    optimizationVals: {
      type: Array,
      required: false,
    },
    optimizationSections: {
      type: Array,
      required: false,
    },
    tableActionsButtonEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    removeReferenceDate: {
      type: Boolean,
      required: false,
      default: false,
    },
    addDatesToFilter1: {
      type: Boolean,
      required: false,
      default: false,
    },
    dontSortDataFromStream: {
      type: Boolean,
      required: false,
      default: false,
    },
    cellEditingValidations: {
      type: Array,
      required: false,
      default: () => [],
    },
    useActualCurrentDate: {
      type: Boolean,
      required: false,
      default: false,
    },
    getOptionsListFromStream: {
      type: Array,
      required: false,
      default: () => [],
    },
    useTopSettingsSaving: {
      type: Boolean,
      default: false,
    },
    restartStreamButton: {
      type: Object,
      required: false,
      default: () => {},
    },
    showExpandTableButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    refreshTableAfterEditColumns: {
      type: Array,
      required: false,
      default: () => [],
    },
    overrideCompetitorColumn: {
      type: String,
      required: false,
      default: 'store',
    },
    isLoadButtonRendered: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    const showTable = !this.isLoadButtonRendered;
    const currentDateMinusTwoDays = moment().subtract(2, 'days').format('YYYY-MM-DD');

    const referenceDate = this.useActualCurrentDate
      ? currentDateMinusTwoDays : this.initialReferenceDate;
    const effectiveDate = this.initialEffectiveDate;

    window.referenceDate = referenceDate;
    window.effectiveDate = effectiveDate;
    window.uniqueKey = this.uniqueKey;

    const config = this.getUniqueTableDataFromLocalStorage('currentConfig') || {};
    const kpis = this.getUniqueTableDataFromLocalStorage('kpis') || [];
    const editingVars = this.getUniqueTableDataFromLocalStorage('vars') || { target_poi_length: 7, target_cost_change_days: 1 };
    const currentFilter = this.getUniqueTableDataFromLocalStorage('currentFilter') || [];
    const editingFilter = this.getUniqueTableDataFromLocalStorage('editingFilter') || [];
    const currentSections = this.getUniqueTableDataFromLocalStorage('currentSections') || [];
    const editingSections = this.getUniqueTableDataFromLocalStorage('editingSections') || [];
    const currentDimensions = this.getUniqueTableDataFromLocalStorage('currentDimensions') || [];
    const editingDimensions = this.getUniqueTableDataFromLocalStorage('editingDimensions') || [];
    const subTotals = this.getUniqueTableDataFromLocalStorage('subTotals') || false;
    const priceZone = this.getUniqueTableDataFromLocalStorage('priceZone') || null;
    const optimizationId = this.getUniqueTableDataFromLocalStorage('optimizationId') || null;
    const promoType = this.getUniqueTableDataFromLocalStorage('currentRulesConfig')?.promoType || null;

    const editingDimensionsWithUniqueKeys = utils.addUniqueKeysToDimensions(editingDimensions);
    const currentDimensionsWithUniqueKeys = utils.addUniqueKeysToDimensions(currentDimensions);

    let pastTimeframe = this.getUniqueTableDataFromLocalStorage('past-timeframe');
    if (!this.timeframes[pastTimeframe]) {
      pastTimeframe = 'reference_date';
    }

    let futureTimeframe = this.getUniqueTableDataFromLocalStorage('future-timeframe');
    if (!this.timeframes[futureTimeframe]) {
      futureTimeframe = 'next_30_days';
    }

    if (this.hash) {
      const params = new URLSearchParams(this.hash);
      const permalink = params.get('permalink');

      if (permalink) {
        this.loadPermalink(permalink);
        utils.removeLocationHash();
      }
    }

    const searchTerm = localStorage.searchTerm || '';

    const isEvaluateBtnsLoading = new Array(this.evaluateButtons.length).fill(false);

    return {
      _,
      l10n: utils.l10n,
      kpis,
      editingVars,
      config,
      context: null,
      referenceDate,
      effectiveDate,
      currentSections,
      editingSections,
      editingSection: null,
      editingColumn: null,
      editingValue: null,
      editingTimeframe: null,
      editingClass: null,
      editingDimension: null,
      editingFilter,
      currentFilter,
      editingDimensions: editingDimensionsWithUniqueKeys,
      currentDimensions: currentDimensionsWithUniqueKeys,
      subTotals,
      editableSeqN: 0,
      requestDataTimeout: null,
      report: null,
      configYaml: null,
      showConfig: false,
      preventEditing: false,
      pastTimeframe,
      futureTimeframe,
      priceZone,
      optimizationId,
      permalink: null,
      dependencies: null,
      streamLinks: null,
      permalinkCopied: false,
      uploadFileDryRun: null,
      failedEdits: [],
      searchTerm,
      isPastTimeframeSelectVisible: false,
      isFutureTimeframeSelectVisible: false,
      isEvaluateBtnsLoading,
      classAttributes: [],
      unEditableColumns: [],
      downloadFileFromUrlButtonLoading: false,
      uploadFileToUrlButtonLoading: false,
      rulesStartDate: null,
      rulesEndDate: null,
      promoType,
      showTable,
    };
  },

  watch: {
    attributes() { this.actualize(); },
    metrics() { this.actualize(); },
    formulas() { this.actualize(); },
    timeframes() { this.actualize(); },
    permalink() { this.permalinkCopied = false; },
    editingColumn() { _.defer(this.setupEditingControl); },
    editingSection() { _.defer(this.setupEditingControl); },
    editingTimeframe() { _.defer(this.setupEditingControl); },
    editingClass() { _.defer(this.setupEditingControl); },

    config: {
      deep: true,
      handler() {
        this.saveUniqueDataToLocalStorage('currentConfig', this.config);
        this.$emit('config-changed', this.config);
      },
    },

    hash() {
      if (this.hash) {
        const params = new URLSearchParams(this.hash);
        const permalink = params.get('permalink');
        if (permalink) {
          this.loadPermalink(permalink);
          utils.removeLocationHash();
        }
      }
    },

    pastTimeframe() {
      this.pastTimeframe = this.getUniqueTableDataFromLocalStorage('past-timeframe');
    },

    futureTimeframe() {
      this.futureTimeframe = this.getUniqueTableDataFromLocalStorage('future-timeframe');
    },

    editingFilter: {
      deep: true,
      handler(filter) {
        this.saveUniqueDataToLocalStorage('editingFilter', filter);
        this.$set(this.config, 'filter', filter);
      },
    },

    editingSections: {
      deep: true,
      handler(sections) {
        this.saveUniqueDataToLocalStorage('editingSections', sections);
        this.$set(this.config, 'sections', sections);
      },
    },

    editingDimensions: {
      deep: true,
      handler(dimensions) {
        const dimensionsWithUniqueKeys = utils.addUniqueKeysToDimensions(dimensions);

        this.saveUniqueDataToLocalStorage('editingDimensions', dimensionsWithUniqueKeys);
        this.$set(this.config, 'dimensions', dimensionsWithUniqueKeys);
      },
    },

    subTotals(subTotals) {
      this.saveUniqueDataToLocalStorage('subTotals', subTotals);
      this.$set(this.config, 'subTotals', subTotals);
    },

    priceZone() {
      this.saveUniqueDataToLocalStorage('priceZone', this.priceZone);
      const optimizationId = this.getUniqueTableDataFromLocalStorage(`optimizationId-${this.priceZone}`);

      if (optimizationId) {
        this.optimizationId = optimizationId;
      }

      utils.bridge.trigger('priceZoneChanged', this.priceZone);
    },

    currentFilter: {
      deep: true,
      handler(filter) {
        this.saveUniqueDataToLocalStorage('currentFilter', filter);
      },
    },

    currentSections: {
      deep: true,
      handler(sections) {
        this.saveUniqueDataToLocalStorage('currentSections', sections);
      },
    },

    currentDimensions: {
      deep: true,
      handler(dimensions) {
        const dimensionsWithUniqueKeys = utils.addUniqueKeysToDimensions(dimensions);

        this.saveUniqueDataToLocalStorage('currentDimensions', dimensionsWithUniqueKeys);
      },
    },

    filters() {
      utils.bridge.trigger('filtersChanged', this.$refs.table._uid, this.filters);
    },

    referenceDate(date) {
      window.referenceDate = date;
      this.boradcastReferenceDate();
    },

    effectiveDate(date) {
      window.effectiveDate = date;
    },

    dims: {
      deep: true,
      handler(dims) {
        utils.bridge.trigger('primaryDimsChanged', dims);
      },
    },

    kpis: {
      deep: true,
      handler(kpis) {
        kpis = kpis || [];
        this.$set(this.config, 'kpis', kpis);
        this.saveUniqueDataToLocalStorage('kpis', kpis);
      },
    },

    editingVars: {
      deep: true,
      handler(vars) {
        vars = vars || { target_poi_length: 7, target_cost_change_days: 1 };
        this.$set(this.config, 'vars', vars);
        this.saveUniqueDataToLocalStorage('vars', vars);
      },
    },

    optimizationId() {
      if (this.optimizationId) {
        this.saveUniqueDataToLocalStorage('optimizationId', this.optimizationId);
        this.saveUniqueDataToLocalStorage(`optimizationId-${this.priceZone}`, this.optimizationId);
        utils.bridge.trigger('optimizationIdChanged', this.optimizationId);
        this.requestOptimizationRules(this.optimizationId).then((result) => {
          const { rows } = result.data.dataset.streams.optimization_rules.report;
          if (!rows.length) return;
          const config = this.getUniqueTableDataFromLocalStorage('currentRulesConfig');
          delete config.sections;
          const newSections = [{
            id: utils.randomId(),
            name: 'Правила оптимизации',
            rules: rows.map((row) => JSON.parse(row)),
          }];
          config.sections = newSections;
          this.saveUniqueDataToLocalStorage('currentRulesConfig', config);
          utils.bridge.trigger('rulesConfigChanged', config);
        });
      }
    },
  },

  computed: {
    tableActionsEx() {
      const actions = [...this.tableActions];
      if (this.searchProp) {
        actions.push({
          tagname: 'gp-table-search',
          config: {
            props: {
              value: this.searchTerm,
            },
            on: {
              change: (value) => {
                this.searchTerm = value;
              },
            },
          },
          menu: false,
        });
      }
      actions.push({
        icon: 'download',
        text: 'Download as Excel',
        call: () => { this.exportToFile(); return true; },
        menu: false,
      });
      actions.push({
        icon: 'upload',
        text: 'Upload from Excel',
        call: () => { this.importFromFile(); return true; },
        menu: false,
      });
      actions.push({
        icon: 'link',
        text: 'Create report permanent link',
        call: () => { this.createAndShowPermalink(); return true; },
        menu: false,
      });

      if (this.tableActionsButtonEnabled) {
        actions.push({
          icon: 'chevrons-right',
          text: 'Accept all recommendations',
          call: () => { this.acceptAllRecommendations(); return true; },
          // menu: false,
        });
        actions.push({
          icon: 'chevrons-right',
          text: 'Accept all recommendations where strict rules are not violated',
          call: () => { this.acceptAllNonViolatedRecommendations(); return true; },
          // menu: false,
        });
        actions.push({
          icon: 'check',
          text: 'Approve my prices changes',
          call: () => { this.approveMyPriceChanges(); return true; },
          // menu: false,
        });
        actions.push({
          icon: 'check',
          text: 'Approve all prices changes',
          call: () => { this.approveAllPriceChanges(); return true; },
          // menu: false,
        });
        actions.push({
          icon: 'trash',
          text: 'Remove my prices changes',
          call: () => { this.resetMyPriceChanges(); return true; },
        });
        actions.push({
          icon: 'trash',
          text: 'Remove all prices changes',
          call: () => { this.resetAllPriceChanges(); return true; },
        });
      }

      if (this.hasSomeFilters) {
        actions.push({
          icon: 'filter',
          text: 'Remove all filters',
          call: () => { this.removeAllFilters(); return true; },
        });
      }

      return actions;
    },

    hasSomeFilters() {
      const hasFilters = (column) => column.hideZeroRows || column.hideEmptyRows || column.condition && column.condition !== 'none' || !_.isEmpty(column.values);

      if (_.some(this.dims, hasFilters) || _.some(this.cols, hasFilters)) {
        return true;
      }

      return false;
    },

    caption() {
      return `${this.config && this.config.name || 'report'}`;
    },

    dimesionsOptions() {
      return this.attributes
        .filter((attribute) => !attribute.deleted && attribute.name)
        .map((attribute) => _.assign(_.cloneDeep(attribute), { attribute, id: utils.randomId() }))
        .sort((a, b) => a.name.localeCompare(b.name));
    },

    computedTimeframes() {
      this.timeframes.past.calc = this.timeframes[this.pastTimeframe].calc;
      this.timeframes.future.calc = this.timeframes[this.futureTimeframe].calc;
      return _(this.timeframes).filter((timeframe) => !timeframe.deleted);
    },

    computedClasses() {
      return this.classAttributes;
    },

    fixedTimeframes() {
      return _(this.timeframes)
        .filter((timeframe) => !timeframe.deleted && timeframe.id !== 'past' && timeframe.id !== 'future');
    },

    pastTimeframeName() {
      return this.timeframes[this.pastTimeframe]?.name || '...';
    },

    futureTimeframeName() {
      return this.timeframes[this.futureTimeframe]?.name || '...';
    },

    pastTimeframeTitle() {
      try {
        const referenceDate = this.parseDate(this.referenceDate);
        const timeframe = this.timeframes[this.pastTimeframe];
        if (!timeframe) {
          return '...';
        }
        const [startDate, endDate] = eval(timeframe.calc)(referenceDate);
        if (startDate.getTime() === endDate.getTime()) {
          return startDate.toLocaleDateString();
        }

        return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
      } catch (ex) {
        console.warn('pastTimeframeTitle', ex);
      }
    },

    futureTimeframeTitle() {
      try {
        const referenceDate = this.parseDate(this.referenceDate);
        const timeframe = this.timeframes[this.futureTimeframe];
        if (!timeframe) {
          return '...';
        }
        const [startDate, endDate] = eval(timeframe.calc)(referenceDate);
        if (startDate.getTime() === endDate.getTime()) {
          return startDate.toLocaleDateString();
        }

        return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
      } catch (ex) {
        console.warn('futureTimeframeTitle', ex);
      }
    },

    filters() {
      const filter0 = [];
      const filter1 = [];
      const filter2 = [];
      const filter3 = [];

      if (this.context && this.context.row) {
        const { dims } = this.context.meta;

        for (let i = 0; i < dims.length; ++i) {
          const dim = dims[i];
          const value = this.context.row[i];
          let calc = _.isString(dim)
            ? dim
            : dim.calc;

          if (this.product != 'pim' && ['item', 'store', 'class', 'region', 'ITEM_NUMBER'].indexOf(calc) !== -1) {
            filter0.push(`${calc} == ${utils.quote(value)}`);
            filter1.push(`${calc} == ${utils.quote(value)}`);
          } else {
            if (calc.indexOf('.') !== -1) {
              if (_.isString(value)) {
                calc = `isnull(${calc}, '')`;
              }

              if (_.isNumber(value)) {
                calc = `isnull(${calc}, 0.0)`;
              }
            }
            filter2.push(`${calc} == ${utils.quote(value)}`);
          }
        }
      }

      return {
        groups: this.groups.slice(0, 1),
        stream: this.stream,
        filter0: filter0.join(' && '),
        filter1: filter1.join(' && '),
        filter2: filter2.join(' && '),
        filter3: filter3.join(' && '),
      };
    },

    filter0() {
      switch (this.product) {
        case 'price': {
          const datesFilter = _(this.currentSections)
            .map('columns')
            .flatten()
            .value();
          return this.makeDatesFilter(datesFilter);
        }
        default: return '';
      }
    },

    extendedFilter1() {
      const filter1 = [];

      if (this.filter1) {
        filter1.push(this.filter1);
      }

      if (this.addDatesToFilter1) {
        const datesFilter = _(this.currentSections).map('columns').flatten().value();
        const datesString = this.makeDatesFilter(datesFilter);
        filter1.push(datesString);
      }

      return filter1.join(' && ');
    },

    filter2_() {
      const filter2 = [];
      if (this.filter2) {
        filter2.push(this.filter2);
      }
      if (this.searchProp && this.searchTerm) {
        filter2.push(`${this.searchProp} like ${utils.quote(`*${this.searchTerm.toLowerCase()}*`)}`);
      }

      return filter2.join(' && ');
    },

    filter3() {
      const filter3 = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const col of this.cols) {
        if (col.hideZeroRows) {
          filter3.push(`abs(${col.calc}) > ${this.zeroThreshold}`);
        }

        if (col.hideEmptyRows) {
          filter3.push(`(${col.calc}) != ''}`);
        }

        if (col.condition && col.condition !== 'none' && this.conditions[col.condition]) {
          const condition = this.conditions[col.condition];
          const args = (col.args || []).slice(0, _.size(condition.args));

          for (let i = 0; i < args.length; ++i) {
            if (condition.args[i] === 'number') {
              args[i] = parseFloat(args[i]) || 0;
            }
          }

          filter3.push(eval(condition.func)(col.calc, args[0], args[1]));
        }

        if (!_.isEmpty(col.values)) {
          const include = [];
          const exclude = [];

          // eslint-disable-next-line no-restricted-syntax
          for (const value of Object.keys(col.values)) {
            if (col.values[value]) {
              include.push(value);
            } else {
              exclude.push(value);
            }
          }
          if (include.length > 0) {
            filter3.push(`(${col.calc}) in [${include.map(utils.quote).join(',')}]`);
          }
          if (exclude.length > 0) {
            filter3.push(`!((${col.calc}) in [${exclude.map(utils.quote).join(',')}])`);
          }
        }
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const dim of this.dims) {
        const i = this.dims.indexOf(dim) + 1;

        if (dim.hideZeroRows) {
          filter3.push(`abs(col${i}) > ${this.zeroThreshold}`);
        }

        if (dim.hideEmptyRows) {
          filter3.push(`(col${i}) != ''`);
        }

        if (dim.condition && dim.condition !== 'none' && this.conditions[dim.condition]) {
          const condition = this.conditions[dim.condition];
          const args = dim.args || [];

          filter3.push(eval(condition.func)(`col${i}`, args[0], args[1]));
        }

        if (!_.isEmpty(dim.values)) {
          const include = [];
          const exclude = [];

          // eslint-disable-next-line no-restricted-syntax
          for (const value of Object.keys(dim.values)) {
            if (dim.values[value]) {
              include.push(value);
            } else {
              exclude.push(value);
            }
          }

          if (include.length > 0) {
            let condition = `(col${1}) in [${include.map(utils.quote).join(',')}]`;
            condition = `isnull(${condition}, ${include.indexOf('') !== -1})`;
            filter3.push(condition);
          }

          if (exclude.length > 0) {
            let condition = `!((col${1}) in [${exclude.map(utils.quote).join(',')}])`;
            condition = `isnull(${condition}, ${exclude.indexOf('') === -1})`;
            filter3.push(condition);
          }
        }
      }

      return filter3.join(' && ');
    },

    dims() {
      const dims = [];
      const section = 'Dimensions';
      const referenceDate = utils.parseDate(this.referenceDate);
      const date = utils.nextDate(referenceDate);

      _.forEach(this.currentDimensions, (dimension, i) => {
        const [style, rowStyle] = this.makeStyles(dimension);
        const calc = this.resolveDateConditions(this.resolveSubstitutes(dimension.calc), date, date, referenceDate);
        const baseDim = {
          section,
          order: 0,
          id: `dim${i + 1}`,
          style,
          rowStyle,
        };
        let dim;

        if (this.type === 'tree' && i === 0) {
          baseDim.actionable = true;
          baseDim.actionicon = (row) => row.opened === null ? 'noicon' : row.opened ? 'minus' : 'plus';
          baseDim.action = (row) => Vue.set(this.$refs.table.openedRows, row.key, !this.$refs.table.openedRows[row.key]);
          dim = _.assign(baseDim, _.omit(dimension, ['style', 'rowStyle', 'calc', 'editable', 'actionable']), { calc });
        } else {
          dim = _.assign(baseDim, _.omit(dimension, ['style', 'rowStyle', 'calc', 'editable']), { calc });
        }

        this.assignColumnFilter(dim);
        dims.push(Object.freeze(dim));
      });

      return dims;
    },

    vars() {
      const timeframe = this.timeframes[this.futureTimeframe];

      const [startDate, endDate] = eval(timeframe.calc)(this.referenceDate);
      const formatedStartDate = moment(startDate).format('YYYY-MM-DD');
      const formatedEndDate = moment(endDate).format('YYYY-MM-DD');

      const vars = {
        target_reference_date: `\`${this.referenceDate}\``,
        target_optimization_run_id: utils.quote(this.optimizationId || ''),
        strategyStartDate: `\`${this.rulesStartDate || formatedStartDate}\``,
        strategyEndDate: `\`${this.rulesEndDate || formatedEndDate}\``,
      };

      if (this.priceZone) {
        vars.target_price_zone = this.priceZone;
      }

      for (const key of _.keys(this.editingVars)) {
        const val = this.editingVars[key];
        vars[key] = utils.quote(val);
      }

      utils.bridge.trigger('varsChanged', vars);
      return vars;
    },

    vals() {
      const vals = {};

      // eslint-disable-next-line no-restricted-syntax
      for (const section of this.currentSections) {
        // eslint-disable-next-line no-restricted-syntax
        for (const column of section.columns) {
          if (column.visible !== false) {
            this.makeVals(vals, section, column);
          }
        }
      }

      return _(vals)
        .toPairs()
        .map(([name, calc]) => ({
          name,
          calc: `${calc} as ${name}`,
          show: false,
        }))
        .sortBy('calc')
        .map(Object.freeze)
        .value();
    },

    cols() {
      const cols = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const section of this.currentSections) {
        // eslint-disable-next-line no-restricted-syntax
        for (const column of section.columns) {
          if (column.visible !== false) {
            this.makeCols(cols, section, column);
          }
        }
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const col of cols) {
        this.assignColumnFilter(col);
      }

      return _(cols)
        .sortBy('id')
        .map(Object.freeze)
        .value();
    },

    initialSort() {
      if (this.dontSortDataFromStream) {
        return [];
      }

      return _.map(this.dims, (dim, i) => i + 1);
    },

    somethingEdited() {
      return !_.isEqual(this.currentFilter, this.editingFilter)
        || !_.isEqual(this.currentSections, this.editingSections)
        || !_.isEqual(this.currentDimensions, this.editingDimensions);
    },
  },

  created() {
    if (this.useTopSettingsSaving) {
      utils.bridge.bind('topSettingsChanged', this.topSettingsChanged);
    }

    if (this.uniqueTable === 'lama-pages_promo-report') {
      utils.bridge.bind('promoTypeChanged', this.promoTypeChanged);
    }
  },

  beforeMount() {
    this.boradcastReferenceDate();
    this.actualize();
  },

  mounted() {
    utils.bridge.bind('showTable', this.showPlainTable);
    utils.bridge.bind('rulesDatesChanged', this.rulesDatesChanged);
    utils.bridge.bind('getVars', () => utils.bridge.trigger('varsChanged', this.vars));
    document.addEventListener('click', this.interceptClickEvent);
    window.addEventListener('hashchange', this.handleHashChange);
    window.gptable = this;
    _.defer(this.setupSortable, 100);
    this.updateReports = {};

    if (this.$refs.table) {
      this.$refs.table.pageSize = this.getUniqueTableDataFromLocalStorage('pageSize') || 20;
      this.$refs.table.desiredSort = this.getUniqueTableDataFromLocalStorage('desiredSort') || [];
      this.$refs.table.desiredPage = this.getUniqueTableDataFromLocalStorage('desiredPage') || 0;
      this.$refs.table.filters = this.getUniqueTableDataFromLocalStorage('filters') || [];
    }

    if (this.isClassSelectRendered) {
      this.getColumnClasses();
    }
  },

  beforeDestroy() {
    utils.bridge.unbind('showTable', this.showPlainTable);
    document.removeEventListener('click', this.interceptClickEvent);
    window.removeEventListener('hashchange', this.handleHashChange);
    $('.gp-settings-sections').sortable('destroy');
    $('.gp-table-settings').sortable('destroy');

    utils.bridge.unbind('rulesDatesChanged', this.rulesDatesChanged);

    if (this.useTopSettingsSaving) {
      utils.bridge.unbind('topSettingsChanged', this.topSettingsChanged);
    }

    if (this.uniqueTable === 'lama-pages_promo-report') {
      utils.bridge.unbind('promoTypeChanged', this.promoTypeChanged);
    }
  },

  methods: {
    showPlainTable() {
      this.showTable = true;
    },
    requestOptimizationRules(id) {
      return graphQl.makeGQRequest(`
       query {
          dataset {
            streams {
              optimization_rules {
                           
            report(
                cores: 64,
                filter2: "optimization_run_id=='${id}'",
                dims: "optimization_rule_data",
                               
                sort: [])
            {
                size
                rows
                columns { name type synonym }
            } 
                        }
                    }
                }
            }`);
    },
    promoTypeChanged(type) {
      this.promoType = type;
    },
    rulesDatesChanged({ startDate, endDate }) {
      this.rulesStartDate = startDate;
      this.rulesEndDate = endDate;
    },
    topSettingsChanged() {
      if (this.isLoadButtonRendered) {
        this.showTable = false;
      }

      const proxyConfig = _.cloneDeep(this.config);
      const filter = localStorage[`${this.uniqueKey}_gp-search-filter`] || '[]';
      const classes = localStorage[`${this.uniqueKey}_gp-search-classes`] || '[]';
      const categories = localStorage[`${this.uniqueKey}_gp-search-categories`] || '[]';

      const topSettings = {
        filter,
        classes,
        categories,
      };

      this.config = {
        ...proxyConfig,
        topSettings,
      };
    },

    refreshTable() {
      if (this.showTable) {
        this.$refs.table.refreshData();
      }
    },

    getColumnClasses() {
      graphQl.makeGQRequest(`
        query {
            dataset {
                source(name: "grades") {
                    report(
                        cores: 64,
                        cache: true,
                        dims: "class",
                        sort: [])
                    {
                        rows
                        columns {
                          synonym
                        }
                    }
                }
            }
        }`).then((result) => {
        if (result.type && result.type === 'error') {
          this.createNotification(result.text, result.type);
          return;
        }

        const { data: { dataset: { source: { report: { columns, rows } } } } } = result;
        const { synonym } = columns[0];
        const classes = rows.map((cls) => {
          const classItem = cls[0];
          const classObj = {
            name: classItem,
            column: synonym,
          };
          return classObj;
        });

        this.classAttributes = classes;
      }).catch(() => {
        this.createNotification('Error while getting attributes', 'error');
      });
    },

    getRowColsData(colCalc, tableRow, meta, column) {
      const { cols, dims } = meta;
      return tableRow[
        cols.findIndex((col) => col.calc === colCalc
                      && col.section === column.section)
        + dims.length
      ];
    },

    checkSectionNameInputVisibility(section) {
      return this.editingSection === section
        && !this.editingTimeframe
        && !this.editingClass
        && !this.editingColumn;
    },

    checkSectionNameSelectVisibility(section, column) {
      return this.editingSection === section
        && this.editingColumn === column
        && !this.editingTimeframe
        && !this.editingClass;
    },

    checkClassSelectVisibility(section, column) {
      return this.editingSection === section
        && this.editingColumn === column
        && !this.editingTimeframe
        && this.editingClass;
    },

    checkUnEditableColumn(column) {
      return this.unEditableColumns.findIndex((col) => col.id === column.id) !== -1;
    },

    handleGradeFromTotalArea(column, action) {
      const data = {
        target_group: column.section,
        user: this.username,
      };

      const url = '/api/calculation/grade-from-total-area';
      const options = {
        method: action === 'delete' ? 'DELETE' : 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      };

      fetch(url, options)
        // TODO: make error handling for this case
        .catch((error) => console.error(error.text));
    },

    getSelectedClass(column) {
      return column?.classObj?.name;
    },

    getValueFromExtraFilter(filterName) {
      const filtersString = this.extraFilter2.split('&&');
      const filterString = filtersString.find((filter) => filter.includes(filterName));

      if (!filterString) {
        return null;
      }

      const filterValue = filterString.substring(filterString.indexOf('[') + 1, filterString.indexOf(']'));
      return filterValue.replaceAll('"', '');
    },

    getFilterValueFromObjectWithLevel(object, key, level) {
      if (String(object[key]).includes('***') || level > object.level) {
        return `(${key} == \\"\\")`;
      }
      return `(${key} == \\"${object[key]}\\")`;
    },

    setPastTimeframe(timeframe) {
      this.saveUniqueDataToLocalStorage('past-timeframe', timeframe);
      this.timeframes.past.calc = this.timeframes[timeframe].calc;
      this.pastTimeframe = this.getUniqueTableDataFromLocalStorage('past-timeframe');
      this.isPastTimeframeSelectVisible = false;
    },

    setFutureTimeframe(timeframe) {
      this.saveUniqueDataToLocalStorage('future-timeframe', timeframe);
      this.timeframes.future.calc = this.timeframes[timeframe].calc;
      this.futureTimeframe = this.getUniqueTableDataFromLocalStorage('future-timeframe');
      this.isFutureTimeframeSelectVisible = false;
    },

    selectUploadFileColumn(column, selected) {
      const entries = this.uploadFileDryRun.result.report;
      const { stream } = entries.find((entry) => entry.column == column);
      const columns = entries
        .filter((entry) => entry.stream == stream)
        .filter((entry) => entry.column == column || entry.position != entries.find(entry => entry.column == column).position)
        .map((entry) => entry.column);

      // eslint-disable-next-line no-restricted-syntax
      for (column of columns) {
        if (selected) {
          this.$set(this.uploadFileDryRun.columns, column, true);
        } else {
          this.$delete(this.uploadFileDryRun.columns, column);
        }
      }

      if (selected) {
        const metrics = this.metrics.filter((metric) => !metric.deleted && metric.editable && metric.stream == stream);
        const maxPosition = _(metrics).map('position').max();

        if (maxPosition && maxPosition > columns.length - 1) {
          const metr = metrics
            .filter((metric) => !entries.find((entry) => entry.formula == metric.formula))
            .map((metric) => metric.name);

          this.$set(this.uploadFileDryRun.missing, stream, metr);
        }
      } else {
        this.$delete(this.uploadFileDryRun.missing, stream);
      }
    },

    showMessage(message) {
      window.alert(message);
    },

    handleDesiredSortChanged(desiredSort) {
      this.saveUniqueDataToLocalStorage('desiredSort', desiredSort);

      if (this.config) {
        this.$set(this.config, 'desiredSort', desiredSort);
      }
    },

    handleTableFiltersChanged(filters) {
      this.saveUniqueDataToLocalStorage('filters', filters);

      if (this.config) {
        this.$set(this.config, 'filters', filters);
      }
    },

    handleDesiredPageChanged(event) {
      this.saveUniqueDataToLocalStorage('desiredPage', event);
    },

    handlePageSizeChanged(event) {
      this.saveUniqueDataToLocalStorage('pageSize', event);
    },

    handleEvaluateButtonClick(evaluateButton) {
      const baseUrl = '/api/';
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const url = baseUrl + evaluateButton.name;
      const data = {};

      if (evaluateButton.user) {
        data.user = this.username;
      }

      if (evaluateButton.body) {
        data.body = evaluateButton.body;
      }

      options.body = JSON.stringify(data);

      // Removes "false" and adds "true" for this evaluateButton reactivity loading start
      this.isEvaluateBtnsLoading.splice(this.evaluateButtons.indexOf(evaluateButton), 1, true);

      fetch(url, options)
        .then((response) => response.json())
        .then((result) => {
          if (result?.detail.length) {
            this.createNotification(result.detail[0], 'error');
            return;
          }

          if (evaluateButton.successMessage) {
            this.createNotification(evaluateButton.successMessage, 'success');
          }

          if (evaluateButton.refresh) {
            this.refreshTable();
          }
        })
        .catch((error) => {
          this.createNotification(error.message, 'error');
        })
        .finally(() => {
          // Removes "true" and adds "false" for this evaluateButton reactivity loading end
          this.isEvaluateBtnsLoading.splice(this.evaluateButtons.indexOf(evaluateButton), 1, false);
        });
    },

    getTimeframeName(timeframe) {
      return this.timeframes[timeframe]?.name || timeframe;
    },

    getColumnName(column) {
      return column?.classObj?.name;
    },

    getTableActionsElement() {
      const el = this.$refs.table.$el.querySelector('.plain-table-table-actions');
      return el;
    },

    assignColumnFilter(column) {
      const filters = [];

      if (column.hideZeroRows) {
        filters.push((x) => Math.abs(x) > this.zeroThreshold);
      }

      if (column.hideEmptyRows) {
        filters.push((x) => x != '');
      }

      if (!_.isEmpty(column.values)) {
        const include = new Set();
        const exclude = new Set();

        // eslint-disable-next-line no-restricted-syntax
        for (const value of Object.keys(column.values)) {
          if (column.values[value]) {
            include.add(value);
          } else {
            exclude.add(value);
          }
        }

        if (!_.isEmpty(include)) {
          filters.push((x) => include.has(x));
        }

        if (!_.isEmpty(exclude)) {
          filters.push((x) => !exclude.has(x));
        }
      }

      const [a, b] = column.args || [];

      switch (column.condition) {
        case 'is_empty':
          filters.push((x) => !x);
          break;
        case 'is_not_empty':
          filters.push((x) => x);
          break;
        case 'text_contains':
          filters.push((x) => _.includes(x, a));
          break;
        case 'text_doesnt_contain':
          filters.push((x) => !_.includes(x, a));
          break;
        case 'text_starts_with':
          filters.push((x) => _.startsWith(x, a));
          break;
        case 'text_ends_with':
          filters.push((x) => _.endsWith(x, a));
          break;
        case 'text_is_exactly':
          filters.push((x) => x == a);
          break;
        case 'greater_than':
          filters.push((x) => x > a);
          break;
        case 'greater_than_or_equal':
          filters.push((x) => x >= a);
          break;
        case 'less_than':
          filters.push((x) => x < a);
          break;
        case 'less_than_or_equal':
          filters.push((x) => x <= a);
          break;
        case 'is_equal_to':
          filters.push((x) => x == a);
          break;
        case 'is_not_equal_to':
          filters.push((x) => x != a);
          break;
        case 'is_between':
          filters.push((x) => x >= a && x <= b);
          break;
        case 'is_not_betweeen':
          filters.push((x) => x < a || x > b);
          break;
        default: break;
      }

      if (!_.isEmpty(filters)) {
        column.filter = (x) => _.every(filters, f => f(x));
      }
    },

    getUniqueTableDataFromLocalStorage(key) {
      const response = ls.loadFromLocalStorage(this.uniqueKey, key);

      if (response && response.type === 'error') {
        setTimeout(() => {
          this.createNotification(response, 'error');
          return undefined;
        }, 0);
      }

      return response;
    },

    saveUniqueDataToLocalStorage(key, value) {
      return ls.saveToLocalStorage(this.uniqueKey, key, value);
    },

    handleFileUpload(e) {
      const file = e.target.files[0];
      this.$refs.uploadForm.reset();
      this.uploadFile(file, true, []);
    },

    handleFileToUrlUpload(event, url) {
      const file = event.target.files[0];
      this.$refs.uploadToUrlForm.reset();
      this.uploadFileToUrl(file, url);
    },

    async downloadAsExcel(rows, file) {
      const XLSX = await import('xlsx');
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet(rows);
      XLSX.utils.book_append_sheet(workbook, worksheet, 'report');
      XLSX.writeFile(workbook, file);
    },

    async uploadFile(file, dryRun, columns) {
      const formData = new FormData();
      formData.append('file', file);

      const { rows } = await Promise.resolve($.ajax({
        url: '/import2',
        type: 'POST',
        data: formData,
        processData: false,
        contentType: false,
      }));

      let result = {
        no_keys: {},
        columns: [],
        changes: 0,
        report: [],
      };

      const { table } = this.$refs;

      if (!table.report) {
        window.alert(utils.l10n('Please wait for report to load'));
        return;
      }

      const header = rows[1];
      const groups = {};

      for (let i = 0; i < header.length; ++i) {
        const name = header[i];
        const col = table.columns.find((column) => column.name == name);

        if (!col) {
          continue;
        }

        if (columns.length > 0 && !columns.includes(col.name)) {
          continue;
        }

        if (col.editable && col.stream && col.keys && !['competitors_override'].includes(col.stream)) {
          // ???
          if (app.isCategoryManager(app.username) && col.editable.toString && col.editable.toString() == '(row, col) => !app.isCategoryManager(app.username)') {
            continue;
          }

          if (!groups[col.stream]) {
            groups[col.stream] = [];
          }

          groups[col.stream].push({ col, name, i });
        }
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const stream of _.keys(groups)) {
        const queries = [];

        // eslint-disable-next-line no-restricted-syntax
        for (const { col, i } of groups[stream]) {
          const keys = [];

          // eslint-disable-next-line no-restricted-syntax
          for (const key of col.keys) {
            const dim = table.columns.find((column) => column.calc == key && column.section == 'Dimensions');
            const pos = header.indexOf(dim?.name);
            if (pos == -1) {
              if (!result.no_keys[col.name]) {
                result.no_keys[col.name] = [];
              }

              result.no_keys[col.name].push(key);
            } else {
              keys.push({ pos, dim });
            }
          }

          const index = {};
          const is = keys.map(({ dim: { i } }) => i);
          const dims = table.columns.filter((column) => column.section == 'Dimensions');

          // eslint-disable-next-line no-restricted-syntax
          for (let row of table.report.rows) {
            row = table.rowOverrides[row.key] || row;
            const key = is.map(i => row[i]);
            index[key.join('; ')] = {
              val: row[col.i],
              dims: dims.map((dim) => row[dim.i]),
              editable: col.editable(row, col),
            };
          }

          if (keys.length == col.keys.length) {
            result.columns.push(col.name);

            const stats = {
              formula: col.metric?.formula,
              column: col.name,
              position: col.position || 0,
              stream,
              notFound: 0,
              notFoundRows: [
                [utils.l10n('not found')],
                keys.map((key) => key.dim.name).concat([col.name]),
              ],
              changed: 0,
              changedRows: [
                [utils.l10n('changes')],
                dims.map((dim) => dim.name).concat([
                  utils.l10n('Old value {column}').replace('{column}', col.name),
                  col.name,
                ]),
              ],
              unchanged: 0,
              notAllowed: 0,
              notAllowedRows: [
                [utils.l10n('not allowed')],
                dims.map((dim) => dim.name).concat([col.name]),
              ],
            };

            const extra = [];

            if (col.stream == 'costs_override') {
              extra.push(utils.formatDate(utils.nextDate(utils.parseDate(this.referenceDate))));
            }

            const time = _.now();
            const user = this.username;
            const records = {};
            const changed = [];
            const js = keys.map(({ pos }) => pos);

            // eslint-disable-next-line no-restricted-syntax
            for (const row of rows.slice(2)) {
              const key = js.map((j) => row[j]);
              let val = row[i];

              switch (col.type) {
                case 'string':
                case 'tagged':
                  if (val == null) {
                    val = '';
                  }
                  if (_.isNumber(val)) {
                    val = Math.round(val * 1e4) / 1e4;
                  }
                  if (!_.isString(val)) {
                    val = `${val}`;
                  }
                  break;
                case 'int8':
                case 'int16':
                case 'int32':
                case 'int64':
                case 'docid':
                  if (_.isString(val)) {
                    val = parseInt(val, 10);
                  }
                  if (val == null) {
                    val = 0;
                  }
                  if (_.isNaN(val)) {
                    val = 0;
                  }
                  break;
                case 'float':
                case 'double':
                  if (_.isString(val)) {
                    val = parseFloat(val);
                  }
                  if (val == null) {
                    val = 0;
                  }
                  if (_.isNaN(val)) {
                    val = 0;
                  }
                  break;
                case 'date':
                  if (val) {
                    const date = new Date('January 1, 1900');
                    date.setDate(val - 1);
                    val = this.formatDate(date);
                  }
                  break;
                default: break;
              }

              const entry = index[key.join('; ')];
              const isEqual = (x, y) => {
                const normalize = (z) => {
                  if (_.isDate(z)) return this.formatDate(z);
                  if (_.isNumber(z)) return Math.round(z * 1000);
                  return z;
                };
                return _.isEqual(normalize(x), normalize(y));
              };

              if (entry === undefined) {
                stats.notFound += 1;
                stats.notFoundRows.push(key.concat([val]));
              } else if (!entry.editable) {
                if (!isEqual(val, entry.val)) {
                  stats.notAllowed += 1;
                  stats.notAllowedRows.push(entry.dims.concat([val]));
                }
              } else {
                records[key.join('; ')] = js
                  .map((j) => row[j])
                  .concat([user, time, val])
                  .concat(extra);

                if (!isEqual(val, entry.val)) {
                  changed.push(key.join('; '));
                  stats.changed += 1;
                  stats.changedRows.push(entry.dims.concat([entry.val, val]));
                } else if (val) {
                  stats.unchanged += 1;
                }
              }
            }

            result.changes += _.size(records);
            if (!dryRun) {
              queries.push({ records, col, changed });
            }
            result.report.push(stats);
          }
        }

        const groupedRecords = {};

        // collect all changed record
        // eslint-disable-next-line no-restricted-syntax
        for (const { records, changed, col } of queries) {
          // eslint-disable-next-line no-restricted-syntax
          for (const key of changed) {
            const record = records[key];
            if (!groupedRecords[key]) {
              groupedRecords[key] = [];
            }
            groupedRecords[key].push({ record, col });
          }
        }

        // update it with values from related unchanged records
        // eslint-disable-next-line no-restricted-syntax
        for (const { records, col } of queries) {
          // eslint-disable-next-line no-restricted-syntax
          for (const key of _.keys(groupedRecords)) {
            const record = records[key];
            if (record) {
              groupedRecords[key].push({ record, col });
            }
          }
        }

        const mergedRecords = _.values(groupedRecords).map((groupedRecord) => {
          const mergedRecord = [];
          // eslint-disable-next-line no-restricted-syntax
          for (const { record, col } of groupedRecord) {
            const position = col.position || 0;

            for (let i = 0; i < col.keys.length + 2; ++i) {
              mergedRecord[i] = record[i];
            }
            for (let i = col.keys.length + 2; i < record.length; ++i) {
              mergedRecord[i + position] = record[i];
            }
          }

          return mergedRecord;
        });

        if (!_.isEmpty(mergedRecords)) {
          const query = `
                        mutation {
                            appendRecords(
                                stream: ${utils.quote(stream)},
                                format: ${utils.quote('json')},
                                records: ${utils.quote(JSON.stringify(_.values(mergedRecords)))})
                        }`;

          // eslint-disable-next-line no-await-in-loop
          await fetch('/graphql', {
            method: 'POST',
            body: JSON.stringify({ query }),
            headers: { 'Content-Type': 'application/json' },
          });

          utils.bridge.trigger('streamModified', stream);
        }
      }

      const error = null;

      if (error) {
        const faildToImport = utils
          .l10n('Failed to import data from {file}: {error}')
          .replace('{file}', file.name)
          .replace('{error}', utils.l10n(error));
        window.alert(faildToImport);
      } else if (_.isEmpty(result.columns) && _.isEmpty(result.no_keys)) {
        const columnsNotDetected = utils
          .l10n('Editable columns are not detected in file {file}')
          .replace('{file}', file.name);
        window.alert(columnsNotDetected);
      } else if (dryRun) {
        result = utils.deepFreeze(result);
        this.uploadFileDryRun = {
          error,
          result,
          file,
          columns: {},
          missing: {},
        };
      } else {
        this.refreshTable();
        const messages = [];
        const baseMsg = utils
          .l10n('Imported {changes} from file {file}')
          .replace('{changes}', Number(result.changes).toLocaleString())
          .replace('{file}', file.name);

        messages.push(baseMsg);

        // eslint-disable-next-line no-restricted-syntax
        for (const stats of result.report) {
          const msg = utils
            .l10n('{column}: {changed} changed, {not changed} not changed, {not found} not found, {not allowed} not allowed.')
            .replace('{column}', stats.column)
            .replace('{changed}', stats.changed)
            .replace('{not changed}', stats.unchanged)
            .replace('{not found}', stats.notFound)
            .replace('{not allowed}', stats.notAllowed);

          messages.push(msg);
        }

        window.alert(messages.join('\n'));
      }
    },

    uploadFileToUrl(file, url) {
      this.uploadFileToUrlButtonLoading = true;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('stream_name', this.uploadToUrlButton.stream_name);
      formData.append('csv_name', this.uploadToUrlButton.csv_name);

      const options = {
        method: 'POST',
        body: formData,
      };

      fetch(url, options)
        .then((response) => response.json())
        .then((result) => {
          this.uploadFileToUrlButtonLoading = false;

          if (result?.detail) {
            this.createNotification(result.detail[0], 'error');
            return;
          }

          if (result.text && result.type) {
            this.createNotification(result.text, result.type);
            this.refreshTable();
          }
        })
        .catch((error) => {
          this.createNotification('Во время загрузки файла произошла ошибка, попробуйте еще раз, пожалуйста', 'error');
          this.uploadFileToUrlButtonLoading = false;
        });
    },

    importFromFile() {
      $(this.$refs.uploadInput).trigger('click');
    },

    downloadFileFromUrl() {
      const url = this.downloadFromUrlButton.value;
      const data = {};
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      this.downloadFileFromUrlButtonLoading = true;

      data.columns = this.downloadFromUrlButton.columns;

      if (this.downloadFromUrlButton.title) {
        data.title = this.downloadFromUrlButton.title;
      }
      if (this.downloadFromUrlButton.template_name) {
        data.template_name = this.downloadFromUrlButton.template_name;
      }

      options.body = JSON.stringify(data);

      fetch(url, options)
        .then(async (response) => {
          const fileNameRegEx = /(?<=filename=").+(?=")/gm;
          const fileName = response.headers.get('content-disposition').match(fileNameRegEx)?.[0];
          const file = await response.blob();
          return { file, fileName };
        })
        .then((result) => {
          this.downloadFileFromUrlButtonLoading = false;
          utils.createAndDownloadFileFromBlob(result.file, result.fileName);
        })
        .catch(() => {
          this.createNotification('Во время скачивания шаблона произошла ошибка, попробуйте еще раз, пожалуйста', 'error');
          this.downloadFileFromUrlButtonLoading = false;
        });
    },

    importFromFileToUrl() {
      $(this.$refs.uploadToUrlInput).trigger('click');
    },

    exportToFile() {
      let { exportFormat } = this;

      if (_.isString(exportFormat)) {
        try {
          exportFormat = eval(exportFormat);
        } catch (ex) {
          console.warn(exportFormat, ex);
          exportFormat = undefined;
        }
      }

      if (!exportFormat) {
        exportFormat = () => ({});
      }

      const el = $(this.$refs.table.$refs.table).find('> thead > tr > th:first-child');
      const rows = _.map(el, (row) => ({
        height: $(row).outerHeight(),
        format: { text_wrap: true },
      }));

      if (rows.length > 0) {
        rows[0].format.bold = true;
      }

      if (rows.length > 1) {
        rows[1].format.border_bottom = 'Thin';
        rows[1].format.border_bottom_color = 'Black';
      }

      const columns = _(this.dims)
        .concat(_(this.cols)
          .filter(({ show }) => show !== false)
          .sortBy(({ order }) => order)
          .value())
        .map((column) => ({
          name: column.name,
          width: (column.width || $(`th#${column.id}`).outerWidth()) / 7.5,
          format: exportFormat(column),
          section: column.section !== undefined
            ? utils.l10n(column.section)
            : undefined,
        }))
        .value();

      const config = { rows, columns };

      let filters = [];

      const collectFilters = (column, name) => {
        if (column.hideZeroRows) {
          filters.push(`abs(${name}) > ${this.zeroThreshold}`);
        }

        if (column.hideEmptyRows) {
          filters.push(`${name} != ''`);
        }

        if (!_.isEmpty(column.values)) {
          const include = new Set();
          const exclude = new Set();

          // eslint-disable-next-line no-restricted-syntax
          for (const value of Object.keys(column.values)) {
            if (column.values[value]) {
              include.add(value);
            } else {
              exclude.add(value);
            }
          }

          if (!_.isEmpty(include)) {
            filters.push(`${name} in ${utils.quote([...include])}`);
          }

          if (!_.isEmpty(exclude)) {
            filters.push(`!(${name} in ${utils.quote([...exclude])})`);
          }
        }

        const [a, b] = column.args || [];
        const condition = this.conditions[column.condition];

        if (condition?.func) {
          filters.push(eval(condition.func)(name, a, b));
        }
      };

      const cols = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const section of this.$refs.table.sections) {
        // eslint-disable-next-line no-restricted-syntax
        for (const column of section.visibleColumns) {
          cols.push(column);
        }
      }

      for (let i = 0; i < cols.length; ++i) {
        collectFilters(cols[i], `col${i + 1}`);
      }

      filters = {
        filter3: filters.join(' && '),
      };

      this.$refs.table.exportToFile(config, filters);
    },

    makeFilter(filters) {
      return _(filters)
        .filter(_.identity)
        .map((filter) => `(${filter})`)
        .join(' && ');
    },

    async sendBulkChanges(endpoints, prompt, config = {}) {
      if (!window.confirm(utils.l10n(prompt))) {
        return;
      }

      const { table } = this.$refs;

      const query = {
        filter0: this.makeFilter([table.extraFilter0, table.filter0, config.filter0]),
        filter1: this.makeFilter([table.extraFilter1, table.filter1, config.filter1]),
        filter2: this.makeFilter([table.extraFilter2, table.filter2, config.filter2]),
        dims: table.dims.map(({ calc }) => calc),
        keys: table.report.rows.map((row) => row.slice(0, table.dims.length)),
        vars: JSON.stringify(this.vars),
      };

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < endpoints.length; i++) {
        if (this.uniqueTable === 'lama-pages_promo-report') {
          query.dst_stream = 'new_prices_promo';

          if (endpoints[i] === '/prices/update') {
            query.fixed_date = +this.vars.strategyStartDate.replaceAll('`', '').split('-').join('');
            query.effective_date = +this.vars.strategyEndDate.replaceAll('`', '').split('-').join('');
          }

          if (endpoints[i] === '/prices/approve' || endpoints[i] === '/prices/reset') {
            query.src_stream = 'new_prices_promo';
          }
        }
        // eslint-disable-next-line no-await-in-loop
        await fetch(endpoints[i], {
          method: 'POST',
          body: JSON.stringify(query),
        });
      }

      table.refreshData();
    },

    async sendPromoType() {
      if (!this.promoType) {
        this.createNotification('Please select promo type in rules section', 'error');
        return;
      }

      const { hostname } = window.location;
      const token = hostname.startsWith('dev') ? '263a2bac72531162021e92729be2b35d8db9b314' : 'e73881779c8178d3ddeff3030dfc5a89026bdd1b';
      const url = `https://service.${hostname}/api/write-promo-type`;
      const options = {
        method: 'POST',
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'application/json; charset=UTF-8',
        },
      };
      const data = {
        user: this.username,
        promo_type: this.promoType,
      };
      options.body = JSON.stringify(data);

      await fetch(url, options);
    },

    async resetAllRecommendations() {
      await this.sendBulkChanges(['/recommendations/reset'], 'Are you sure you want to reset all recommendations?');
    },

    async resetMyPriceChanges() {
      let filter2 = [];

      if (app.isCategoryManager(app.username) && this.formulas['new_price_editable_cm']) {
        filter2.push(this.resolveSubstitutes(this.formulas['new_price_editable_cm']));
      }

      if (this.uniqueTable === 'lama-pages_promo-report') {
        filter2.push(`new_prices_promo.create_user == ${utils.quote(app.username)}`);
      } else {
        filter2.push(`new_prices.create_user == ${utils.quote(app.username)}`);
      }

      filter2 = filter2.join(' && ');

      await this.sendBulkChanges(['/prices/reset'], 'Are you sure you want to reset price changes made by you?', { filter2 });
    },

    async resetAllPriceChanges() {
      let filter2 = [];

      if (app.isCategoryManager(app.username) && this.formulas['new_price_editable_cm']) {
        filter2.push(this.resolveSubstitutes(this.formulas['new_price_editable_cm']));
      }

      filter2 = filter2.join(' && ');
      await this.sendBulkChanges(['/prices/reset'], 'Are you sure you want to reset all price changes?', { filter2 });
    },

    async approveMyPriceChanges() {
      let filter2 = [];

      if (app.isCategoryManager(app.username) && this.formulas['new_price_editable_cm']) {
        filter2.push(this.resolveSubstitutes(this.formulas['new_price_editable_cm']));
      }

      if (this.uniqueTable === 'lama-pages_promo-report') {
        filter2.push(`new_prices_promo.create_user == ${utils.quote(app.username)}`);
      } else {
        filter2.push(`new_prices.create_user == ${utils.quote(app.username)}`);
      }

      filter2 = filter2.join(' && ');

      await this.sendBulkChanges(['/prices/approve', '/prices/update'], 'Are you sure you want to approve price changes made by you?', { filter2 });

      if (this.uniqueTable === 'lama-pages_promo-report') {
        await this.sendPromoType();
      }
    },

    async approveAllPriceChanges() {
      let filter2 = [];

      if (app.isCategoryManager(app.username) && this.formulas['new_price_editable_cm']) {
        filter2.push(this.resolveSubstitutes(this.formulas['new_price_editable_cm']));
      }

      filter2 = filter2.join(' && ');

      await this.sendBulkChanges(['/prices/approve', '/prices/update'], 'Are you sure you want to approve all price changes?', { filter2 });

      if (this.uniqueTable === 'lama-pages_promo-report') {
        await this.sendPromoType();
      }
    },

    async acceptMyMarkdowns() {
      let filter2 = [];

      filter2.push(`optimization.create_user == ${utils.quote(app.username)}`);
      filter2 = filter2.join(' && ');

      await this.sendBulkChanges(['/markdowns/accept'], 'Are you sure you want to accept markdowns made by you?', { filter2 });
    },

    async acceptAllMarkdowns() {
      let filter2 = [];

      filter2 = filter2.join(' && ');

      await this.sendBulkChanges(['/markdowns/accept'], 'Are you sure you want to accept all markdowns?', { filter2 });
    },

    async approveMyMarkdowns() {
      let filter2 = [];

      filter2.push(`markdown_prices_last.create_user == ${utils.quote(app.username)}`);
      filter2.push(`markdown_prices_last.date > \`${this.referenceDate}\``);
      filter2 = filter2.join(' && ');

      await this.sendBulkChanges(['/markdowns/approve'], 'Are you sure you want to approve markdowns made by you?', { filter2 });
    },

    async approveAllMarkdowns() {
      let filter2 = [];

      filter2.push(`markdown_prices_last.date > \`${this.referenceDate}\``);
      filter2 = filter2.join(' && ');

      await this.sendBulkChanges(['/markdowns/approve'], 'Are you sure you want to approve all markdowns?', { filter2 });
    },

    async resetMyMarkdowns() {
      let filter2 = [];

      filter2.push(`markdown_prices_last.create_user == ${utils.quote(app.username)}`);
      filter2.push(`markdown_prices_last.date > \`${this.referenceDate}\``);
      filter2 = filter2.join(' && ');

      await this.sendBulkChanges(['/markdowns/reset'], 'Are you sure you want to reset markdowns made by you?', { filter2 });
    },

    async resetAllMarkdowns() {
      let filter2 = [];
      filter2.push(`markdown_prices_last.date > \`${this.referenceDate}\``);
      filter2 = filter2.join(' && ');
      await this.sendBulkChanges(['/markdowns/reset'], 'Are you sure you want to reset all markdowns?', { filter2 });
    },

    async acceptAllRecommendations() {
      let filter2 = [];

      if (app.isCategoryManager(app.username) && this.formulas['new_price_editable_cm']) {
        filter2.push(this.resolveSubstitutes(this.formulas['new_price_editable_cm']));
      }

      filter2 = filter2.join(' && ');

      if (this.uniqueTable === 'lama-pages_promo-report') {
        await this.sendBulkChanges(['/prices/accept', '/prices/update'], 'Are you sure you want to accept all price recommendations?', { filter2 });
      } else {
        await this.sendBulkChanges(['/prices/accept'], 'Are you sure you want to accept all price recommendations?', { filter2 });
      }
    },

    async acceptAllNonViolatedRecommendations() {
      let filter2 = [];

      if (app.isCategoryManager(app.username) && this.formulas['new_price_editable_cm']) {
        filter2.push(this.resolveSubstitutes(this.formulas['new_price_editable_cm']));
      }

      filter2.push("optimization.first_violated_final_price_strict_rule_id == ''");
      filter2 = filter2.join(' && ');

      if (this.uniqueTable === 'lama-pages_promo-report') {
        await this.sendBulkChanges(['/prices/accept', '/prices/update'], 'Are you sure you want to accept all price recommendations where strict rules are not violated?', { filter2 });
      } else {
        await this.sendBulkChanges(['/prices/accept'], 'Are you sure you want to accept all price recommendations where strict rules are not violated?', { filter2 });
      }
    },

    async removeAllValues(column) {
      const confirmText = utils
        .l10n('Are you sure you want to remove all values in column "{column}"?')
        .replace('{column}', column.name);

      if (window.confirm(confirmText)) {
        const { table } = this.$refs;
        const rows = table.report?.rows || [];

        // eslint-disable-next-line no-restricted-syntax
        for (let row of rows) {
          row = table.rowOverrides[row.key] || row;
          if (row[column.i] && column.editable(row, column)) {
            // eslint-disable-next-line no-await-in-loop
            await this.cellEdited({ row, column, value: '', meta: table.meta });
          }
        }
      }
    },

    async loadPermalink(permalink) {
      fetch(`/storage/permalinks/${permalink}`)
        .then((response) => response.json())
        .then((config) => {
          this.applyConfig(config);
          this.config = _.pick(config, [
            'kpis',
            'vars',
            'filter',
            'sections',
            'dimensions',
            'desiredSort',
            'desiredPage',
            'filters']);
        });
    },

    async copyPermalink() {
      await navigator.clipboard.writeText(this.permalink);
      this.permalinkCopied = true;
    },

    async createAndShowPermalink() {
      this.permalink = await this.createPermalink();
    },

    async createPermalink(overrides) {
      let userScopeCategory = null;
      try {
        const query = `
          {
            dataset {
              users {
                id
                access {
                  stream
                  filter1
                }
              }
            }
          }`;
        const res = await fetch('/graphql', {
          method: 'POST',
          body: JSON.stringify({ query }),
        });
        const data = await res.json();
        const user = data.data.dataset.users.find(({ id }) => id == this.username);
        const filter = user?.access?.find((entry) => entry.stream == this.stream)?.filter1;

        if (_.startsWith(filter, 'class in ')) {
          const classes = JSON.parse(filter.replace('class in ', ''));

          if (!_.isEmpty(classes)) {
            userScopeCategory = {
              name: this.username,
              scope: true,
              class: 'user-scope',
              classes,
            };
          }
        }
      } catch (ex) {
        console.warn('cannot create user scope fiter', ex);
      }

      const id = utils.randomId();
      const config = {
        kpis: this.kpis,
        vars: this.editingVars,
        filter: this.currentFilter,
        sections: this.currentSections,
        dimensions: this.currentDimensions,
        referenceDate: this.referenceDate,
        pastTimeframe: this.pastTimeframe,
        futureTimeframe: this.futureTimeframe,
        desiredSort: this.$refs?.table?.desiredSort || [],
        desiredPage: this.$refs?.table?.desiredPage || 0,
        filters: this.$refs?.table?.filters || [],
        optimizationId: this.optimizationId,
        priceZone: this.priceZone,
        globalSearch: {
          selectedCategories: gpsearch.selectedCategories,
          selectedClasses: gpsearch.selectedClasses,
          filter: gpsearch.filter,
          showInactiveAssortment: gpsearch.showInactiveAssortment,
          showClosedStores: gpsearch.showClosedStores,
          showLinkedItems: gpsearch.showLinkedItems,
        },
        createUser: this.username,
        createTime: Date.now(),
      };

      _.merge(config, overrides);

      if (userScopeCategory) {
        if (!_.isEqual(config.globalSearch.selectedCategories[0], userScopeCategory)) {
          config.globalSearch.selectedCategories = [userScopeCategory].concat(config.globalSearch.selectedCategories);
        }
      }

      await fetch(`/storage/permalinks/${id}`, {
        method: 'PUT',
        body: JSON.stringify(config),
        headers: { 'Content-Type': 'application/json' },
      });

      return this.$parent.base
        ? `${window.location.origin}${this.$parent.base}#permalink=${id}`
        : `${window.location.toString()}#permalink=${id}`;
    },

    removeAllFilters(target) {
      const cleanupColumn = (column) => {
        delete column.hideZeroRows;
        delete column.hideEmptyRows;
        delete column.condition;
        delete column.args;
        delete column.values;
      };

      const cleanupInplace = (dimensions, sections) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const dimension of dimensions) {
          if (!target || target.id === dimension.id) {
            cleanupColumn(dimension);
          }
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const section of sections) {
          // eslint-disable-next-line no-restricted-syntax
          for (const column of section.columns) {
            if (!target || target.id === column.id) {
              cleanupColumn(column);
            }
          }
        }
      };
      const currentFilter = _.cloneDeep(this.currentFilter);
      const editingFilter = _.cloneDeep(this.editingFilter);
      const currentSections = _.cloneDeep(this.currentSections);
      const editingSections = _.cloneDeep(this.editingSections);
      const currentDimensions = _.cloneDeep(this.currentDimensions);
      const editingDimensions = _.cloneDeep(this.editingDimensions);

      cleanupInplace(currentDimensions, currentSections);
      cleanupInplace(editingDimensions, editingSections);

      this.currentFilter = currentFilter;
      this.editintFilter = editingFilter;
      this.currentSections = currentSections;
      this.editingSections = editingSections;
      this.currentDimensions = currentDimensions;
      this.editingDimensions = editingDimensions;
    },

    makeVals(vals, section, column) {
      if (column.type == 'attribute') {
        const { calc } = column;
        const format = this.formats[column.format] || column.format;

        const referenceDate = utils.parseDate(this.referenceDate);
        const date = utils.nextDate(referenceDate);

        const resolve = (calc) => this.resolveDateConditions(
          this.resolveSubstitutes(calc),
          date,
          date,
          referenceDate,
        );

        if (!format) {
          vals[`min_${calc.replace('.', '_')}`] = `min(${resolve(calc)})`;
          vals[`max_${calc.replace('.', '_')}`] = `max(${resolve(calc)})`;
        } else {
          vals[calc.replace('.', '_')] = resolve(calc);
        }
      }

      if (column.type == 'metric') {
        const referenceDate = this.parseDate(this.referenceDate);
        let timeframe = column.timeframe || section.timeframe;

        if (timeframe === 'past') {
          timeframe = this.pastTimeframe;
        }

        if (timeframe === 'future') {
          timeframe = this.futureTimeframe;
        }

        if (!this.timeframes[timeframe]) {
          timeframe = 'reference_date';
        }

        const [startDate, endDate] = eval(this.timeframes[timeframe].calc)(referenceDate);

        const resolveSubstitutes = (calc, depth = 0) => {
          if (depth == 10) {
            return calc;
          }

          return calc.replaceAll(/[a-zA-Z_][a-zA-Z_0-9]*/g, (symbol) => {
            const formula = this.formulas[symbol];

            if (formula !== undefined && !this.isAggregationFormula(formula)) {
              return `(${resolveSubstitutes(formula, depth + 1)})`;
            }

            return symbol;
          });
        };

        const registerFormula = (symbol) => {
          let formula = this.formulas[symbol];

          if (formula !== undefined) {
            if (column.classObj) {
              if (formula.endsWith(')')) {
                formula = `${formula.slice(0, -1)} if ${column.classObj.column} == '${column.classObj.name}')`;
              } else {
                formula = `${formula} if ${column.classObj.column} == '${column.classObj.name}'`;
              }
            }

            if (this.isAggregationFormula(formula)) {
              let valKey = `${symbol}_${timeframe}`;

              if (column.classObj) {
                valKey += `_${column.classObj.name.replace(/[-+]/g, '')}`;
              }

              vals[valKey] = this.resolveDateConditions(
                resolveSubstitutes(formula),
                startDate,
                endDate,
                referenceDate,
              );
            } else {
              // eslint-disable-next-line no-restricted-syntax
              for (let [symbol] of formula.matchAll(/[a-zA-Z_][a-zA-Z_0-9]*/g)) {
                registerFormula(symbol);
              }
            }
          }
        };

        const symbols = column.formula.split(/[\s,]+/g);

        // eslint-disable-next-line no-restricted-syntax
        for (const symbol of symbols) {
          registerFormula(symbol);
        }
      }
    },

    makeDatesFilter(columns = []) {
      const dates = new Set();
      const referenceDate = this.parseDate(this.referenceDate);

      dates.add(this.formatDate(utils.nextDate(referenceDate)));

      // eslint-disable-next-line no-restricted-syntax
      for (const column of columns) {
        if (column.visible !== false && column.timeframe) {
          let { timeframe } = column;

          if (timeframe === 'past') {
            timeframe = this.pastTimeframe;
          }

          if (timeframe === 'future') {
            timeframe = this.futureTimeframe;
          }

          if (!this.timeframes[timeframe]) {
            timeframe = 'reference_date';
          }

          const [startDate, endDate] = eval(this.timeframes[timeframe].calc)(referenceDate);

          if (endDate >= startDate) {
            if (column.formula) {
              const formula = this.resolveSubstitutes(column.formula);

              if (_.includes(formula, 'date_before_start')) {
                dates.add(this.formatDate(utils.prevDate(startDate)));
              }

              if (_.includes(formula, 'start_date')) {
                dates.add(this.formatDate(startDate));
              }

              if (_.includes(formula, 'end_date')) {
                dates.add(this.formatDate(endDate));
              }

              if (_.includes(formula, 'date_after_end')) {
                dates.add(this.formatDate(utils.nextDate(endDate)));
              }

              if (_.includes(formula, 'in_date_range')) {
                let date = new Date(startDate);

                while (date.getTime() <= endDate.getTime()) {
                  dates.add(this.formatDate(date));
                  date = utils.nextDate(date);
                }
              }
            }
          }
        }
      }

      return `date in [${[...dates].map((date) => `\`${date}\``).sort().join(', ')}]`;
    },

    parseStyleRules(styleRules, styleFuncs, rowStyleFuncs) {
      // eslint-disable-next-line no-restricted-syntax
      for (const rule of styleRules) {
        try {
          const style = _.clone(rule.style == 'custom'
            ? rule
            : this.styles[rule.style]);
          const css = {};

          if (style.bold) {
            css['--font-weight'] = 'bold';
          }

          if (style.italic) {
            css['--font-style'] = 'italic';
          }

          if (style.underline) {
            if (!css['--text-decoration']) {
              css['--text-decoration'] = 'underline';
            } else {
              css['--text-decoration'] += ' underline';
            }
          }

          if (style.lineThrough) {
            if (!css['--text-decoration']) {
              css['--text-decoration'] = 'line-through';
            } else {
              css['--text-decoration'] += ' line-through';
            }
          }

          if (style.color) {
            css['--color'] = style.color;
          }

          if (style.backgroundColor) {
            let color = style.backgroundColor;

            if (style.saturation !== undefined) {
              const fill = this.darkTheme ? '#222' : '#fff';
              color = d3.interpolateRgb(fill, color)(style.saturation);
            }

            css['background-color'] = `${color}!important`;
          }

          let styleFunc = () => css;

          if (style.func) {
            const func = eval(style.func);
            const { args } = style;

            for (let i = 0; i < style.args.length; ++i) {
              if (rule.styleArgs && rule.styleArgs[i] !== undefined) {
                args[i] = rule.styleArgs[i];
              }
            }

            styleFunc = (val) => func(val, args[0], args[1]);
          }

          if (rule.condition != 'none') {
            const condition = this.conditions[rule.condition];
            const args = rule.conditionArgs || rule.args;
            const func = eval(condition.nativeFunc)(args[0], args[1]);
            const originalStyleFunc = styleFunc;
            styleFunc = (val) => (func(val)
              ? originalStyleFunc(val)
              : null);
          }

          if (style.applyToRow) {
            rowStyleFuncs.push(styleFunc);
          } else {
            styleFuncs.push(styleFunc);
          }
        } catch (ex) {
          console.warn(ex, rule);
        }
      }
    },

    makeStyles(column) {
      const styleFuncs = [];
      const rowStyleFuncs = [];

      const parseCodeFunc = (code, funcs) => {
        if (_.isPlainObject(code)) {
          funcs.push(() => code);
        }

        if (_.isString(code) && code) {
          try {
            const func = eval(code);

            if (_.isFunction(func)) {
              funcs.push(func);
            }
          } catch (ex) {
            console.warn(ex, code);
          }
        }

        if (_.isFunction(code)) {
          funcs.push(code);
        }
      };

      parseCodeFunc(column.style, styleFuncs);
      parseCodeFunc(column.rowStyle, rowStyleFuncs);

      if (!_.isEmpty(column.styleRules)) {
        this.parseStyleRules(column.styleRules, styleFuncs, rowStyleFuncs);
      }

      const makeSeqFunc = (funcs) => {
        if (funcs.length == 1) {
          return funcs[0];
        }

        if (funcs.length > 1) {
          return (val, row, col, meta) => {
            const style = {};

            // eslint-disable-next-line no-restricted-syntax
            for (const func of styleFuncs) {
              _.assign(style, func(val, row, col, meta));
            }
            return style;
          };
        }
      };

      return [
        makeSeqFunc(styleFuncs),
        makeSeqFunc(rowStyleFuncs),
      ];
    },

    resolveVars(name) {
      if (_.isString(name)) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key of _.keys(this.editingVars)) {
          name = name.replace(`{${key}}`, `${this.editingVars[key]}`);
        }
      }

      return name;
    },

    makeCols(cols, section, column) {
      const order = cols.length + 1;
      const [style, rowStyle] = this.makeStyles(column);

      if (column.type == 'attribute') {
        let name = column.alias || column.name;
        let calc = column.calc.replace('.', '_');
        const format = this.formats[column.format] || column.format;

        if (!format) {
          calc = `prefix(min_${calc}, max_${calc})`;
        }
        name = this.resolveVars(name);
        cols.push(
          _.assign({
            order,
            name,
            calc,
            style,
            rowStyle,
            format,
            section: this.sectionName(section),
            className: `my-calc-${_.kebabCase(column.calc)}`,
          }, _.omit(column, ['name', 'type', 'calc', 'format', 'style', 'rowStyle'])),
        );
      }

      if (column.type == 'metric') {
        let calc;
        const symbols = column.formula.split(/[\s,]+/g);

        // eslint-disable-next-line no-restricted-syntax
        for (const symbol of symbols) {
          const formula = this.formulas[symbol];
          let timeframe = column.timeframe || section.timeframe;

          if (timeframe === 'past') {
            timeframe = this.pastTimeframe;
          }

          if (timeframe === 'future') {
            timeframe = this.futureTimeframe;
          }

          if (!this.timeframes[timeframe]) {
            timeframe = 'reference_date';
          }

          if (formula !== undefined) {
            if (this.isAggregationFormula(formula)) {
              calc = `${symbol}_${timeframe}`;

              if (column.classObj) {
                calc += `_${column.classObj.name.replace(/[-+]/g, '')}`;
              }
            } else {
              const resolveSubstitutes = (calc, depth = 0) => {
                if (depth == 10) {
                  return calc;
                }

                return calc.replaceAll(/[a-zA-Z_][a-zA-Z_0-9]*/g, (symbol) => {
                  const formula = this.formulas[symbol];

                  if (formula !== undefined) {
                    if (this.isAggregationFormula(formula)) {
                      if (column.classObj) {
                        return `${symbol}_${timeframe}_${column.classObj.name.replace(/[-+]/g, '')}`;
                      }

                      return `${symbol}_${timeframe}`;
                    }

                    return `(${resolveSubstitutes(formula, depth + 1)})`;
                  }

                  return symbol;
                });
              };

              calc = formula.replaceAll(/[a-zA-Z_][a-zA-Z_0-9]*/g, (symbol) => resolveSubstitutes(symbol));
            }
          }

          let override;

          if (column.editable) {
            if (this.checkUnEditableColumn(column)) {
              column.editable = '() => {}';
            }
            override = this.override;
          }

          let { name } = column;

          if (this.hyphenate) {
            name = hyphenate(name, this.hyphenate);
          }

          if (column.metric
            && column.metric.timeframe !== timeframe
            && !(column.metric.timeframe === 'past' && timeframe === this.pastTimeframe)
            && !(column.metric.timeframe === 'future' && timeframe === this.futureTimeframe)
          ) {
            name = `${name} (${this.getTimeframeName(timeframe)})`;
          }

          if (column.alias) {
            name = column.alias;
          }

          name = this.resolveVars(name);

          if (calc !== undefined) {
            if (symbol === symbols[0]) {
              cols.push(
                _.assign({
                  order,
                  name,
                  calc,
                  style,
                  rowStyle,
                  format: this.formats[column.format] || column.format,
                  section: this.sectionName(section),
                  override,
                  className: `my-calc-${_.kebabCase(symbol)}`,
                },
                _.omit(column, ['name', 'type', 'format', 'style', 'rowStyle'])));
            } else {
              cols.push({
                order,
                name: calc,
                calc,
                section: this.sectionName(section),
                show: false,
                id: `${column.id}_${symbols.indexOf(symbol)}`,
              });
            }
          }
        }
      }

      if (column.type == 'calc_column') {
        const symbols = column.calc.split(/;+/g).map((x) => x.trim());

        symbols.forEach((symbol, index) => {
          let { name } = column;
          const calc = `${symbol} as ${_.snakeCase(index === 0 ? column.id : symbol)}`;

          if (this.hyphenate) {
            name = hyphenate(name, this.hyphenate);
          }

          if (column.alias) {
            name = column.alias;
          }

          name = this.resolveVars(name);

          if (symbol === symbols[0]) {
            cols.push(
              _.assign({
                order,
                name,
                calc,
                style,
                rowStyle,
                format: this.formats[column.format] || column.format,
                section: this.sectionName(section),
                className: `my-calc-${_.kebabCase(symbol)}`,
              },
              _.omit(column, ['name', 'type', 'calc', 'format', 'style', 'rowStyle'])));
          } else {
            cols.push({
              order,
              name: calc,
              calc,
              section: this.sectionName(section),
              show: false,
              id: `${column.id}_${index}`,
            });
          }
        });
      }
    },

    nextDate(date) {
      return utils.formatDate(utils.nextDate(utils.parseDate(date)));
    },

    prevDate(date) {
      return utils.formatDate(utils.prevDate(utils.parseDate(date)));
    },

    boradcastReferenceDate() {
      if (this.product == 'price') {
        utils.bridge.trigger('referenceDateChanged', this.referenceDate);
        const date = utils.nextDate(this.parseDate(this.referenceDate));
        utils.bridge.trigger('filtersChanged', this._uid, {
          stream: this.stream,
          groups: ['reference-date'],
          filter0: `date == \`${this.formatDate(date)}\``,
          filter1: `date == \`${this.formatDate(date)}\``,
          filter2: '',
        });
      }
    },

    formatDate(date) {
      return utils.formatDate(date);
    },

    actualize() {
      this.actualizeSections();
      this.actualizeDimensions();
    },

    actualizeDimensions(dimensions) {
      if (dimensions === undefined) {
        this.actualizeDimensions(this.currentDimensions);
        this.actualizeDimensions(this.editingDimensions);
        return;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const column of dimensions) {
        if (column.attribute) {
          const attribute = this.attributesByNameCalc[column.attribute.name + column.attribute.calc]
          || this.attributesByCalc[column.attribute.calc];

          if (attribute) {
            _.assign(column, attribute);
            column.attribute = attribute;
          }
        }
      }
    },

    actualizeSections(sections) {
      if (sections === undefined) {
        this.actualizeSections(this.currentSections);
        this.actualizeSections(this.editingSections);
        return;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const section of sections) {
        // eslint-disable-next-line no-restricted-syntax
        for (const column of section.columns) {
          if (column.attribute) {
            const attribute = this.attributesByNameCalc[column.attribute.name + column.attribute.calc]
            || this.attributesByCalc[column.attribute.calc];

            if (attribute) {
              if (attribute.id) {
                delete attribute.id;
              }
              _.assign(column, attribute);
              column.attribute = attribute;
              column.name = attribute.name;
            }
          }

          if (column.metric) {
            const { timeframe } = column;
            const metric = this.metricsByFormula[column.metric.formula.split(/[\s,]+/g)[0]] || this.metricsByName[column.metric.name];

            if (metric) {
              if (metric.id) {
                delete metric.id;
              }

              _.assign(column, metric);
              column.metric = metric;
              column.name = metric.name;
            }

            if (this.timeframes[timeframe]) {
              column.timeframe = timeframe;
            }
          }
        }
      }
    },

    configSaved(config) {
      this.applyConfig(config);
      this.config = config;
    },

    configChanged(config, forced) {
      if (config && !_.isEqual(config, this.config)) {
        if (!this.config || this.config.id !== config.id || forced) {
          this.applyConfig(config);
        }
        this.config = config;
      }

      _.defer(this.setupSortable);

      if (this.useTopSettingsSaving) {
        utils.bridge.trigger('configChanged', _.cloneDeep(config));
      }
    },

    applyConfig(config) {
      config = _.cloneDeep(config);
      this.editingFilter = _.cloneDeep(config.filter) || [];
      this.currentFilter = _.cloneDeep(config.filter) || [];
      this.editingSections = _.cloneDeep(config.sections) || [];
      this.currentSections = _.cloneDeep(config.sections) || [];
      this.editingDimensions = _.cloneDeep(config.dimensions) || [];
      this.currentDimensions = _.cloneDeep(config.dimensions) || [];
      this.subTotals = config.subTotals || false;
      this.kpis = _.cloneDeep(config.kpis) || [];
      this.editingVars = _.cloneDeep(config.vars) || { target_poi_length: 7, target_cost_change_days: 1 };
      this.actualize();
      
      if (this.$refs.table) {
        this.$refs.table.desiredPage = config.desiredPage || 0;
        this.$refs.table.desiredSort = config.desiredSort || [];
        this.$refs.table.filters = config.filters || [];
      }

      if (config.referenceDate !== undefined) {
        this.referenceDate = config.referenceDate;
      }

      if (config.pastTimeframe !== undefined) {
        this.pastTimeframe = config.pastTimeframe;
      }

      if (config.futureTimeframe !== undefined) {
        this.futureTimeframe = config.futureTimeframe;
      }

      if (config.optimizationId !== undefined) {
        this.optimizationId = config.optimizationId;
      }

      if (config.priceZone !== undefined) {
        this.priceZone = config.priceZone;
      }

      if (config.globalSearch !== undefined) {
        _.assign(gpsearch, config.globalSearch);
      }
    },

    async loadSavedReports() {
      let reports = await fetch('/storage/reports', { method: 'GET', headers: utils.nocahe() })
        .then((res) => res.json());
      reports = _(reports)
        .toPairs()
        .map(([id, report]) => _.assign(report, { id }))
        .sortBy(({ id }) => id)
        .value();

      if (!_.isEqual(reports, this.savedReports)) {
        this.savedReports = reports;
        _.defer(this.setupSortable);
      }
    },

    setupSortable() {
      $('.gp-settings-sections')
        .sortable({
          handle: '.gp-table-section',
          items: 'table',
          axis: 'y',
          cursor: 'move',
          helper: 'clone',
          distance: 4,
          start: (e, ui) => {
            ui.item.data('n', -1);
          },
          change: (e, ui) => {
            const x = ui.placeholder[0];
            const n = Array.prototype.indexOf.call(x.parentNode.childNodes, x);

            ui.item.data('n', n);
          },
          beforeStop: (e, ui) => {
            this.preventEditing = true;

            _.defer(() => {
              this.preventEditing = false;
            });

            const i = ui.item.data('i');
            const n = ui.item.data('n');

            if (n != -1) {
              const section = this.editingSections.splice(i, 1)[0];
              this.editingSections.splice(n > i ? n - 1 : n, 0, section);
              this.editingSections = _.clone(this.editingSections);
            }
          },
          stop: (event, ui) => {
            ui.item.closest('.gp-settings-sections').sortable('cancel');
          },
        });

      $('.gp-table-settings')
        .sortable({
          items: '.gp-table-column',
          axis: 'y',
          cursor: 'move',
          cancel: '.gp-select',
          distance: 4,
          helper(e, tr) {
            tr.children().each(function() {
              $(this).width($(this).width());
            });

            return tr.clone();
          },
          start: (e, ui) => {
            ui.item.data('n', -1);
          },
          change: (e, ui) => {
            const x = ui.placeholder[0];
            const n = Array.prototype.indexOf.call(x.parentNode.childNodes, x);

            ui.item.data('n', n);
          },
          beforeStop: (e, ui) => {
            this.preventEditing = true;

            _.defer(() => {
              this.preventEditing = false;
            });

            const i = ui.item.closest('table').data('i');
            const j = ui.item.data('j');
            const n = ui.item.data('n');

            if (n != -1) {
              const section = this.editingSections.splice(i, 1)[0];
              const column = section.columns.splice(j, 1)[0];
              section.columns.splice(n > j ? n - 1 : n, 0, column);
              this.editingSections.splice(i, 0, section);
              this.editingSections = _.clone(this.editingSections);
            }
          },
          stop: (event, ui) => {
            ui.item.closest('.gp-table-settings').sortable('cancel');
          },
        });
    },

    toogleYamlConfig() {
      this.showConfig = !this.showConfig;
      if (this.showConfig) {
        const filter = _.cloneDeep(this.currentFilter);
        const sections = _.cloneDeep(this.currentSections);
        const dimensions = _.cloneDeep(this.currentDimensions);
        const kpis = _.cloneDeep(this.kpis);
        const vars = _.cloneDeep(this.editingVars);
        const { subTotals } = this;

        this.configYaml = jsyaml.safeDump({
          filter,
          sections,
          dimensions,
          kpis,
          vars,
          subTotals,
        });
      }
    },

    applyYamlConfig() {
      try {
        const {
          dimensions,
          sections,
          filter,
          kpis,
          vars,
        } = jsyaml.safeLoad(this.configYaml);

        // eslint-disable-next-line no-restricted-syntax
        for (const dimension of dimensions) {
          if (dimension.id === undefined) {
            dimension.id = utils.randomId();
          }
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const section of sections) {
          if (section.id === undefined) {
            section.id = utils.randomId();
          }

          // eslint-disable-next-line no-restricted-syntax
          for (const column of section.columns) {
            if (column.id === undefined) {
              column.id = utils.randomId();
            }
          }
        }

        this.editingFilter = filter;
        this.editingSections = sections;
        this.editingDimensions = dimensions;
        this.kpis = kpis;
        this.editingVars = vars;
      } catch (ex) {
        alert(ex);
      }
    },

    isSectionVisible(section) {
      let visible = true;

      // eslint-disable-next-line no-restricted-syntax
      for (const column of section.columns) {
        if (!this.isColumnVisible(column)) {
          visible = false;
        }
      }

      return visible;
    },

    isSectionIndeterminate(section) {
      let visible = false;
      let invisible = false;

      // eslint-disable-next-line no-restricted-syntax
      for (const column of section.columns) {
        if (this.isColumnVisible(column)) {
          visible = true;
        } else {
          invisible = true;
        }
      }

      return visible && invisible;
    },

    toogleSectionVisible(section) {
      const visible = !this.isSectionVisible(section);

      // eslint-disable-next-line no-restricted-syntax
      for (const column of section.columns) {
        if (column.id) {
          if (visible) {
            this.showColumn(column);
          } else {
            this.hideColumn(column);
          }
        }
      }
    },

    findColumnIndex(column) {
      if (!this.report) {
        return -1;
      }

      return this.report.meta.columns.findIndex(({ id }) => column.id === id);
    },

    isColumnVisible(column) {
      return column.visible !== false;
    },

    showColumn(column) {
      this.updateColumn(
        column.id,
        column => column.visible = true,
        ['editing']);
    },

    hideColumn(column) {
      this.updateColumn(
        column.id,
        column => column.visible = false,
        ['editing']);
    },

    toogleColumnVisible(column) {
      this.updateColumn(
        column.id,
        column => column.visible = column.visible === false,
        ['editing']);
    },

    override({ value }) {
      return value;
    },

    reportUpdated(report) {
      this.report = report;
    },

    async handleCellAction(e, info) {
      this.$refs.table.$forceUpdate();
    },

    acceptRecommendation(row, column, meta, streamTo = 'new_prices') {
      let streamToVar = streamTo;

      if (this.uniqueTable === 'lama-pages_promo-report') {
        streamToVar = 'new_prices_promo';
      }

      const value = row[column.i];
      const { table } = this.$refs;
      column = table.columns.find(({ editable, actionable, stream }) => editable && actionable && stream === streamToVar);

      this.cellEdited({
        row,
        column,
        value,
        meta: table.meta,
      });
    },

    keepOnly({ row, column }) {
      const value = row[column.i];
      this.$refs.table.filters.push({
        type: 'keepOnly',
        name: `${column.name}: ${column.format(value)}`,
        code: `(${this.formulas[column.calc] || column.calc}) == ${utils.quote(value, column)}`,
      });
    },

    exclude({ row, column }) {
      const value = row[column.i];
      this.$refs.table.filters.push({
        type: 'exclude',
        name: `${column.name}: ${column.format(value)}`,
        code: `(${this.formulas[column.calc] || column.calc}) != ${utils.quote(value, column)}`,
      });
    },

    cellClicked(e, { row, column }) {
      if (column.attribute) {
        const data = {
          key: [],
          row,
          column: {
            i: column.i,
            calc: column.attribute.calc,
            name: column.name,
            format: column.format,
          },
          value: [row[column.i]],
        };

        this.$refs.tooltip.click(data, e);
      }
    },

    linkClicked(e, { column: col, row, meta }) {
      if (e.altKey && col.metric && col.metric.formula.indexOf('_competitor_') != -1) {
        const fields = col.metric.formula.split(/\s*,\s*/g);
        const shift = _.findIndex(fields, (field) => field.endsWith('_link'));

        if (shift != -1) {
          const prompt = 'Please enter a new link for item {item}, competitor {competitor} and price zone {zone}.';
          const value = row[col.i + shift];

          this.overrideCompetitor({
            col,
            row,
            meta,
            fields,
            shift,
            value,
            prompt,
          });
        }
      } else if (e.target.href) {
        window.open(e.target.href, '_blank', 'toolbar=0,location=0,menubar=0');
      }
    },

    async overrideCompetitor({
      row,
      col,
      meta,
      fields,
      shift,
      value,
      prompt,
    }) {
      const itemCol = _.findIndex(meta.dims, ({ calc }) => calc === 'item');
      const extraCol = _.findIndex(meta.dims, ({ calc }) => calc === this.overrideCompetitorColumn);

      if (itemCol === -1 || extraCol === -1) {
        const text = utils.l10n('Please add grouping by item and price zone to edit competitors data.');
        window.alert(text);

        return;
      }

      const item = row[itemCol];
      const extra = row[extraCol];
      const nameShift = _.findIndex(fields, (field) => field.endsWith('_name'));

      if (nameShift === -1) {
        const text = utils.l10n('Cannot find column with competitor name.');
        window.alert(text);

        return;
      }

      const name = row[col.i + nameShift];

      if (!name) {
        const text = utils
          .l10n('Competitor is not set in competitor panel for item {item}, price zone {zone}.')
          .replace('{item}', item)
          .replace('{zone}', extra);
        alert(text);

        return;
      }

      const date = parseFloat(utils.formatDate(utils.nextDate(utils.parseDate(this.referenceDate))).replace(/-/g, ''));
      let replace = false;
      let ignore = false;
      let deleted = false;
      let defval;

      let scrapShift = -1;
      const ignoreShift = _.findIndex(fields, (field) => field.endsWith('_ignore'));

      if (fields[shift].endsWith('_price')) {
        defval = 0;
        scrapShift = _.findIndex(fields, (field) => field.endsWith('_scrap_price'));
      }

      if (fields[shift].endsWith('_action')) {
        defval = 0;
        scrapShift = _.findIndex(fields, (field) => field.endsWith('_scrap_action'));
      }

      if (fields[shift].endsWith('_link')) {
        defval = '';
        scrapShift = _.findIndex(fields, (field) => field.endsWith('_scrap_link'));
      }

      if (prompt) {
        const text = utils
          .l10n(prompt)
          .replace('{item}', item)
          .replace('{zone}', extra)
          .replace('{competitor}', name);
        value = window.prompt(text, value);
      }

      if (value == null) {
        return;
      }

      switch (value) {
        case '-':
          value = defval;
          ignore = true;
          break;

        case '':
          value = defval;
          deleted = true;
          break;

        default:
          if (_.isNumber(defval) && _.isString(value)) {
            value = parseFloat(value.replace(/,/g, '.'));
            if (Number.isNaN(value))
              return;
          }
          replace = true;
      }

      if (_.isNumber(defval) && scrapShift != -1 && row[col.i + scrapShift] && Math.abs(value - row[col.i + scrapShift]) < 0.001) {
        deleted = true;
      }

      row = this.modifyRow(row, (row) => {
        Vue.set(row, col.i + shift, deleted ? defval : value);

        if (ignoreShift != -1) {
          Vue.set(row, col.i + ignoreShift, ignore);
        }
      });

      const req = {
        item,
        'zone': extra,
        name,
        date,
        replace,
        ignore,
        deleted,
      };

      req[_.last(fields[shift].split(/_/g))] = value;

      await fetch('/competitors/update', {
        method: 'POST',
        body: JSON.stringify(req),
        headers: { 'Content-Type': 'application/json' },
      });
      await utils.bridge.trigger('streamModified', 'competitors_override', req);
      await this.updateRow(row, ['competitors_override']);
    },

    formatFailedEdit({
      args: {
        row,
        column,
        value,
        meta,
        shift = 0,
      },
    }) {
      return [
        meta.columns
          .filter((column) => column.section == 'Dimensions')
          .map((column) => column.format(row[column.i]))
          .join(', '),
        ' | ',
        `${utils.l10n(column.name)}: `,
        `${column.format(row[column.i + shift])} -> ${value}`,
      ].join('');
    },

    async retryFailedEdits() {
      // eslint-disable-next-line no-restricted-syntax
      for (const failedEdit of _.clone(this.failedEdits)) {
        try {
          this.$set(failedEdit, 'status', 'retrying');

          // eslint-disable-next-line no-await-in-loop
          await this.cellEdited(failedEdit.args);
          this.failedEdits.splice(this.failedEdits.indexOf(failedEdit), 1);
        } catch (error) {
          this.$set(failedEdit, 'status', 'failed');
          this.$set(failedEdit, 'error', error);
        }
      }
    },

    async discardFailedEdits() {
      // eslint-disable-next-line no-restricted-syntax
      for (let {args: {row, column, shift=0, oldValue}} of _.reverse(this.failedEdits)) {
        try {
          this.modifyRow(row, (row) => Vue.set(row, column.i + shift, oldValue));
        } catch (ex) {
          console.warn('failed to restore old value while discarding failed edits', ex);
        }
      }
      this.failedEdits = [];
    },

    async cellEdited(args) {
      const { row, column, shift = 0 } = args;

      if (args.oldValue === undefined) {
        args.oldValue = row[column.i + shift];
      }

      try {
        await this.handleCellEdit(args);
      } catch (error) {
        this.failedEdits.push({ args, error, status: 'failed' });
      }
    },

    async handleCellEdit({
      rows,
      row,
      column,
      value,
      meta,
      shift = 0,
    }) {
      const getRowIndex = (key) => {
        return this.dims.findIndex((dim) => dim.calc === key);
      }

      const paramRow = row;

      if (column.stream === 'competitors_override') {
        const fields = column.metric.formula.split(/\s*,\s*/g);
        await this.overrideCompetitor({
          row,
          col: column,
          meta,
          fields,
          value,
          shift,
        });

        return;
      }

      if (column.formula === 'attributes_link_name') {
        const indexTG = getRowIndex('category_plus');

        for (let i = 0; i < rows.length; i++) {
          if (rows[i].key !== row.key && rows[i][indexTG] === row[indexTG]) {
            const item = rows[i].find((item) => item === value);

            if (item) {
              this.createNotification('There cannot be identical names in one tg+', 'error');
              return;
            }
          }
        }
      }

      if (this.cellEditingValidations) {
        const failedValidations = [];

        this.cellEditingValidations.forEach((validation) => {
          if (validation.formula === column.formula) {
            let condition;

            try {
              condition = eval(validation.condition);
            } catch (e) {
              this.createNotification('Error occured while parsing validation condition', 'error');
            }

            if (condition) {
              this.createNotification(validation.text, 'error');
              failedValidations.push(validation);
            }
          }
        });

        if (failedValidations.length) {
          return;
        }
      }

      if (column.formula === 'strg_plan_rrp_discount\nstr_pln_price_type') {
        const url = '/api/strategic-plan/const-price-types-discount';
        const data = {
          user: this.username,
        };
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        };

        const response = await fetch(url, options);

        if (!response.ok) {
          const error = await response.json();
          const errorDetails = error?.detail || [];

          if (errorDetails.length) {
            this.createNotification(errorDetails[0], 'error');
            return;
          }
        }
      }

      const makeFilter = (filters) => _(filters)
        .filter()
        .map((filter) => `(${filter})`)
        .join(' && ');

      let rowFilters1 = [];
      const rowFilters2 = [];
      let { table } = this.$refs;

      if (_.isString(value)) {
        value = _.trim(value);

        switch (column.type) {
          case 'docid':
          case 'int8':
          case 'int16':
          case 'int32':
          case 'int64':
            if (value === '') {
              value = 0;
            } else {
              value = parseInt(value, 10);
            }

            if (_.isNaN(value)) {
              return;
            }
            break;
          case 'float':
          case 'double':
            if (value === '') {
              value = 0;
            } else {
              value = parseFloat(value.replace(',', '.'));
            }

            if (_.isNaN(value)) {
              return;
            }
            break;
          case 'bool':
            value = value.match(/^[yY1дД]/) !== null || value == utils.l10n('yes');
            break;
          case 'date':
            value = value || null;
            break;
          default: break;
        }
      }

      if (row) {
        // if (this.type === 'tree' && row.level === table.dims.length - 1) {
        //   // TODO: make toast notification for this case
        //   return;
        // }

        _.forEach(table.dims, (dim, i) => {
          const calc = _.isString(dim) ? dim : dim.calc;
          if (this.type === 'tree' && (column.metric.formula.startsWith('sales_rub_to_ly_formula')
             || column.metric.formula.startsWith('sales_rub_to_lly_formula')
             || column.metric.formula.startsWith('asp_bool'))
          ) {
            if (calc === 'item') {
              if (!row.gpTargetGroup?.includes('***')) rowFilters2.push(`${calc} == ${utils.quote(row.gpTargetGroup)}`);
            } else if (calc === 'class') {
              rowFilters1.push(`${calc} == ${utils.quote(row[i])}`);
            } else if (calc === 'month') {
              const monthFromKey = row.key.split('|')[3];

              if (monthFromKey) {
                rowFilters2.push(`${calc} == ${monthFromKey}`);
              } else if (row.level > 2) {
                rowFilters2.push(`${calc} == ${utils.quote(row[i])}`);
              }
            } else if (i <= row.level) {
              rowFilters2.push(`${calc} == ${utils.quote(row[i])}`);
            }
          } else if (this.type === 'tree' && row.level >= i) {
            if (i === 0) {
              rowFilters1.push(`${calc} == ${utils.quote(row.firstElement)}`);
              return;
            }

            if (calc === 'item') {
              rowFilters2.push(`${calc} == ${utils.quote(row.gpTargetGroup)}`);
              return;
            }

            if (calc === 'class') {
              rowFilters1.push(`${calc} == ${utils.quote(row[i])}`);
            } else {
              rowFilters2.push(`${calc} == ${utils.quote(row[i])}`);
            }
          } else {
            if (calc === 'item' || calc === 'class') {
              if (!String(row[i]).includes('***')) {
                rowFilters1.push(`${calc} == ${utils.quote(row[i])}`);
              }
            } else if (!String(row[i]).includes('***')) {
              rowFilters2.push(`${calc} == ${utils.quote(row[i])}`);
            }
          }
        });

        row = this.modifyRow(row, (row) => Vue.set(row, column.i + shift, value));
      } else {
        // eslint-disable-next-line no-restricted-syntax
        for (let row of table.rows) {
          this.modifyRow(row, (row) => Vue.set(row, column.i + shift, value));
        }

        if (table.report.totals) {
          table.report.totals.rows[0][column.i - table.dims.length + shift] = value;
        }
      }

      table.reportId = null;
      this.editableSeqN += 1;

      let filter0 = this.makeDatesFilter();

      if (this.product == 'pim' || this.removeReferenceDate) {
        filter0 = undefined;
      }

      if (this.readReport) {
        if (column.section) {
          rowFilters2.push(`class == "${column.section}"`);
        }
      }

      const filter1 = makeFilter([table.extraFilter1, table.filter1].concat(rowFilters1));
      const filter2 = makeFilter([table.extraFilter2, table.filter2].concat(rowFilters2));
      const reportKey = JSON.stringify({ filter0, filter1, filter2 });

      delete this.updateReports[reportKey];

      if (column.stream === 'new_prices' || column.stream === 'new_prices_promo') {
        if (column.stream === 'new_prices_promo' && !this.promoType) {
          this.createNotification('Please select promo type in rules section', 'error');
          return;
        }

        const query = {
          filter0,
          filter1,
          filter2,
          dims: table.dims.map(({ calc }) => calc),
          keys: [row.slice(0, table.dims.length)],
          vars: JSON.stringify(this.vars),
        };

        if (this.uniqueTable === 'lama-pages_promo-report') {
          query.dst_stream = 'new_prices_promo';
          query.fixed_date = +this.vars.strategyStartDate.replaceAll('`', '').split('-').join('');
          query.effective_date = +this.vars.strategyEndDate.replaceAll('`', '').split('-').join('');
        }

        if (_.isNumber(value)) {
          query.price = value;
        }

        if (_.isBoolean(value)) {
          query.approved = value;
        }

        switch (column.metric?.formula?.replace(/,.*/, '')) {
          case 'new_price_effective_date':
            if (value) {
              query.effective_date = parseInt(value.replaceAll('-', ''), 10);
            } else {
              query.effective_date = 0;
            }
            break;

          case 'new_price_fixed_date':
            if (value) {
              query.fixed_date = parseInt(value.replaceAll('-', ''), 10);
            } else {
              query.fixed_date = 0;
            }
            break;

          case 'avg_new_equiv_price':
            query.price *= row[column.i + 1];
            break;

          default: break;
        }

        await fetch('/prices/update', {
          method: 'POST',
          body: JSON.stringify(query),
        });

        if (column.stream === 'new_prices_promo') {
          await this.sendPromoType();
        }
      } else if (column.stream === 'new_prices_delayed'
        || column.stream === 'new_prices_delayed_1'
        || column.stream === 'new_prices_delayed_2'
        || column.stream === 'new_prices_delayed_3'
      ) {
        const query = {
          filter0,
          filter1,
          filter2,
          stream: column.stream,
          dims: table.dims.map(({calc}) => calc),
          keys: [row.slice(0, table.dims.length)],
          vars: JSON.stringify(this.vars),
        };

        if (column.metric.formula.startsWith('min_delayed_price_date')
          || column.metric.formula.startsWith('min_delayed_price_1_date')
          || column.metric.formula.startsWith('min_delayed_price_2_date')
          || column.metric.formula.startsWith('min_delayed_price_3_date')
          || column.metric.formula.startsWith('min_new_prices_delayed_1_date')
          || column.metric.formula.startsWith('min_new_prices_delayed_2_date')
          || column.metric.formula.startsWith('min_new_prices_delayed_3_date')
        ) {
          if (value) {
            query.date = parseInt(value.replaceAll('-', ''), 10);
          } else {
            query.date = 0;
          }
        }

        if (column.metric.formula.startsWith('avg_delayed_price_price')
          || column.metric.formula.startsWith('avg_delayed_price_1_price')
          || column.metric.formula.startsWith('avg_delayed_price_2_price')
          || column.metric.formula.startsWith('avg_delayed_price_3_price')
          || column.metric.formula.startsWith('avg_new_prices_delayed_1_price')
          || column.metric.formula.startsWith('avg_new_prices_delayed_2_price')
          || column.metric.formula.startsWith('avg_new_prices_delayed_3_price')
        ) {
          query.price = value;
        }

        await fetch('/prices_delayed/update', {
          method: 'POST',
          body: JSON.stringify(query),
        });
      } else {
        const { keys } = column;
        const extra = _.map(column.extra, (field) => (this[field] ? this[field] : field));

        if (column.stream == 'costs_override') {
          extra.push(utils.formatDate(utils.nextDate(utils.parseDate(this.referenceDate))));
        }

        clearTimeout(this.requestDataTimeout);

        let dateFilter = `date == \`${utils.formatDate(utils.nextDate(utils.parseDate(this.referenceDate)))}\``;

        if (this.product == 'pim' || this.removeReferenceDate) {
          dateFilter = undefined;
        }

        const rows = await utils.query({
          name: 'gp-edit-keys',
          vars: this.vars,
          stream: table.stream,
          report: table.report,
          cores: table.cores,
          dims: keys,
          filter0: makeFilter([dateFilter, filter0]),
          filter1: makeFilter([dateFilter, filter1]),
          filter2,
        });

        const createUser = this.username;
        let createTime = Date.now();

        if (this.product == 'pim') {
          createTime = new Date().toISOString().replace('T', ' ').split('.')[0];
        }

        let records = [];

        for (let i = 0; i < rows.length; ++i) {
          const row = rows[i];
          let record = [];

          if (this.product == 'pim') {
            record.push('manual');
            record.push(0);
          }

          for (let j=0; j<keys.length; ++j) {
            record.push(row[j]);
          }

          if (this.product == 'pim') {
            record.push(value);
            record.push(createTime);
            record.push(createUser);
            record.push(createTime);
            record.push(createUser);
          } else {
            record.push(createUser);
            record.push(createTime);

            if (extra) {
              record = record.concat(extra);
            }

            const editResults = column.editValue(value, paramRow, column, meta, shift);

            if (editResults.length === 0) {
              record.push(value);
              records.push(record);
            } else if (editResults[0] instanceof Array) {
              // eslint-disable-next-line no-restricted-syntax
              for (let i in editResults) {
                records.push(record.concat(editResults[i]));
              }
            } else {
              records.push(record.concat(editResults));
            }
          }
        }

        records = JSON.stringify(records);

        const { stream } = column;
        const query = `
          mutation {
            appendRecords(
              stream: ${utils.quote(stream)},
              format: "json",
              records: ${utils.quote(records)})
          }`;
        await fetch('/graphql', {
          method: 'POST',
          body: JSON.stringify({ query }),
          headers: { 'Content-Type': 'application/json' },
        });

        if (this.type === 'tree' && (
          column.metric.formula.startsWith('sales_rub_to_ly_formula')
          || column.metric.formula.startsWith('sales_rub_to_lly_formula')
          || column.metric.formula.startsWith('asp_bool')
        )) {
          await fetch('api/cumulata');
        }
      }

      utils.bridge.trigger('streamModified', column.stream);

      const getRowData = (field, tableRow) => {
        const { dims } = meta;
        return tableRow[dims.findIndex((dim) => dim.calc === field)];
      };

      const getRowColsData = (colFormula, tableRow) => {
        const { cols, dims } = meta;
        return tableRow[
          cols.findIndex((col) => col.formula === colFormula
                        && col.section === column.section)
          + dims.length
        ];
      };

      const getRowsProcessed = (rows) => rows.map((rowProcessed) => {
        const rowObject = {
          year: getRowData('year', rowProcessed),
          quarter: getRowData('quarter', rowProcessed),
          brand: getRowData('brand', rowProcessed),
          chanel: getRowData('chanel', rowProcessed),
          grade: getRowData('grade', rowProcessed),
          level: getRowData('level', rowProcessed),
          value: getRowData('value', rowProcessed),
          key: rowProcessed.key,
        };
        return rowObject;
      });

      if (column?.metric?.formula === 'sg_revenue_share') {
        const rowsGroupByLevel = table.rows.filter((tableRow) => JSON.stringify(tableRow.slice(0, -2)) === JSON.stringify(row.slice(0, -2)));
        const rowsWithoutEdited = rowsGroupByLevel.filter((rowGrouped) => rowGrouped.key !== row.key);
        const rowsProcessed = getRowsProcessed(rowsWithoutEdited);

        const data = {
          year: getRowData('year', row),
          quarter: getRowData('quarter', row),
          brand: getRowData('brand', row),
          chanel: getRowData('chanel', row),
          grade: getRowData('grade', row),
          user: this.username,
          level: getRowData('level', row),
          value,
          key: row.key,
          rows: rowsProcessed,
        };

        Object.keys(data).forEach((key) => {
          if (data[key] === undefined) {
            this.createNotification(`There is no correct ${key} value`, 'error');
          }
        });

        const url = '/api/calculation/revenue';
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        };

        fetch(url, options)
          .then((response) => response.json())
          .then((result) => {
            if (result) {
              // TODO: remove this part to the api function
              if (!Array.isArray(result)) {
                const resData = result?.detail ? result?.detail[0] : null;

                if (!resData.msg && !resData.key) {
                  this.createNotification(resData, 'error');
                  return;
                }

                if (resData.msg) {
                  this.createNotification(resData.msg, 'warn');
                }

                if (resData.key) {
                  const rowToUpdate = table.rows
                    .find((tableRow) => tableRow.key === resData.key);
                  this.updateRow(rowToUpdate, [column.stream]);
                  return;
                }
              }

              const rowsToUpdate = result
                .map((resultRow) => table.rows
                  .find((tableRow) => tableRow.key === resultRow));
              const level = getRowData('level', rowsToUpdate[0]);

              if (level === 3) {
                table.editNextRow(1);
              }

              rowsToUpdate.forEach((rowToUpdate) => this.updateRow(rowToUpdate, [column.stream]));
            }
          })
          // TODO: make error handling for this case
          .catch((error) => console.error(error.text));
      }

      await this.updateRow(row, [column.stream]);

      if (this.refreshTableAfterEditColumns.includes(column.formula)) {
        this.refreshTable();
      }
    },

    modifyRow(row, func) {
      const { table } = this.$refs;

      if (row.key) {
        const { key, firstElement, gpTargetGroup, level } = row;
        row = _.clone(row);
        row.key = key;

        if (this.type === 'tree') {
          row.firstElement = firstElement;
          row.gpTargetGroup = gpTargetGroup;
          row.level = level;
        }

        Vue.set(table.rowOverrides, key, row);
      }

      row.__cache = {};

      func(row);

      return row;
    },

    async getDependencies() {
      const { data: { dataset: { reports: deps } } } = await fetch('/graphql', {
        method: 'POST',
        body: JSON.stringify({ query: 'query{dataset{reports{name,deps}}}' }),
      }).then((response) => response.json());

      return _(deps)
        .map(({ name, deps }) => deps.map((dep) => [dep, name]))
        .flatten()
        .groupBy(([dep]) => dep)
        .toPairs()
        .map(([dep, pairs]) => [dep, pairs.map(([, name]) => name)])
        .fromPairs()
        .value();
    },

    async updateRow(row, streams = []) {
      const { table } = this.$refs;
      let rowFilters1 = [];
      const rowFilters2 = [];
      let modifiedRow = {};

      _.forEach(table.dims, (dim, i) => {
        const calc = _.isString(dim) ? dim : dim.calc;

        if (calc === 'item' || calc === 'class') {
          if (!String(row[i]).includes('***')) {
            rowFilters1.push(`${calc} == ${utils.quote(row[i])}`);
          }
        } else {
          if (this.type === 'tree' && row.level >= i && i === 0) {
            rowFilters2.push(`${calc} == ${utils.quote(row.firstElement)}`);
          } else {
            if (!String(row[i]).includes('***')) {
              rowFilters2.push(`${calc} == ${utils.quote(row[i])}`);
            }
          }
        }
      });

      const makeFilter = (filters) => _(filters)
        .filter()
        .map((filter) => `(${filter})`)
        .join(' && ');

      let filter0 = makeFilter([table.extraFilter0, table.filter0]);
      const filter1 = makeFilter([table.extraFilter1, table.filter1].concat(rowFilters1));
      const filter2 = makeFilter([table.extraFilter2, table.filter2].concat(rowFilters2));
      const reportId = utils.randomId();
      const reportKey = JSON.stringify({ filter0, filter1, filter2 });

      if (this.updateReports[reportKey]) {
        utils.bridge.trigger('cancelReport', this.updateReports[reportKey]);
      }

      this.updateReports[reportKey] = reportId;

      const { vals } = table;
      const { cols } = table;

      const affectedVals = [];
      const affectedCols = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const stream of streams) {
        if (!this.dependencies) {
          this.dependencies = await this.getDependencies();
        }

        if (!this.streamLinks) {
          const query = `query{dataset{streams{stream:${this.stream}{links{linkName sourceName}}}}}`;

          // eslint-disable-next-line no-await-in-loop
          const { data: { dataset: { streams: { stream: { links } } } } } = await fetch('/graphql', {
            method: 'POST',
            body: JSON.stringify({ query }),
          }).then((response) => response.json());

          this.streamLinks = _(links)
            .map(({ linkName, sourceName }) => [linkName, sourceName])
            .fromPairs()
            .value();
        }

        const affectedSources = new Set();

        affectedSources.add(stream);

        const collectAffectedSources = (source) => {
          // eslint-disable-next-line no-restricted-syntax
          for (const dep of this.dependencies[source] || []) {
            if (!affectedSources.has(dep)) {
              affectedSources.add(dep);
              collectAffectedSources(dep);
            }
          }
        };

        collectAffectedSources(stream);

        // eslint-disable-next-line no-restricted-syntax
        for (const val of vals) {
          // eslint-disable-next-line no-restricted-syntax
          for (const match of val.calc.matchAll(/([a-zA-Z_][a-zA-Z_0-9]*)\.[a-zA-Z_][a-zA-Z_0-9]*/g)) {
            const source = this.streamLinks[match[1]];
            if (affectedSources.has(source) && affectedVals.indexOf(val) === -1) {
              affectedVals.push(val);
            }
          }
        }
      }

      const additionalVals = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const col of cols) {
        if (_.some(affectedVals, (val) => col.calc.indexOf(val.name) !== -1)) {
          affectedCols.push(col);

          // eslint-disable-next-line no-restricted-syntax
          for (const match of col.calc.matchAll(/([a-zA-Z_][a-zA-Z_0-9]*)/g)) {
            const val = vals.find(({ name }) => name === match[1]);

            if (val && affectedVals.indexOf(val) === -1 && additionalVals.indexOf(val) === -1) {
              additionalVals.push(val);
            }
          }
        }
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const val of additionalVals) {
        affectedVals.push(val);
      }

      if (affectedCols.length > 0) {
        filter0 = this.makeFilter([this.makeDatesFilter(affectedCols), filter0]);

        const [src] = await utils.query({
          name: 'gp-edit-rows',
          vars: this.vars,
          stream: table.stream,
          report: table.report,
          cores: table.cores,
          dims: table.dims.map(({ calc }) => calc),
          vals: _.map(affectedVals, 'calc'),
          cols: _.map(affectedCols, 'calc'),
          filter0,
          filter1,
          filter2,
        }, undefined, true);

        if (src && this.updateReports[reportKey] === reportId) {
          delete this.updateReports[reportKey];

          modifiedRow = this.modifyRow(row, (row) => {
            for (let i = 0; i < src.length; ++i) {
              Vue.set(row, cols.indexOf(affectedCols[i]) + table.dims.length, src[i]);
            }
          });

          table.$forceUpdate();
        }
      }
      return modifiedRow;
    },

    interceptClickEvent(e) {
      let control = this.$refs.editingControl;
      if (_.isArray(control)) {
        control = control[0];
      }
      if (control) {
        if ($(e.target).closest($(control).parent()).length === 0) {
          this.stopEditing();
        }
      }
    },

    editSectionName(section) {
      this.startEditing({
        editingValue: section.name,
        editingColumn: null,
        editingSection: section,
        editingTimeframe: false,
        editingClass: false,
      });
    },

    editColumnName(section, column) {
      this.startEditing({
        editingValue: column,
        editingColumn: column,
        editingSection: section,
        editingTimeframe: false,
        editingClass: false,
      });
    },

    editSectionTimeframe(section) {
      this.startEditing({
        editingValue: section.timeframe,
        editingColumn: null,
        editingSection: section,
        editingTimeframe: true,
        editingClass: false,
      });
    },

    editColumnTimeframe(section, column) {
      this.startEditing({
        editingValue: column.timeframe,
        editingColumn: column,
        editingSection: section,
        editingTimeframe: true,
        editingClass: false,
      });
    },

    editColumnClass(section, column) {
      this.startEditing({
        editingValue: column.timeframe,
        editingColumn: column,
        editingSection: section,
        editingTimeframe: false,
        editingClass: true,
      });
    },

    renameColumn(section, column) {
      let { name } = column;

      if (column.metric && column.metric.timeframe !== column.timeframe) {
        name += ` (${this.getTimeframeName(column.timeframe)})`;
      }

      const alias = window.prompt(utils.l10n('Please enter column name'), column.alias || name);

      if (alias != null) {
        if (alias) {
          column.alias = alias;
        } else {
          delete column.alias;
        }
        this.editingSections = _.clone(this.editingSections);
      }
    },

    startEditing(options) {
      if (this.preventEditing) {
        return;
      }

      const {
        editingValue,
        editingColumn,
        editingSection,
        editingTimeframe,
        editingClass,
      } = options;

      const isEditingDataUpdated = this.editingColumn !== editingColumn
        || this.editingSection !== editingSection
        || this.editingTimeframe !== editingTimeframe
        || this.editingClass !== editingClass;

      if (isEditingDataUpdated) {
        this.editingValue = editingValue;
        this.editingColumn = editingColumn;
        this.editingSection = editingSection;
        this.editingTimeframe = editingTimeframe;
        this.editingClass = editingClass;
      }
    },

    stopEditing() {
      this.editingValue = null;
      this.editingColumn = null;
      this.editingSection = null;
      this.editingTimeframe = false;
      this.editingClass = false;
    },

    submitEditing() {
      if (this.editingColumn) {
        this.updateColumnName(
          this.editingSection,
          this.editingColumn,
          this.editingValue,
        );
      } else if (this.editingSection) {
        this.updateSectionName(
          this.editingSection,
          this.editingValue,
        );
      }

      this.stopEditing();
    },

    handleEditingDimensionKeyDown(e) {
      if (e.key === 'Enter') {
        const attribute = this.attributes.find(({name}) => name == this.editingDimension);

        if (attribute) {
          const dimension = _.cloneDeep(attribute);
          dimension.id = utils.randomId();
          dimension.attribute = attribute;
          this.editingDimensions.push(dimension);
          this.editingDimension = '';
        } else {
          this.editingDimension = '';
        }
      }
    },

    handleEditingControlKeyDown(e) {
      if (e.key === 'Enter') {
        this.submitEditing();
      }

      if (e.key === 'Escape') {
        this.stopEditing();
      }
    },

    handleEditingControlBlur() {
      this.submitEditing();
    },

    updateSectionTimeframe(section, timeframe) {
      section.timeframe = timeframe;
      this.editingSections = _.clone(this.editingSections);
    },

    updateColumnTimeframe(column, timeframe) {
      column.timeframe = timeframe;
      this.editingSections = _.clone(this.editingSections);
      this.stopEditing();
    },

    updateColumnClass(column, classObj) {
      column.classObj = classObj;
      this.editingSections = _.clone(this.editingSections);
      this.stopEditing();
    },

    updateSectionName(section, name) {
      section.name = name;
      this.editingSections = _.clone(this.editingSections);
    },

    updateColumnName(section, column, value) {
      const index = section.columns.indexOf(column);

      if (this.attributes.indexOf(value) !== -1) {
        const attribute = value;

        if (attribute.id) {
          delete attribute.id;
        }

        if (attribute !== undefined) {
          section.columns.splice(index, 1, _.assign({id: column.id, type: 'attribute', attribute}, attribute));
        }
      }

      if (this.metrics.indexOf(value) !== -1) {
        const metric = value;

        if (metric.id) {
          delete metric.id;
        }

        section.columns.splice(index, 1, _.assign({ id: column.id, type: 'metric', metric }, metric));
      }

      if (this.calc_columns.indexOf(value) !== -1) {
        const calcColumn = value;

        if (calcColumn.id) {
          delete calcColumn.id;
        }
        
        section.columns.splice(index, 1, _.assign({id: column.id, type: 'calc_column', calcColumn }, calcColumn));
      }

      this.editingSections = _.clone(this.editingSections);
    },

    setupEditingControl() {
      let control = this.$refs.editingControl;

      if (_.isArray(control)) {
        control = control[0];
      }

      if (control) {
        $(control).focus();
        $(control).select();
      }
    },

    parseDate(text) {
      return utils.parseDate(text);
    },

    sectionName(section) {
      return section.name;
    },

    columnIcons(column) {
      const icons = [];
      if (column.hideZeroRows
        || column.hideEmptyRows
        || column.condition
        && column.condition !== 'none'
        || !_.isEmpty(column.values)
      ) {
        icons.push('filter');
      }

      if (!(column.metric && this.metricsByName[column.metric.name])
        && !(column.attribute && this.attributesByName[column.attribute.name])
        && !(column.calc_column && this.calcColumnById[column.calc_column.id])
      ) {
        icons.push('alert-triangle');
      }

      return icons;
    },

    columnActions(column) {
      const actions = [];
      const format = column.metric?.format || column.attribute?.format || null;
      const numeric = column.type !== 'string' && column.type !== 'tagged' && format !== 'date' && format !== 'timestamp';

      if (column.editable) {
        actions.push({
          icon: 'trash',
          text: utils.l10n('Remove all values'),
          call: () => {
            this.removeAllValues(column);
            return true;
          },
        });
      }

      if (!this.checkUnEditableColumn(column) && this.evaluateButtons.findIndex((button) => button.name === 'calculation/capacity-grades') !== -1) {
        actions.push({
          icon: 'book',
          text: utils.l10n('Get from the common grade'),
          call: (column) => {
            this.unEditableColumns.push({ 
              id: column.id, 
              editable: column.editable, 
            });
            this.updateColumn(column.id, (column) => {
              column.columnClass = '() => "capacity-grade-column"';
              column.editable = '() => {}';
            });
            this.handleGradeFromTotalArea(column, 'post');
            return true;
          },
        });

      }

      if (!!this.checkUnEditableColumn(column) && this.evaluateButtons.findIndex((button) => button.name === 'calculation/capacity-grades') !== -1) {
        actions.push({
          icon: 'book-open',
          text: utils.l10n('Skip getting from the common grade'),
          call: (column) => {
            const columnPrevEditable = this.unEditableColumns.find((col) => col.id === column.id).editable;
            this.updateColumn(column.id, (column) => {
              column.columnClass = '';
              column.editable = columnPrevEditable;
            });
            this.unEditableColumns = this.unEditableColumns.filter(({id}) => id != column.id);
            this.handleGradeFromTotalArea(column, 'delete');
            return true;
          },
        });
      }

      if (numeric) {
        actions.push({
          key: 'gp-column-precision',
          tagname: 'gp-column-precision',
          config: {
            props: {
              value: column.precision,
            },
            on: {
              change: (value) => {
                const precision = value
                  ? parseInt(value, 10)
                  : undefined;

                this.updateColumn(column.id, (column) => { column.precision = precision; });
                Vue.nextTick(() => this.$refs.table.requestData({ silent: true }));
              },
            },
          },
        });
      }

      actions.push({ tagname: 'hr' });

      if (numeric) {
        actions.push({
          key: 'gp-column-stats',
          tagname: 'gp-column-stats',
          config: {
            props: {
              format,
              column,
              formats: this.formats,
              rows: this.$refs.table.report.rows,
            },
          },
        });
      }

      actions.push({
        key: 'gp-column-styles',
        tagname: 'gp-column-styles',
        config: {
          props: {
            column,
            conditions: this.conditions,
            styles: this.styles,
            rows: this.$refs.table.report.rows,
          },
          on: {
            change: (changes) => {
              this.updateColumn(column.id, (column) => _.assign(column, changes));
            },
          },
        },
      });

      actions.push({
        key: 'gp-column-filters',
        tagname: 'gp-column-filters',
        config: {
          props: {
            conditions: this.conditions,
            condition: column.condition || '',
            args: column.args || [],
            values: column.values || {},
            stream: this.stream,
            groups: ['column-filters'].concat(this.groups.slice(1)),
            column,
            rows: _.get(this.$refs.table, 'data.dataset.source.report.rows') || [],
            dims: this.dims,
            vals: this.vals,
            cols: this.cols,
            columns: this.$refs.table.columns,
            filter0: this.filter0,
            filter1: this.filter1,
            filter2: this.filter2,
            product: this.product,
          },
          on: {
            change: (changes) => {
              this.updateColumn(column.id, (column) => _.assign(column, changes));
            },
            submit: (changes) => {
              this.updateColumn(column.id, (column) => _.assign(column, changes));
              this.$refs.table.selectedColumn = null;
            },
            cancel: () => {
              this.$refs.table.selectedColumn = null;
            },
          },
        },
      });

      switch (column.type) {
        case 'string':
        case 'tagged':
          actions.push({
            icon: 'hide-rows',
            text: column.hideEmptyRows === true ? 'Show empty rows' : 'Hide empty rows',
            call: this.hideEmptyRows,
          });
          break;
        default:
          actions.push({
            icon: 'hide-rows',
            text: column.type == 'bool'
              ? (column.hideZeroRows === true ? 'Show "no" rows' : 'Hide "no" rows')
              : (column.hideZeroRows === true ? 'Show zero rows' : 'Hide zero rows'),
            call: this.hideZeroRows,
          });
      }

      if (column.hideZeroRows
        || column.hideEmptyRows
        || column.condition
        && column.condition !== 'none'
        || !_.isEmpty(column.values)
      ) {
        actions.push({
          icon: 'x',
          text: 'Remove all filters',
          call: () => this.removeAllFilters(column),
        });
      }

      return actions;
    },

    sectionActions(section) {
      const actions = [];

      if (section.name !== 'Dimensions') {
        section = this.currentSections.find(({ name }) => name === section.name);
        if (section) {
          actions.push({
            tagname: 'gp-section-columns',
            config: {
              props: {
                attributes: this.attributes,
                metrics: this.metrics,
                columns: section.columns,
              },
              on: {
                submit: (changes) => {
                  this.updateSection(section.id, (section) => _.assign(section, changes));
                  this.$refs.table.selectedSection = null;
                },
                cancel: () => {
                  this.$refs.table.selectedSection = null;
                },
              },
            },
          });
        }
      }

      return actions;
    },

    updateColumn(id, callback, modes=['editing', 'current']) {
      // eslint-disable-next-line no-restricted-syntax
      for (const mode of modes) {
        const sections = _.cloneDeep(this[`${mode}Sections`]);
        const dimensions = _.cloneDeep(this[`${mode}Dimensions`]);

        // eslint-disable-next-line no-restricted-syntax
        for (const section of sections) {
          // eslint-disable-next-line no-restricted-syntax
          for (const column of section.columns) {
            if (column.id === id) {
              callback(column);
            }
          }
        }

        // eslint-disable-next-line no-restricted-syntax
        dimensions.forEach((dim, i) => {
          let uniqueId;
          
          if (id.startsWith('dimension')) {
            uniqueId = id;
          } else {
            uniqueId = `dimension-${id}-${i}`;
          }

          if (!dim.uniqueDimensionKey) {
            dim.uniqueDimensionKey = `dimension-${dim.id}-${i}`;
          }
          
          if (dim.uniqueDimensionKey == uniqueId) {
            callback(dim);
          }
        });



        this[`${mode}Sections`] = sections;
        this[`${mode}Dimensions`] = dimensions;
      }
    },

    updateSection(id, callback) {
      const editingSections = _.cloneDeep(this.editingSections);
      const currentSections = _.cloneDeep(this.currentSections);

      // eslint-disable-next-line no-restricted-syntax
      for (const section of currentSections) {
        if (section.id === id) {
          callback(section);
        }
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const section of editingSections) {
        if (section.id === id) {
          callback(section);
        }
      }

      this.editingSections = editingSections;
      this.currentSections = currentSections;
    },

    hideZeroRows(column) {
      this.updateColumn(column.id, (column) => {
        column.hideZeroRows = !(column.hideZeroRows === true);
      });
      Vue.nextTick(() => this.$refs.table.requestData({ silent: true }));

      return false;
    },

    hideEmptyRows(column) {
      this.updateColumn(column.id, (column) => {
        column.hideEmptyRows = !(column.hideEmptyRows === true);
      });
      Vue.nextTick(() => this.$refs.table.requestData({ silent: true }));

      return false;
    },

    addSection() {
      const section = {
        id: utils.randomId(),
        name: '',
        columns: [],
      };
      const editingSections = _.clone(this.editingSections);
      editingSections.push(section);
      this.editingSections = editingSections;
      this.editingValue = null;
      this.editingColumn = null;
      this.editingSection = section;
      this.editingTimeframe = false;
      setTimeout(this.setupEditingControl, 100);
      _.defer(this.setupSortable);
    },

    removeSection(section) {
      if (this.editingSection === section) {
        this.stopEditing();
      }

      this.editingSections = _.without(this.editingSections, section);
    },

    addColumn(section) {
      const column = {
        id: utils.randomId(),
        name: '',
        type: '',
      };
      section.columns.push(column);
      this.editingValue = null;
      this.editingColumn = column;
      this.editingSection = section;
      this.editingTimeframe = false;
      this.editingSections = _.clone(this.editingSections);
    },

    removeColumn(section, column) {
      if (this.editingColumn === column) {
        this.stopEditing();
      }

      section.columns = _.without(section.columns, column);
      this.editingSections = _.clone(this.editingSections);
    },

    applyChanges() {
      this.currentFilter = _.cloneDeep(this.editingFilter);
      this.currentSections = _.cloneDeep(this.editingSections);
      this.currentDimensions = _.cloneDeep(this.editingDimensions);
    },

    submitChanges() {
      let name = this.$refs.savedReports.inputValue;

      if (!name) {
        name = window.prompt('Plase enter report name');
      }

      if (!name) {
        return;
      }

      if (!this.savedReport || this.savedReport.name !== name || this.savedReport.username !== this.username) {
        if (this.savedReport && this.savedReport.name === name && this.savedReport.username !== this.username) {
          const text = utils
            .l10n('This report was created by user {user}. Would you like to give your copy another name?')
            .replace('{user}', this.savedReport.username);
          const newName = window.prompt(text, name);

          if (newName) {
            name = newName;
          }
        }

        const id = utils.randomId();
        const savedReport = {
          id,
          name,
          filter: _.cloneDeep(this.editingFilter),
          sections: _.cloneDeep(this.editingSections),
          dimensions: _.cloneDeep(this.editingDimensions),
          username: this.username,
        };

        this.savedReports.push(savedReport);
        this.savedReport = savedReport;
        Promise.resolve($.ajax({
          url: `/storage/reports/${id}`,
          method: 'PUT',
          data: JSON.stringify(this.savedReport),
        })).then(this.loadSavedReports);
      } else {
        const { id } = this.savedReport;

        this.$set(this.savedReport, 'filter', _.cloneDeep(this.editingFilter));
        this.$set(this.savedReport, 'sections', _.cloneDeep(this.editingSections));
        this.$set(this.savedReport, 'dimensions', _.cloneDeep(this.editingDimensions));
        Promise.resolve($.ajax({
          url: `/storage/reports/${id}`,
          method: 'PUT',
          data: JSON.stringify(this.savedReport),
        })).then(this.loadSavedReports);
      }

      this.currentFilter = _.cloneDeep(this.editingFilter);
      this.currentSections = _.cloneDeep(this.editingSections);
      this.currentDimensions = _.cloneDeep(this.editingDimensions);
    },

    discardChanges() {
      this.editingFilter = _.cloneDeep(this.currentFilter);
      this.editingSections = _.cloneDeep(this.currentSections);
      this.editingDimensions = _.cloneDeep(this.currentDimensions);
    },

    deleteSettings() {
      const text = utils
        .l10n('Are you sure you want to delete report "{report}"')
        .replace('{report}', this.savedReport.name);

      if (window.confirm(text)) {
        const { id } = this.savedReport;
        const i = this.savedReports.indexOf(this.savedReport);

        if (i !== -1) {
          this.savedReports.splice(i, 1);
        }

        this.savedReport = null;
        Promise.resolve($.ajax({
          url: `/storage/reports/${id}`,
          method: 'DELETE',
        })).then(this.loadSavedReports);
      }
    },

    rowSelected(row) {
      this.$emit('row-selected', row);
      const { table } = this.$refs;

      row = table.report ? table.report.rows[row + table.offset] : null;

      if (row != null) {
        this.context = { row, meta: table.meta };
      } else {
        this.context = null;
      }
    },

    rowsSelected(rows) {
      this.$emit('rows-selected', rows);
    },

    columnSelected(column) {
      this.$emit('column-selected', column);
    },

    columnMoved({ self, prev, next }) {
      const moveColumnInplace = (sections) => {
        let column = null;

        // eslint-disable-next-line no-restricted-syntax
        for (const section of sections) {
          const i = section.columns.findIndex(({ id }) => id == self);

          if (i !== -1) {
            column = section.columns.splice(i, 1)[0];
            break;
          }
        }

        if (column && next) {
          // eslint-disable-next-line no-restricted-syntax
          for (const section of sections) {
            const i = section.columns.findIndex(({ id }) => id == next);
            if (i !== -1) {
              section.columns.splice(i, 0, column);
              column = null;
              break;
            }
          }
        }

        if (column && prev) {
          // eslint-disable-next-line no-restricted-syntax
          for (const section of sections) {
            const i = section.columns.findIndex(({ id }) => id == prev);
            if (i !== -1) {
              section.columns.splice(i + 1, 0, column);
              column = null;
              break;
            }
          }
        }

        if (column) {
          sections.slice(-1)[0].columns.push(column);
        }
      };

      const currentSections = _.cloneDeep(this.currentSections);
      const editingSections = _.cloneDeep(this.editingSections);

      moveColumnInplace(currentSections);
      moveColumnInplace(editingSections);

      this.currentSections = currentSections;
      this.editingSections = editingSections;

      Vue.nextTick(() => this.$refs.table.requestData({ silent: true }));
    },

    columnUpdated(column, props) {
      this.updateColumn(column.uniqueDimensionKey || column.id, (column) => _.assign(column, props));
      Vue.nextTick(() => this.$refs.table.requestData({ silent: true }));
    },

    async findAndRemoveRecordsFromStream(stream, filter) {
      return graphQl.makeGQRequest(`
        query {
          dataset {
            streams {
              ${stream}{
                records (
                  filter: "${filter}"
                ){
                  rows(
                    columns:["__id"]
                    )
                  }
                }
              }
            }
        }`).then((result) => {
          if (result.type && result.type === 'error') {
            this.createNotification(result, 'error');
            return;
          }

          if (result.errors) {
            result.errors.forEach(error => {
              this.createNotification(error.message, 'error');
            });
            return;
          }

          const { data: { dataset: { streams: { [stream]: { records: { rows } } } } } } = result;
          if (rows.length) {
            const ids = [];
            rows.forEach(row => ids.push(row[0]));
            
            graphQl.makeGQRequest(`
            mutation {
              removeRecords(
                stream: "${stream}"
                ids: [${ids}])
              } `
            );
          }
        }
      );
    },
  },
};
</script>

<style>
.gp-table-settings {
  margin: 0;
  width: 100%;
  margin-bottom: 10px;
}
.gp-table-settings td {
  font-size: 14px;
  line-height: 1.4;
  padding: 2px 6px;
  position: relative;
}
.gp-table-settings td .gp-select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.gp-table-settings tr.gp-table-column:hover td {
  background-color: #3498db10;
  position: relative;
}
.gp-table-settings tr.gp-table-column:hover td:before,
.gp-table-settings tr.gp-table-column:hover td:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background-color: var(--cyan);
}
.gp-table-settings tr.gp-table-column:hover td:before {
  top: -1px;
}
.gp-table-settings tr.gp-table-column:hover td:after {
  bottom: -1px;
}
.gp-table-settings .gp-table-section td {
  background-color: var(--light);
  border-top: 1px solid var(--dark);
  padding-top: 4px;
  padding-bottom: 4px;
}
.gp-table-settings + p {
  display: flex;
  margin-right: -10px;
}
.gp-table-settings + p > * {
  flex-grow: 1;
  margin-right: 10px;
}
.my-dark-theme .gp-table-settings .gp-table-section td {
  background-color: var(--dark);
  border-color: var(--light);
}
.gp-table-actions {
  display: none;
}
.gp-table-configuration .ace_editor {
  margin-left: -20px;
  margin-right: -20px;
  width: calc(100% + 40px)!important;
  margin-bottom: 15px;
}
.gp-table-settings {
  margin-left: -20px;
  margin-right: -20px;
  width: calc(100% + 40px);
}
.gp-table-settings td:first-child {
  padding-left: 20px;
}
.gp-table .plain-table > table > thead > tr > th {
  overflow: visible;
}
.gp-table .plain-table > table > thead > tr > th > a {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.column-move-target-prev .column-move-target,
.column-move-target-next .column-move-target {
  position: absolute;
  height: 1000px;
  width: 1px;
  top: 0;
  background-color: var(--green);
  z-index: 100;
}
.column-move-target-prev .column-move-target {
  right: 0;
}
.column-move-target-next .column-move-target {
  left: 0;
}
.gp-table tr.ui-sortable {
  position: relative;
}
.gp-table th.ui-sortable-handle {
  display: table-cell!important;
}
.gp-table th.ui-sortable-placeholder {
  display: none!important;
}
.gp-table th.ui-sortable-helper {
  top: 31px!important;
  display: table-cell!important;
  opacity: 0.8;
}
.gp-table .plain-table-manage-column {
  margin-top: 3px;
}
.gp-table .table > thead > tr:last-child > th {
  position: sticky;
  top: -20px;
  background-color: #E9E9E9;
  z-index: 2;
  vertical-align: top;
  text-align: left;
  word-wrap: break-word;
}
.my-dark-theme .gp-table .table > thead > tr:last-child > th {
  background-color: #222;
}
.gp-table .table > thead > tr:last-child > th:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0px;
  background-color: var(--dark);
}
.my-dark-theme .gp-table .table > thead > tr:last-child > th:after {
  background-color: var(--light);
}
.gp-table .table > thead > tr:last-child > th a {
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 1px;
}
.gp-table .table > thead > tr:last-child > th a:hover {
  text-decoration: none;
  cursor: pointer;
}
.gp-table .plain-table > table > tbody > tr > td {
  white-space: nowrap;
}
.gp-table .my-caption {
  display: none;
}
.gp-table-dates {
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}
.gp-table-dates select,
.gp-table-dates select:focus {
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: var(--cyan);
  line-height: 22px;
  height: 22px;
  padding: 0;
  margin: 0;
  font-size: 1em;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  box-shadow: none;
}
.gp-table-dates select:hover {
  text-decoration: underline;
}
.gp-table-dates > * {
  margin-right: 10px;
}
.gp-table-dates label {
  margin-right: 4px;
}
.gp-table-dates svg {
  width: 18px;
  height: 18px;
  margin-top: 1px;
  margin-right: 4px;
  vertical-align: top;
}
.gp-table-settings + p {
  margin-top: 20px;
}
.gp-table-settings td:nth-child(1) {
  width: 20px;
}
.gp-table-settings td:nth-child(2) {
  width: 100%;
}
.gp-table-settings td:nth-child(3),
.gp-table-settings td:nth-child(4),
.gp-table-settings td:nth-child(5),
.gp-table-settings td:nth-child(6) {
  width: 16px;
  text-align: right;
}
.gp-table-settings .feather-icon svg {
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-top: -4px;
}
.gp-table-settings .feather-icon-trash svg,
.gp-table-settings .feather-icon-edit-3 svg,
.gp-table-settings .feather-icon-calendar svg,
.gp-table-settings .feather-icon-sliders svg {
  visibility: hidden;
}
.gp-table-settings tr:hover .feather-icon svg {
  visibility: initial;
}
.gp-table-timeframes {
  list-style: none;
  margin: 0;
  padding: 4px 0;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--gray-dark);
}
.gp-table-timeframes li a {
  color: #212529;
  display: block;
  line-height: 24px;
  white-space: nowrap;
  padding: 0;
  padding-left: 6px;
  padding-right: 20px;
}
.gp-table-timeframes li a > span:first-child {
  display: inline-block;
  width: 16px;
}
.gp-table-settings td .gp-select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -1px;
  z-index: 1;
}
.my-dark-theme .gp-table-settings td .gp-select input {
  background-color: white;
  color: #222!important;
}
.gp-table-settings td > input {
  margin: -9px!important;
  font-size: 14px;
}
.gp-table-settings td .gp-select input {
  height: 100%;
}
.gp-settings-actions {
  display: flex;
  flex-wrap: wrap;
  position: sticky;
  top: -21px;
  margin: 0 -20px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 20px;
  padding-right: calc(20px - 8px);
  z-index: 5;
  background-color: white;
  border-top: 1px solid var(--gray);
  border-bottom: 1px solid var(--gray);
  box-shadow: 0 10px 10px -10px #00000020;
}
.my-dark-theme .gp-settings-actions {
  background-color: var(--dark);
}
.gp-settings-actions .gp-select {
  flex-basis: 100%;
  margin-right: 8px;
  margin-bottom: 8px;
}
.gp-settings-actions .gp-check {
  flex-basis: 100%;
  margin-bottom: 10px;
}
.gp-settings-actions button {
  flex-grow: 1;
  flex-basis: 30%;
  margin-right: 8px;
  margin-bottom: 4px;
}
.gp-table tr.selected td {
  cursor: pointer;
}
.gp-table tr.selected td:hover {
  text-decoration: underline;
}
.gp-table .my-tooltip-actions {
  display: flex;
}
.gp-table .my-tooltip-actions button {
  flex-basis: 50%;
  flex-grow: 1;
}
.gp-table .my-tooltip-values td:last-child {
  text-align: left;
  max-width: 200px;
}
.gp-table .plain-table {
  clear: right;
}
.gp-table .table {
  margin-bottom: 0;
}
.gp-table .plain-table-body {
  padding-bottom: 0!important;
}
.popover.gp-table-permalink {
  max-width: 100vw;
  width: 520px;
  box-shadow: 0 2px 10px -5px grey;
  font-size: 1em;
}
.popover.gp-table-permalink input {
  font-size: 0.9em;
}
.popover.gp-table-permalink svg {
  width: 18px;
}
.my-dark-theme .popover.gp-table-permalink {
  box-shadow: 0 2px 10px -5px black;
}
.my-dark-theme .popover.gp-table-permalink button {
  color: var(--light);
}
.gp-table-dates * {
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
  font-style: normal;
  font-weight: 500; 
  line-height: 120%;
}
.gp-table-dates label {
  display: inline-block;
}
.gp-table-dates .gp-date {
  display: flex;
  align-items: center;
}
.gp-table-dates .gp-table-dates__container {
  display: flex;
  align-items: center;
}
.gp-date .feather-icon svg {
    width: 16px;
    height: 16px;
    margin-left: 8px;
    color: var(--red);
}
.gp-table-timeframe {
  overflow: hidden;
  position: relative;
}
.gp-table-timeframe span {
  color: var(--cyan);
}
.gp-table-timeframe:hover span {
  text-decoration: underline;
}
.gp-table-timeframe select {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.gp-table .table td,
.gp-table .table th {
  padding: 5px 4px;
  line-height: 14px;
}
.gp-table .table > thead > tr > th {
  position: sticky;
  z-index: 2;
  background-color: #f7f7f7 !important;
  border: 1px solid #E9E9E9;
  padding: 11px 8px;
}
.my-dark-theme .gp-table .table > thead > tr > th {
  background-color: #222 !important;
}
.gp-table .table > thead > tr > th.section-start.section-end.my-column-dim {
  border-top-left-radius: 8px;
}
.gp-table .table > thead > tr:first-child > th:last-child {
    border-top-right-radius: 8px;
}
.gp-table .table > thead > tr:first-child > th {
  top: 0;
}
.gp-table .table > thead > tr:last-child > th {
  top: var(--head-first-row-height)!important;
}
.gp-table .plain-table .table {
  margin-top: 0;
}
.gp-table .table .my-column-dim.section-end:before {
  right: 0;
}
.gp-table .plain-table-totals td {
  position: sticky;
  top: var(--head-height);
  z-index: 2;
}
.gp-table .plain-table-totals td.my-column-dim {
  z-index: 3!important;
}
.gp-column-stats table {
  font-size: 0.95em;
  line-height: 1.5em;
}
.gp-table-dates label {
  margin-bottom: 0;
  display: inline-block;
}
.gp-table-dates select {
  width: auto;
  display: inline-block;
}
.gp-table-dates .feather-icon svg {
  margin-right: 2px;
}
.gp-table-dates + .gp-table-dates {
  margin-top: -4px;
}
.gp-table {
  flex-basis: 1px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.gp-table .plain-table {
  display: flex!important;
  flex-direction: column;
  flex-basis: 1px;
  flex-grow: 1;
}
.gp-table .plain-table-body {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  flex-basis: 1px;
  flex-grow: 1;
  margin: -20px;
  padding: 20px;
  margin-top: 4px;
  padding-top: 0;
  overflow-anchor:none;
  border-top: none;
}
.gp-table .plain-table-body::-webkit-scrollbar {
    width: 12px;
    background-color: #ffffff;
}
.my-dark-theme .gp-table .plain-table-body::-webkit-scrollbar {
  background-color: #a3a3a3;
}
.my-dark-theme .gp-table .plain-table-body::-webkit-scrollbar-corner {
  border-top: 1px solid #686868;
  border-left: 1px solid #686868;
  background-color: #a3a3a3;
}
.gp-table .plain-table-body::-webkit-scrollbar-thumb {
    width: 12px;
    border-radius: 6px;
    background-color: #E9E9E9;
}
.my-dark-theme .gp-table .plain-table-body::-webkit-scrollbar-thumb {
  background-color: #686868;
}
.my-dark-theme .gp-table .plain-table-body {
  border-top: none;
}
.gp-table .table > thead > tr:last-child > th {
  top: 0;
}
.gp-table .table > thead > .my-design-panel {
  z-index: 3;
}
.gp-table .table .my-column-dim {
  position: sticky;
  background-color: white;
  z-index: 2;
}
.gp-table .table .leaf > td.my-column-dim {
  background-color: #F4FAFF;
  border: none;
}
.my-dark-theme .gp-table .table .my-column-dim {
  background-color: rgb(34,34,34)!important;
  z-index: 2;
}
.gp-table .table tr.group-primary-row td.my-column-dim {
  background-color: #CCE5FF;
}
.gp-table .table tr.group-secondary-row td.my-column-dim {
  background-color: #F4FAFF;
}
.gp-table .table tr:hover td.my-column-dim {
  background-color: rgb(233,242,250)!important;
}
.gp-table .table tr.selected td.my-column-dim {
  background-color: #CCE5FF;
}
.my-dark-theme .gp-table .table tr:hover td.my-column-dim,
.my-dark-theme .gp-table .table tr.selected td.my-column-dim {
  background-color: rgb(40,49,57)!important;
}
.gp-table .table .my-column-dim.section-end:before {
  content: "";
  position: absolute;
  right: -1px;
  top: 0;
  bottom: 0;
  width: 0px;
  background-color: var(--dark);
}
.my-dark-theme .gp-table .table .my-column-dim:before {
  background-color: var(--light);
}
.gp-table .plain-table-slider {
  margin-top: 2px;
  margin-bottom: 0;
}
.gp-table .table th.my-column-dim {
  z-index: 3!important;
}
.gp-table .table:not(.table-expandable) tr.selected > td:after {
  top: 0;
  bottom: 0;
}
.gp-table .table {
  width: initial;
}
.gp-table-timeframe-dates {
  float: right;
  margin-left: 10px;
  font-size: 0.9em;
  opacity: 0.7;
}
.popover.plain-table-manage-section {
  max-width: 370px;
}
.gp-table .feather-icon-book,
.gp-table .feather-icon-book-open {
  margin-left: 8px;
}
.gp-table-timeframes {
  font-size: 0.9em;
}
.gp-table-timeframes svg {
  width: 18px;
}
.gp-table-failed-edits {
  margin-bottom: 10px;
}
.gp-table-failed-edits svg {
  width: 18px;
  z-index: -1;
}
.gp-table-failed-edits table {
  font-size: 0.9em;
  margin: 10px 10px;
}
.gp-table-failed-edits th,
.gp-table-failed-edits td {
  padding: 0px 4px;
}
.gp-table-failed-edits button {
  min-width: 100px;
  margin-right: 8px;
}
.gp-content-head-hide {
  z-index: 1;
}
.popover.gp-table-upload-file {
  max-width: 100vw;
  max-height: 80vh;
  overflow: auto
}
.gp-table-upload-file-ready ul {
  list-style: none;
}
.gp-table-upload-file-ready li {
  white-space: nowrap;
}
.popover-header {
  margin-top: -8px;
  margin-left: -12px;
  margin-right: -12px;
  margin-bottom: 15px;
}
.gp-table-upload-file-actions {
  text-align: right;
}
.gp-table-upload-file-actions .btn {
  min-width: 90px;
}
.gp-table-upload-file-actions .btn + .btn {
  margin-left: 5px;
}
.gp-table-upload-file table th,
.gp-table-upload-file table td {
  font-weight: normal;
  font-weight: normal;
  padding: 2px 4px;
}
.gp-table-upload-file table td:not(:first-child) {
  text-align: right;
}
.gp-table-upload-file table th {
  writing-mode: vertical-rl;
  text-orientation: sideways;
  transform: rotate(180deg) translate(-5px, 0);
}
.gp-table .plain-table[id="tree-table"] td.section-start.my-column-actionable {
  padding-left: calc(var(--level) * 16px + 20px) !important;
}
.gp-table .plain-table[id="tree-table"] td.section-start.my-column-actionable[data-column='0'] .my-colum-action {
  left: calc(var(--level) * 16px) !important;
  right: auto;
}
.gp-table .hidden-form {
  display: none;
}
.plain-table[id="tree-table"] tr:not(:last-child) td.section-start.my-column-actionable.my-column-string.my-column-dim:first-child:before {
  content: '';
  position: absolute;
  height: 100%;
  left: 4px;
  width: calc(var(--level) * 14px);
  top: 0;
  background: repeating-linear-gradient(to right, #e9e9e9 0, #e9e9e9 1px, transparent 1px, transparent 16px) 0 no-repeat;
}
tr:not(:last-child).leaf td.section-start.my-column-actionable.my-column-string.my-column-dim:first-child:before {
  background: repeating-linear-gradient(to right, #BCD8F5 0, #BCD8F5 1px, transparent 1px, transparent 16px) 0 no-repeat;
}
td.my-column-actionable .my-colum-action:has(.feather)::before {
  display: none;
}
.form-check--in-settings {
  display: flex;
  min-height: 41px;
}
</style>
