var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gp-table" },
    [
      _vm.failedEdits.length != 0
        ? _c(
            "div",
            { staticClass: "alert alert-warning gp-table-failed-edits" },
            [
              _c("l10n", {
                attrs: { value: "The following changes are failed to save:" }
              }),
              _vm._v(" "),
              _c("table", { staticClass: "gp-table-failed-edits" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th"),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "Column" } })], 1),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "Error" } })], 1),
                    _vm._v(" "),
                    _c(
                      "th",
                      [_c("l10n", { attrs: { value: "Old value" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      [_c("l10n", { attrs: { value: "New value" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("th", [_c("l10n", { attrs: { value: "Row" } })], 1)
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.failedEdits, function(edit, i) {
                    return _c(
                      "tr",
                      { key: i },
                      [
                        _c(
                          "td",
                          [
                            edit.status == "retrying"
                              ? _c("feather-icon", { attrs: { name: "clock" } })
                              : _c("feather-icon", {
                                  attrs: { name: "alert-circle" }
                                })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("l10n", {
                              attrs: { value: edit.args.column.name }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", [_c("span", [_vm._v(_vm._s(edit.error))])]),
                        _vm._v(" "),
                        _c("td", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                edit.args.column.format(
                                  edit.args.oldValue,
                                  edit.args.row,
                                  edit.args.column
                                )
                              )
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("span", [_vm._v(_vm._s(edit.args.value))])
                        ]),
                        _vm._v(" "),
                        _vm._l(edit.args.meta.columns, function(column, i) {
                          return column.section == "Dimensions"
                            ? _c("td", { key: i }, [
                                _c("span", [_vm._v(_vm._s(edit.args.row[i]))])
                              ])
                            : _vm._e()
                        })
                      ],
                      2
                    )
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "gp-table-failed-edits-controls" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn-sm btn-primary",
                    attrs: { type: "button" },
                    on: { click: _vm.retryFailedEdits }
                  },
                  [_c("l10n", { attrs: { value: "Retry" } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn-sm btn-secondary",
                    attrs: { type: "button" },
                    on: { click: _vm.discardFailedEdits }
                  },
                  [_c("l10n", { attrs: { value: "Discard" } })],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.product == "price"
        ? _c("div", { staticClass: "gp-table-dates" }, [
            _c(
              "div",
              { staticClass: "gp-table-dates__container" },
              [
                _c("feather-icon", { attrs: { name: "calendar" } }),
                _vm._v(" "),
                _c(
                  "label",
                  [_c("l10n", { attrs: { value: "Reference date:" } })],
                  1
                ),
                _vm._v(" "),
                _c("gp-date", {
                  attrs: {
                    value: _vm.nextDate(_vm.referenceDate),
                    "expect-today": true
                  },
                  on: {
                    input: function($event) {
                      _vm.referenceDate = _vm.prevDate($event)
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", [
              _c("label", [_c("l10n", { attrs: { value: "Actuals:" } })], 1),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "gp-table-timeframe" },
                [
                  _vm.isPastTimeframeSelectVisible
                    ? _c(
                        "my-popup",
                        {
                          attrs: { portal: "popup" },
                          on: {
                            clickoutside: function($event) {
                              _vm.isPastTimeframeSelectVisible = false
                            },
                            escape: function($event) {
                              _vm.isPastTimeframeSelectVisible = false
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "anchor",
                                fn: function() {
                                  return [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "javascript:void(0)" },
                                        on: {
                                          click: function($event) {
                                            _vm.isPastTimeframeSelectVisible = false
                                          }
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.pastTimeframeName))
                                        ])
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            4219781294
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("gp-timeframe-select", {
                            attrs: {
                              timeframes: _vm.fixedTimeframes,
                              "selected-timeframe": _vm.pastTimeframe
                            },
                            on: { "select-timeframe": _vm.setPastTimeframe }
                          })
                        ],
                        1
                      )
                    : _c(
                        "span",
                        {
                          attrs: { title: _vm.pastTimeframeTitle },
                          on: {
                            click: function($event) {
                              _vm.isPastTimeframeSelectVisible = true
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.pastTimeframeName))]
                      )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", [
              _c("label", [_c("l10n", { attrs: { value: "Forecasts:" } })], 1),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "gp-table-timeframe" },
                [
                  _vm.isFutureTimeframeSelectVisible
                    ? _c(
                        "my-popup",
                        {
                          attrs: { portal: "popup" },
                          on: {
                            clickoutside: function($event) {
                              _vm.isFutureTimeframeSelectVisible = false
                            },
                            escape: function($event) {
                              _vm.isFutureTimeframeSelectVisible = false
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "anchor",
                                fn: function() {
                                  return [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "javascript:void(0)" },
                                        on: {
                                          click: function($event) {
                                            _vm.isFutureTimeframeSelectVisible = false
                                          }
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.futureTimeframeName)
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            2598411310
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("gp-timeframe-select", {
                            attrs: {
                              timeframes: _vm.fixedTimeframes,
                              "selected-timeframe": _vm.futureTimeframe
                            },
                            on: { "select-timeframe": _vm.setFutureTimeframe }
                          })
                        ],
                        1
                      )
                    : _c(
                        "span",
                        {
                          attrs: { title: _vm.futureTimeframeTitle },
                          on: {
                            click: function($event) {
                              _vm.isFutureTimeframeSelectVisible = true
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.futureTimeframeName))]
                      )
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.renderOptimization && _vm.product == "price"
        ? _c("gp-table-runs", {
            attrs: {
              vars: _vm.vars,
              stream: _vm.stream,
              locale: _vm.locale,
              username: _vm.username,
              metrics: _vm.metrics,
              attributes: _vm.attributes,
              formats: _vm.formats,
              formulas: _vm.formulas,
              timeframes: _vm.timeframes,
              referenceDate: _vm.referenceDate,
              optimizationDims: _vm.optimizationDims,
              optimizationVals: _vm.optimizationVals,
              optimizationSections: _vm.optimizationSections,
              futureTimeframe: _vm.futureTimeframe
            },
            model: {
              value: _vm.optimizationId,
              callback: function($$v) {
                _vm.optimizationId = $$v
              },
              expression: "optimizationId"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("my-tooltip", {
        ref: "tooltip",
        attrs: {
          meta: null,
          "keep-only": _vm.keepOnly,
          exclude: _vm.exclude,
          actions: _vm.actions
        }
      }),
      _vm._v(" "),
      _c("form", { ref: "uploadForm", staticStyle: { display: "none" } }, [
        _c("input", {
          ref: "uploadInput",
          attrs: { type: "file" },
          on: { change: _vm.handleFileUpload }
        })
      ]),
      _vm._v(" "),
      _c("form", { ref: "uploadToUrlForm", staticClass: "hidden-form" }, [
        _c("input", {
          ref: "uploadToUrlInput",
          attrs: { "aria-label": "Upload excel file", type: "file" },
          on: {
            change: function($event) {
              return _vm.handleFileToUrlUpload(
                $event,
                _vm.uploadToUrlButton.value
              )
            }
          }
        })
      ]),
      _vm._v(" "),
      _vm.showTable
        ? _c(
            "plain-table",
            {
              ref: "table",
              attrs: {
                id: _vm.type === "tree" ? "tree-table" : "gp-table",
                cores: _vm.cores,
                caption: _vm.caption,
                stream: _vm.stream,
                groups: _vm.groups,
                vars: _vm.vars,
                dims: _vm.dims,
                vals: _vm.vals,
                cols: _vm.cols,
                filter0: _vm.filter0,
                filter1: _vm.extendedFilter1,
                filter2: _vm.filter2_,
                totals: _vm.totals,
                source: _vm.source,
                hover: _vm.hover,
                striped: _vm.striped,
                "table-actions": _vm.tableActionsEx,
                "column-icons": _vm.columnIcons,
                "column-actions": _vm.columnActions,
                "section-actions": _vm.sectionActions,
                instant: _vm.instant,
                throttled: _vm.throttled,
                "initial-sort": _vm.initialSort,
                responsive: false,
                override: _vm.override,
                expandable: _vm.expandable,
                "freeze-dims": true,
                pagination: _vm.pagination,
                overscroll: _vm.type === "tree" ? false : !_vm.pagination,
                "client-sort": !_vm.pagination,
                changeTypeFromStringToNumber: _vm.changeTypeFromStringToNumber,
                "sub-totals": _vm.subTotals,
                expand: _vm.expand,
                mode: _vm.type,
                evaluateButtons: _vm.evaluateButtons,
                downloadFromUrlButton: _vm.downloadFromUrlButton,
                downloadFileFromUrlButtonLoading:
                  _vm.downloadFileFromUrlButtonLoading,
                uploadToUrlButton: _vm.uploadToUrlButton,
                uploadFileToUrlButtonLoading: _vm.uploadFileToUrlButtonLoading,
                headerWithoutSum: _vm.headerWithoutSum,
                isEvaluateBtnsLoading: _vm.isEvaluateBtnsLoading,
                editCellOnChange: _vm.editCellOnChange,
                groupSize: _vm.groupSize,
                tableActionsButtonEnabled: _vm.tableActionsButtonEnabled,
                getOptionsListFromStream: _vm.getOptionsListFromStream,
                restartStreamButton: _vm.restartStreamButton,
                showExpandTableButton: _vm.showExpandTableButton,
                needColumnsIndexes: _vm.needColumnsIndexes,
                uniqueKey: _vm.uniqueKey
              },
              on: {
                action: _vm.handleCellAction,
                "report-updated": _vm.reportUpdated,
                "cell-edited": _vm.cellEdited,
                "cell-clicked": _vm.cellClicked,
                "link-clicked": _vm.linkClicked,
                "row-selected": _vm.rowSelected,
                "rows-selected": _vm.rowsSelected,
                "column-selected": _vm.columnSelected,
                "column-moved": _vm.columnMoved,
                "column-updated": _vm.columnUpdated,
                "page-size-changed": function($event) {
                  return _vm.handlePageSizeChanged($event)
                },
                "desired-page-changed": function($event) {
                  return _vm.handleDesiredPageChanged($event)
                },
                "desired-sort-changed": _vm.handleDesiredSortChanged,
                "filters-changed": _vm.handleTableFiltersChanged,
                "evaluate-button-click": _vm.handleEvaluateButtonClick,
                "download-url-button-click": _vm.downloadFileFromUrl,
                "upload-url-button-click": _vm.importFromFileToUrl
              }
            },
            [
              _vm.permalink
                ? _c(
                    "my-popup",
                    {
                      attrs: {
                        draggable: true,
                        anchor: _vm.getTableActionsElement,
                        placement: "bottom"
                      },
                      on: {
                        clickoutside: function($event) {
                          _vm.permalink = null
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "popover gp-table-permalink" }, [
                        _c("div", { staticClass: "popover-body" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "a",
                              {
                                staticClass: "float-right",
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    _vm.permalink = null
                                  }
                                }
                              },
                              [_c("feather-icon", { attrs: { name: "x" } })],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "label",
                              [
                                _c("l10n", {
                                  attrs: {
                                    value:
                                      "Please use the link below to share current report"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "input-group" }, [
                              _c("input", {
                                staticClass: "form-control",
                                attrs: { readonly: "" },
                                domProps: { value: _vm.permalink },
                                on: {
                                  click: function($event) {
                                    return $event.target.select()
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "input-group-append" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-sm btn-outline-secondary",
                                    attrs: { type: "button" },
                                    on: { click: _vm.copyPermalink }
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: { name: "clipboard" }
                                    }),
                                    _vm._v(" "),
                                    _c("l10n", {
                                      attrs: {
                                        value: _vm.permalinkCopied
                                          ? "Copied"
                                          : "Copy"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ])
                          ])
                        ])
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.uploadFileDryRun
                ? _c(
                    "my-popup",
                    {
                      attrs: {
                        draggable: true,
                        anchor: _vm.getTableActionsElement,
                        placement: "bottom"
                      },
                      on: {
                        clickoutside: function($event) {
                          _vm.uploadFileDryRun = null
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "popover gp-table-upload-file" },
                        [
                          _c("div", { staticClass: "popover-body" }, [
                            _c(
                              "h3",
                              { staticClass: "popover-header" },
                              [
                                _c("l10n", {
                                  attrs: {
                                    value: "Import file: {file}",
                                    file: _vm.uploadFileDryRun.file.name
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.uploadFileDryRun.result.columns.length
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-group gp-table-upload-file-ready"
                                  },
                                  [
                                    _c(
                                      "label",
                                      [
                                        _c("l10n", {
                                          attrs: {
                                            value:
                                              "Please select columns to import"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("table", [
                                      _c("thead", [
                                        _c("tr", [
                                          _c("th"),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            [
                                              _c("l10n", {
                                                attrs: { value: "changes" }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            [
                                              _c("l10n", {
                                                attrs: { value: "not changed" }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            [
                                              _c("l10n", {
                                                attrs: { value: "not found" }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            [
                                              _c("l10n", {
                                                attrs: { value: "not allowed" }
                                              })
                                            ],
                                            1
                                          )
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "tbody",
                                        _vm._l(
                                          _vm.uploadFileDryRun.result.report,
                                          function(stats, i) {
                                            return _c("tr", { key: i }, [
                                              _c(
                                                "td",
                                                [
                                                  _c(
                                                    "gp-check",
                                                    {
                                                      attrs: {
                                                        checked:
                                                          _vm.uploadFileDryRun
                                                            .columns[
                                                            stats.column
                                                          ]
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.selectUploadFileColumn(
                                                            stats.column,
                                                            $event
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("l10n", {
                                                        attrs: {
                                                          value: stats.column
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                [
                                                  stats.changed
                                                    ? _c(
                                                        "a",
                                                        {
                                                          attrs: { href: "#" },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              _vm.downloadAsExcel(
                                                                stats.changedRows,
                                                                stats.column +
                                                                  " - " +
                                                                  _vm.l10n(
                                                                    "changes"
                                                                  ) +
                                                                  ".xlsx"
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              Number(
                                                                stats.changed
                                                              ).toLocaleString()
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              Number(
                                                                stats.changed
                                                              ).toLocaleString()
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                ],
                                                2
                                              ),
                                              _vm._v(" "),
                                              _c("td", [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      Number(
                                                        stats.unchanged
                                                      ).toLocaleString()
                                                    )
                                                  )
                                                ])
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                [
                                                  stats.notFound
                                                    ? _c(
                                                        "a",
                                                        {
                                                          attrs: { href: "#" },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              _vm.downloadAsExcel(
                                                                stats.notFoundRows,
                                                                stats.column +
                                                                  " - " +
                                                                  _vm.l10n(
                                                                    "not found"
                                                                  ) +
                                                                  ".xlsx"
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              Number(
                                                                stats.notFound
                                                              ).toLocaleString()
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              Number(
                                                                stats.notFound
                                                              ).toLocaleString()
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                ],
                                                2
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                [
                                                  stats.notAllowed
                                                    ? _c(
                                                        "a",
                                                        {
                                                          attrs: { href: "#" },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              _vm.downloadAsExcel(
                                                                stats.notAllowedRows,
                                                                stats.column +
                                                                  " - " +
                                                                  _vm.l10n(
                                                                    "not allowed"
                                                                  ) +
                                                                  ".xlsx"
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              Number(
                                                                stats.notAllowed
                                                              ).toLocaleString()
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              Number(
                                                                stats.notAllowed
                                                              ).toLocaleString()
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                ],
                                                2
                                              )
                                            ])
                                          }
                                        ),
                                        0
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            Object.keys(_vm.uploadFileDryRun.missing).length
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-group gp-table-upload-file-missing"
                                  },
                                  [
                                    _c(
                                      "label",
                                      [
                                        _c("l10n", {
                                          attrs: {
                                            value:
                                              "The following metrics might be missing either in the file or report"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "ul",
                                      [
                                        _vm._l(
                                          _vm.uploadFileDryRun.missing,
                                          function(metrics) {
                                            return _vm._l(metrics, function(
                                              metric,
                                              i
                                            ) {
                                              return _c(
                                                "li",
                                                { key: i },
                                                [
                                                  _c("l10n", {
                                                    attrs: { value: metric }
                                                  })
                                                ],
                                                1
                                              )
                                            })
                                          }
                                        )
                                      ],
                                      2
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            Object.keys(_vm.uploadFileDryRun.result.no_keys)
                              .length
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-group gp-table-upload-file-no-keys"
                                  },
                                  [
                                    _c(
                                      "label",
                                      [
                                        _c("l10n", {
                                          attrs: {
                                            value:
                                              "The following columns require additional grouping to be imported"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "ul",
                                      _vm._l(
                                        _vm.uploadFileDryRun.result.no_keys,
                                        function(keys, column) {
                                          return _c(
                                            "li",
                                            { key: column },
                                            [
                                              _c("l10n", {
                                                attrs: { value: column }
                                              }),
                                              _vm._v(
                                                ": " +
                                                  _vm._s(
                                                    keys
                                                      .map(function(key) {
                                                        return _vm
                                                          .attributesByCalc[key]
                                                          ? _vm
                                                              .attributesByCalc[
                                                              key
                                                            ].name
                                                          : key
                                                      })
                                                      .join(", ")
                                                  ) +
                                                  "\n              "
                                              )
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-group gp-table-upload-file-actions"
                              },
                              [
                                _vm.uploadFileDryRun.result.columns.length
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-sm btn-primary",
                                        attrs: {
                                          type: "button",
                                          disabled:
                                            Object.keys(
                                              _vm.uploadFileDryRun.columns
                                            ).length == 0
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.uploadFile(
                                              _vm.uploadFileDryRun.file,
                                              false,
                                              Object.keys(
                                                _vm.uploadFileDryRun.columns
                                              )
                                            )
                                            _vm.uploadFileDryRun = null
                                          }
                                        }
                                      },
                                      [
                                        _c("l10n", {
                                          attrs: { value: "Import" }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-sm btn-secondary",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        _vm.uploadFileDryRun = null
                                      }
                                    }
                                  },
                                  [_c("l10n", { attrs: { value: "Cancel" } })],
                                  1
                                )
                              ]
                            )
                          ])
                        ]
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "portal",
        { attrs: { to: "gp-kpis" } },
        [
          _c("gp-kpis3", {
            attrs: {
              stream: _vm.stream,
              vars: _vm.vars,
              groups: ["kpis", "search", "report"],
              locale: _vm.locale,
              currency: _vm.currency,
              metrics: _vm.metrics,
              formats: _vm.formats,
              formulas: _vm.formulas,
              timeframes: _vm.timeframes,
              attributes: _vm.attributes,
              metric1: _vm.kpisMetric1,
              metric2: _vm.kpisMetric2,
              metric3: _vm.kpisMetric3
            },
            model: {
              value: _vm.kpis,
              callback: function($$v) {
                _vm.kpis = $$v
              },
              expression: "kpis"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "portal",
        { attrs: { to: "gp-settings-" + this.uniqueKey } },
        [
          _c("h2", [_c("l10n", { attrs: { value: "Report settings" } })], 1),
          _vm._v(" "),
          _c("gp-stored", {
            ref: "stored",
            attrs: {
              family: "reports",
              compact: _vm.compact,
              config: _vm.config,
              username: _vm.username,
              "share-groups": _vm.shareGroups,
              uniqueKey: _vm.uniqueKey
            },
            on: { saved: _vm.configSaved, change: _vm.configChanged }
          }),
          _vm._v(" "),
          _vm.product == "price" || _vm.product == "pim"
            ? [
                _c("label", [_c("l10n", { attrs: { value: "Scope" } })], 1),
                _vm._v(" "),
                _c("gp-filter", {
                  attrs: {
                    stream: _vm.stream,
                    attributes: _vm.attributes,
                    formulas: _vm.formulas,
                    filter0: _vm.extraFilter0,
                    filter1: _vm.extraFilter1,
                    filter2: _vm.extraFilter2,
                    vars: _vm.vars
                  },
                  model: {
                    value: _vm.editingFilter,
                    callback: function($$v) {
                      _vm.editingFilter = $$v
                    },
                    expression: "editingFilter"
                  }
                }),
                _vm._v(" "),
                _c("gp-filter", {
                  staticStyle: { visibility: "hidden", position: "absolute" },
                  attrs: {
                    value: _vm.currentFilter,
                    stream: _vm.stream,
                    attributes: _vm.attributes,
                    formulas: _vm.formulas,
                    groups: ["report", "search"],
                    autofilter: true
                  }
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("label", [_c("l10n", { attrs: { value: "Grouping" } })], 1),
          _vm._v(" "),
          _c("gp-pills", {
            attrs: {
              options: _vm.dimesionsOptions,
              "recent-options-key": "recentDimensions"
            },
            model: {
              value: _vm.editingDimensions,
              callback: function($$v) {
                _vm.editingDimensions = $$v
              },
              expression: "editingDimensions"
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "gp-check",
                {
                  model: {
                    value: _vm.subTotals,
                    callback: function($$v) {
                      _vm.subTotals = $$v
                    },
                    expression: "subTotals"
                  }
                },
                [
                  _c("l10n", {
                    attrs: { value: "Enable subtotals by first group" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", [
            _c(
              "label",
              { attrs: { for: "gp-settings-vars" } },
              [_c("l10n", { attrs: { value: "Parameters" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "table",
              {
                staticClass: "gp-settings-vars",
                attrs: { id: "gp-settings-vars" }
              },
              [
                _c("tbody", [
                  _vm.product == "price"
                    ? _c("tr", [
                        _c(
                          "td",
                          [
                            _c("l10n", {
                              attrs: { value: "POI history length" }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "select",
                            {
                              staticClass: "form-control form-control-sm",
                              domProps: {
                                value: _vm.editingVars.target_poi_length || 7
                              },
                              on: {
                                change: function($event) {
                                  _vm.$set(
                                    _vm.editingVars,
                                    "target_poi_length",
                                    parseInt($event.target.value)
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "option",
                                { attrs: { value: "7" } },
                                [_c("l10n", { attrs: { value: "7 days" } })],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "option",
                                { attrs: { value: "14" } },
                                [_c("l10n", { attrs: { value: "14 days" } })],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "option",
                                { attrs: { value: "28" } },
                                [_c("l10n", { attrs: { value: "28 days" } })],
                                1
                              )
                            ]
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.product == "price"
                    ? _c("tr", [
                        _c(
                          "td",
                          [
                            _c("l10n", {
                              attrs: { value: "Cost change history length" }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "select",
                            {
                              staticClass: "form-control form-control-sm",
                              domProps: {
                                value:
                                  _vm.editingVars.target_cost_change_days || 1
                              },
                              on: {
                                change: function($event) {
                                  _vm.$set(
                                    _vm.editingVars,
                                    "target_cost_change_days",
                                    parseInt($event.target.value)
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "option",
                                { attrs: { value: "1" } },
                                [_c("l10n", { attrs: { value: "1 day" } })],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "option",
                                { attrs: { value: "2" } },
                                [_c("l10n", { attrs: { value: "2 days" } })],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "option",
                                { attrs: { value: "3" } },
                                [_c("l10n", { attrs: { value: "3 days" } })],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "option",
                                { attrs: { value: "4" } },
                                [_c("l10n", { attrs: { value: "4 days" } })],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "option",
                                { attrs: { value: "5" } },
                                [_c("l10n", { attrs: { value: "5 days" } })],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "option",
                                { attrs: { value: "6" } },
                                [_c("l10n", { attrs: { value: "6 days" } })],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "option",
                                { attrs: { value: "7" } },
                                [_c("l10n", { attrs: { value: "7 days" } })],
                                1
                              )
                            ]
                          )
                        ])
                      ])
                    : _vm._e()
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("label", [_c("l10n", { attrs: { value: "Columns" } })], 1),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "gp-settings-sections" },
            _vm._l(_vm.editingSections, function(section, i) {
              return _c(
                "table",
                {
                  key: i,
                  staticClass: "gp-table-settings",
                  attrs: { "data-i": i }
                },
                [
                  _c("thead", [
                    _c("tr", { staticClass: "gp-table-section" }, [
                      _c("td", [
                        _c(
                          "div",
                          { staticClass: "form-check form-check--in-settings" },
                          [
                            _c("input", {
                              staticClass: "form-check-input",
                              attrs: { id: "show-" + i, type: "checkbox" },
                              domProps: {
                                checked: _vm.isSectionVisible(section),
                                indeterminate: _vm.isSectionIndeterminate(
                                  section
                                )
                              },
                              on: {
                                click: function($event) {
                                  return _vm.toogleSectionVisible(section)
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("label", {
                              staticClass: "form-check-label",
                              attrs: { for: "show-" + i }
                            })
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          attrs: { colspan: "4" },
                          on: {
                            click: function($event) {
                              _vm.editSectionName(section)
                              $event.stopPropagation()
                            }
                          }
                        },
                        [
                          !!_vm.checkSectionNameInputVisibility(section)
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.editingValue,
                                    expression: "editingValue"
                                  }
                                ],
                                ref: "editingControl",
                                refInFor: true,
                                staticClass: "form-control form-control-sm",
                                domProps: { value: _vm.editingValue },
                                on: {
                                  keydown: _vm.handleEditingControlKeyDown,
                                  blur: _vm.handleEditingControlBlur,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.editingValue = $event.target.value
                                  }
                                }
                              })
                            : section.name
                            ? [_c("span", [_vm._v(_vm._s(section.name))])]
                            : [_c("span", [_vm._v(" ")])]
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("td", [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                return _vm.removeSection(section)
                              }
                            }
                          },
                          [_c("feather-icon", { attrs: { name: "trash" } })],
                          1
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(section.columns, function(column, j) {
                      return _c(
                        "tr",
                        {
                          key: j,
                          staticClass: "gp-table-column",
                          attrs: { "data-j": j }
                        },
                        [
                          _c("td", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-check form-check--in-settings"
                              },
                              [
                                _c("input", {
                                  staticClass: "form-check-input",
                                  attrs: {
                                    id: "show-" + i + "-" + j,
                                    type: "checkbox"
                                  },
                                  domProps: {
                                    checked: _vm.isColumnVisible(column)
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.toogleColumnVisible(column)
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("label", {
                                  staticClass: "form-check-label",
                                  attrs: { for: "show-" + i + "-" + j }
                                })
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              attrs: {
                                colspan:
                                  column.type === "metric" &&
                                  _vm.isClassSelectRendered
                                    ? 1
                                    : 2
                              },
                              on: {
                                click: function($event) {
                                  _vm.editColumnName(section, column)
                                  $event.stopPropagation()
                                }
                              }
                            },
                            [
                              column.alias || column.name
                                ? [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.resolveVars(
                                            column.alias || column.name
                                          )
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    column.metric &&
                                    column.metric.timeframe !==
                                      column.timeframe &&
                                    !column.alias
                                      ? _c(
                                          "span",
                                          { staticClass: "gp-table-timeframe" },
                                          [
                                            _vm._v(
                                              "(" +
                                                _vm._s(
                                                  _vm.getTimeframeName(
                                                    column.timeframe
                                                  )
                                                ) +
                                                ")"
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.getSelectedClass(column)
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                  (" +
                                              _vm._s(
                                                _vm.getColumnName(column)
                                              ) +
                                              ")\n                "
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                : [_c("span", [_vm._v(" ")])],
                              _vm._v(" "),
                              !!_vm.checkSectionNameSelectVisibility(
                                section,
                                column
                              )
                                ? [
                                    _c(
                                      "div",
                                      {
                                        on: {
                                          click: function($event) {
                                            return $event.stopPropagation()
                                          }
                                        }
                                      },
                                      [
                                        _c("gp-select", {
                                          attrs: {
                                            autofocus: true,
                                            options: _vm.attributes
                                              .concat(_vm.metrics)
                                              .concat(_vm.calc_columns)
                                              .filter(function(a) {
                                                return !a.deleted && a.name
                                              })
                                              .sort(function(a, b) {
                                                return a.name.localeCompare(
                                                  b.name
                                                )
                                              }),
                                            "recent-options-key":
                                              "recentColumns"
                                          },
                                          on: { change: _vm.submitEditing },
                                          model: {
                                            value: _vm.editingValue,
                                            callback: function($$v) {
                                              _vm.editingValue = $$v
                                            },
                                            expression: "editingValue"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          ),
                          _vm._v(" "),
                          column.type === "metric"
                            ? _c(
                                "td",
                                [
                                  _vm.editingSection === section &&
                                  _vm.editingColumn === column &&
                                  _vm.editingTimeframe
                                    ? _c(
                                        "my-popup",
                                        {
                                          attrs: { portal: "popup" },
                                          on: {
                                            clickoutside: _vm.stopEditing,
                                            escape: _vm.stopEditing
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "anchor",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href:
                                                            "javascript:void(0)"
                                                        },
                                                        on: {
                                                          click: _vm.stopEditing
                                                        }
                                                      },
                                                      [
                                                        _c("feather-icon", {
                                                          attrs: {
                                                            name: "calendar"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("gp-timeframe-select", {
                                            attrs: {
                                              timeframes:
                                                _vm.computedTimeframes,
                                              "selected-timeframe":
                                                column.timeframe
                                            },
                                            on: {
                                              "select-timeframe": function(
                                                $event
                                              ) {
                                                return _vm.updateColumnTimeframe(
                                                  column,
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: "javascript:void(0)",
                                            title: column.timeframe
                                              ? _vm.getTimeframeName(
                                                  column.timeframe
                                                )
                                              : null
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.editColumnTimeframe(
                                                section,
                                                column
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("feather-icon", {
                                            attrs: { name: "calendar" }
                                          })
                                        ],
                                        1
                                      )
                                ],
                                1
                              )
                            : _c("td"),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.renameColumn(section, column)
                                  }
                                }
                              },
                              [
                                _c("feather-icon", {
                                  attrs: { name: "edit-3" }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.removeColumn(section, column)
                                  }
                                }
                              },
                              [
                                _c("feather-icon", { attrs: { name: "trash" } })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _vm.isClassSelectRendered
                            ? _c(
                                "td",
                                [
                                  _vm.checkClassSelectVisibility(
                                    section,
                                    column
                                  )
                                    ? _c(
                                        "my-popup",
                                        {
                                          attrs: { portal: "popup" },
                                          on: {
                                            clickoutside: _vm.stopEditing,
                                            escape: _vm.stopEditing
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "anchor",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          "aria-label":
                                                            "editClass",
                                                          href:
                                                            "javascript:void(0)"
                                                        },
                                                        on: {
                                                          click: _vm.stopEditing
                                                        }
                                                      },
                                                      [
                                                        _c("feather-icon", {
                                                          attrs: {
                                                            name: "sliders"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("gp-class-select", {
                                            attrs: {
                                              classes: _vm.computedClasses,
                                              "selected-class": _vm.getSelectedClass(
                                                column
                                              )
                                            },
                                            on: {
                                              "select-class": function($event) {
                                                return _vm.updateColumnClass(
                                                  column,
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _c(
                                        "a",
                                        {
                                          attrs: {
                                            "aria-label": "editClass",
                                            href: "javascript:void(0)",
                                            title: "Выбрать название колонки"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.editColumnClass(
                                                section,
                                                column
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("feather-icon", {
                                            attrs: { name: "sliders" }
                                          })
                                        ],
                                        1
                                      )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("tfoot", [
                    _c("tr", [
                      _c("td", { attrs: { colspan: "5" } }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                _vm.addColumn(section)
                                $event.stopPropagation()
                              }
                            }
                          },
                          [
                            _c("feather-icon", { attrs: { name: "plus" } }),
                            _vm._v(" "),
                            _c("l10n", { attrs: { value: "Add column" } })
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("table", { staticClass: "gp-table-settings" }, [
            _c("tbody", [
              _c("tr", { staticClass: "gp-table-section" }, [
                _c("td", { attrs: { colspan: "5" } }, [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: { click: _vm.addSection }
                    },
                    [
                      _c("feather-icon", { attrs: { name: "plus" } }),
                      _vm._v(" "),
                      _c("l10n", { attrs: { value: "Add section" } })
                    ],
                    1
                  )
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          !_vm.compact
            ? _c("p", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-secondary",
                    attrs: { type: "button", disabled: !_vm.somethingEdited },
                    on: { click: _vm.applyChanges }
                  },
                  [
                    _c("l10n", {
                      attrs: { value: "Apply changes without saving" }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.compact
            ? _c(
                "div",
                { staticClass: "gp-table-configuration" },
                [
                  _c("div", { staticClass: "form-check" }, [
                    _c("input", {
                      staticClass: "form-check-input",
                      attrs: { id: "show-table-config", type: "checkbox" },
                      domProps: { checked: _vm.showConfig },
                      on: { click: _vm.toogleYamlConfig }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "show-table-config" }
                      },
                      [_c("l10n", { attrs: { value: "Show configuration" } })],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _vm.showConfig
                    ? _c("ace-editor", {
                        attrs: { mode: "yaml", "auto-height": true },
                        model: {
                          value: _vm.configYaml,
                          callback: function($$v) {
                            _vm.configYaml = $$v
                          },
                          expression: "configYaml"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showConfig
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-secondary",
                          attrs: { type: "button" },
                          on: { click: _vm.applyYamlConfig }
                        },
                        [_c("l10n", { attrs: { value: "Submit changes" } })],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "portal",
        { attrs: { to: "gp-discard-tools-" + this.uniqueKey } },
        [
          _vm.discardStream
            ? _c("gp-discard-tools", {
                attrs: { stream: _vm.discardStream, user: _vm.username }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }